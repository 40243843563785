import { AuthApi, ViziApi } from '../config/api';
import Api from "../config/api";
import localStorageService, { UNIDADE_LOGADA_KEY, USUARIO_LOGADO_KEY } from "./storage";

export const findUnidadeLogada = async () => {
	try {
		const response = await Api.post("", {
			query: `
				query {
					findUnidadeLogada {
						nome
						id
						referenciaAzulControle
						endereco {
							bairro
							complemento
							municipio {
								nome
							}
							estado {
								uf
							}
							nomeLogradouro
							numero
						}
						plugzapiClientToken
						plugzapiUrl
						profissionaisSaude{
							id
							nome
						}	
						telefonePrincipal
						rede {
							id
							nome
							paisAtuacao {
								id
								descricao
							}
							whitelabel{
								id
								frontWebUrl
								nome
								nomeEmpresa
								corPrincipal
								utilizaPagamentoUnidade
							}
						}
					}
				}
			`
		});

		const data = response?.data?.data?.findUnidadeLogada || {};
		const { whitelabel } = data?.rede || {};
		const { nome } = whitelabel || {};
		localStorageService.set(UNIDADE_LOGADA_KEY, data);

		if (nome) {
			ViziApi.defaults.headers['whitelabel'] = nome;
		}

		return data;
	} catch (error) {
		const data = {};

		localStorageService.set(UNIDADE_LOGADA_KEY, data);

		return data;
	}
};

export const alterarUnidadeLogada = async (unidadeId) => {
	if (!unidadeId) {
		throw new Error("Unidade não informada.");
	}

	const response = await AuthApi.post('graphql', {
		query: `
			mutation ($idUnidade: Long!) {
				alterarUnidadeAtual(idUnidade: $idUnidade) {
					id
					nome
					login
					fotoPerfil
					authorities {
						authority
					}
					rede {
						whitelabel {
							nome
						}
					}
				}
			}
		`,
		variables: {
			idUnidade: unidadeId
		}
	});

	if (response.data.errors) {
		throw Error('Falha ao alterar a unidade logada');
	}

	const { alterarUnidadeAtual } = response.data.data;
	const { whitelabel } = alterarUnidadeAtual?.rede || {};
	await localStorageService.set(USUARIO_LOGADO_KEY, alterarUnidadeAtual);

	if (whitelabel?.nome) {
		ViziApi.defaults.headers['whitelabel'] = whitelabel?.nome;
	}

	return alterarUnidadeAtual;
}

export const referenciaAzulControleUnidadeLogado = async () => {
	const response = await Api.post("", {
		query: `{
            findUnidadeLogada {
                referenciaAzulControle
            }
        }
    `});

	return response.data.data.findUnidadeLogada?.referenciaAzulControle
};

export const findVersaoSistemaByOauthClientId = async (clientId) => {
	const response = await Api.post("", {
		query: `
		query ($clientId: OAuthClientId) {
			findVersaoSistemaByOauthClientId(clientId: $clientId) {
			  id
			  versao
			  dataHoraLancamento
			  changelog
			}
		  }
		`,
		variables: { clientId }
	});

	return response.data.data.findVersaoSistemaByOauthClientId;
};

export const marcarVisualizacaoVersaoSistema = (versoesSistema) => {
	return Api.post("", {
		query: `
			mutation ($versoesSistema: [UUID]) {
				marcarVisualizacaoVersaoSistema(versoesSistema: $versoesSistema)
			}
		`,
		variables: { versoesSistema }
	});
};

export const findAllVersaoSistema = async variables => {
	const response = await Api.post("", {
		query: `
			query ($pageableDTO: PageableDTOInput) {
				findAllVersaoSistema(pageableDTO: $pageableDTO) {
					last
					content {
						id
						versao
						dataHoraLancamento
						changelog
					}
				}
			}
		`,
		variables: variables
	});
	return response.data.data.findAllVersaoSistema
}

export const findAllEspanhaComunidadeAutonoma = async () => {
	const response = await Api.post("", {
		query: `
			{
				findAllEspanhaComunidadeAutonoma(pageableDTO: {sortField: "descricao", sortDir: "ASC"}) {
					id
					descricao
				}
			}
		`
	});
	return response.data.data.findAllEspanhaComunidadeAutonoma;
};

export const findByUnidadeLogadaConfiguracaoUnidadeConfirmaPresencaAgendamento = async () => {
	const response = await Api.post("", {
		query: `
			{
				findByUnidadeLogadaConfiguracaoUnidade {
					confirmaPresencaAgendamento
				}
			}
		`
	});
	return response.data.data.findByUnidadeLogadaConfiguracaoUnidade?.confirmaPresencaAgendamento;
};

export const getWhatsPlugzapiStatus = async (unidadeId) => {

	const response = await Api.post('', {
		query: `
		{
			  whatsPlugzapiStatus(unidadeId: ${unidadeId}){
				connected
				error
				plugzapiWebhookMsgFuncionando
				plugzapiConnectedPhone
			  }     
		}
	`
	})

	return response.data.data.whatsPlugzapiStatus;

}

export const getWhatsPlugzapiQrcode = async (unidadeId) => {

	const response = await Api.post('', {
		query: `
		{
			  whatsPlugzapiQrcode(unidadeId: ${unidadeId}){
				connected
				erro
				value
			  }     
		}
	`
	})

	return response.data.data.whatsPlugzapiQrcode;
}
export const disconnectWhats = async (unidadeId) => {

	const response = await Api.post('', {
		query: `
		{
			  whatsPlugzapiDisconnect(unidadeId: ${unidadeId}){
				connected
				erro
				value
			  }     
		}
	`
	})

	return response.data.data.whatsPlugzapiDisconnect;


}

