import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import { CircularProgress, Checkbox } from "@material-ui/core";
import Scroll from "../../../../../components/InfiniteScroll/Scroll";
import InputMaskCurrency from "../../../../../components/Input/InputMaskCurrency";

const ConvenioList = ({
  classes,
  listaModal,
  handleChangeCheckbox,
  handleChange,
  loadMore,
  lastScroll,
  loadingModal,
}) => {
  return (
    <div className={classes.content}>
      <Scroll
        loadMore={loadMore}
        hasMore={!lastScroll}
        pageStart={0}
        initialLoad={false}
        className={classes.scrollContainerModelos}
      >
        <div className={classes.titles}>
          <div className={classes.title}> Selecione o convênio </div>
          <div className={classNames(classes.title, classes.titleValor)}>
            Valor
          </div>
        </div>
        {listaModal.length > 0 &&
          listaModal.map((itemLista, index) => (
            <div className={classes.linha} key={index}>
              <div className={classes.checkboxDescricao}>
                <Checkbox
                  color={"primary"}
                  onChange={() => handleChangeCheckbox(itemLista, index)}
                  checked={itemLista.convenio?.checked || itemLista.convenios?.checked}
                />
                <div className={classes.convenio}>
                  {itemLista.convenio?.descricao ?? itemLista.convenios.descricao}
                </div>
              </div>
              <InputMaskCurrency
                name={"valor"}
                value={itemLista.valor === 0 ? "" : itemLista.valor}
                className={classNames(
                  classes.inputValor,
                  !(itemLista.convenio?.checked ?? itemLista.convenios.checked) && classes.inputValorDesabilitado
                )}
                onChange={(e) => handleChange(e, itemLista, index)}
                disabled={!(itemLista.convenio?.checked ?? itemLista.convenios.checked)}
              />
            </div>
          ))}
        {loadingModal && (
          <div className={classes.contentLoading}>
            <CircularProgress />
          </div>
        )}
      </Scroll>
    </div>
  );
};

const styles = {
  content: {
    height: "calc(100% - 105px)",
    overflow: "auto",
  },
  titles: {
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    zIndex: 1,
    background: "#fff",
  },
  title: {
    fontSize: "10px",
    color: "#868686",
  },
  titleValor: {
    width: "100%",
    maxWidth: "104px",
  },
  convenio: {
    color: "#868686",
  },
  linha: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "4px",
  },
  inputValor: {
    background: "#F2F2F2",
    padding: "4px 4px 4px 8px",
    width: "90px",
    height: "30px",
    border: "1px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
  },
  checkboxDescricao: {
    display: "flex",
    "&>span": {
      color: "#868686",
    },
  },
  contentLoading: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  scrollContainerModelos: {
    height: "100%",
  },
  inputValorDesabilitado: {
    color: "#E5E5E5",
  },
};

export default withStyles(styles)(ConvenioList);
