import { alignItems, background, border, borderRadius, flexDirection, justifyContent, width } from "styled-system";

const styles = {
  contentButton:{
    display:"flex",
    width:"100%",
    height:"69px",
    alignItems: "center",
    flexDirection: "row",
    paddingLeft:"20px",
  },
  buttonSalvar:{
    borderRadius:"25px",
  },
  styleDialog:{
    maxWidth:"100%",
    overflow:"hidden",
    maxHeight:"none"
  },
  container:{
    width:"100%",
    height:"100%",
    overflowY:"scroll",
  },
  content:{
    display:"flex",
    flexDirection:"column",
    padding:"10px 20px",
    boxShadow:"10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05) ",
    height:"100%",
    width:"500px",
  },
  wrapper:{
    display:"flex",
    flexDirection:"column",
  },
  wrapperModal:{
    display:"flex",
    flexDirection:"row"
  },
  modalTextMessage:{
    width:"572px !important",
    height:"485px !important",
    display:"flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  title:{
    fontSize: "18px",
    fontWeight:"700",
  },
  envolved:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  fieldText:{
  padding:"10px" ,
  height:"328px",
  " &> div":{
    width:"100%",
    height:"325px"
  },
    " &>div>div":{
      width:"500px !important",
  },
  },
  label:{
    fontFamily: "Poppins !important",
    fontWeight: "bold",
  },
  contentInput:{
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"flex-start",
  color:"#505050",
  fontWeight:"400",
  paddingTop:"5px",
  gap:"10px",
  },
  inputName:{
    background:"#F2F2F2",
    border:"1px solid #868686",
    borderRadius:"5px",
  },
  select:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    gap:"5px",
   },
  selectProfissional:{
    width:"230px",
    border:"1px solid #868686",
    borderRadius:"5px",
    background:"#f2f2f2",
    "& >div >div>div":{
    color:"#333333db",
    font:"inherit"
    }
  },
  header:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    alignItems:"center",
  }
}
export default styles;