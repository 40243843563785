import React from "react";
import { Fab, Tooltip, withStyles } from "@material-ui/core";
import { primaryColor, primaryColorDark, red } from "../../assets/jss/appHealth.style";

const styles = () => ({
    optionButton: {
        display: 'flex',
    },
    labelGreen: {
        borderRadius: '1000px',
        position: 'relative',
        right: '-28px',
        display: 'flex',
        alignItems: 'center',
        paddingRight: '30px',
        paddingLeft: '12px',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16)',
        fontWeight: 600,
        cursor: 'pointer',
        background: '#CFE8E7',
        '& span': {
            whiteSpace: 'nowrap',
            color: '#0F8784',
            fontSize: "12px"
        }
    },
    labelRed: {
        borderRadius: '1000px',
        position: 'relative',
        right: '-25px',
        display: 'flex',
        alignItems: 'center',
        paddingRight: '30px',
        paddingLeft: '12px',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16)',
        fontWeight: 600,
        background: '#FFEDED',
        cursor: 'pointer',
        '& span': {
            color: '#FB7676',
            fontSize: "12px"
        }
    },
    buttonGreen: {
        minWidth: "40px",
        maxWidth: "40px",
        height: "40px",
        minHeight: "1px",
        background: primaryColor,
        "&:hover": {
            background: primaryColorDark,
        },
    },
    buttonRed: {
        width: "40px",
        height: "40px",
        minHeight: "1px",
        background: red,
        "&:hover": {
            background: '#f92e2e',
        },
    }
})

const LabelButton = (props) => {
    const { title, classes, icon, onClick, redColor, hiddenLabel } = props;
    const labelStyle = redColor ? classes.labelRed : classes.labelGreen ;
    const buttonStyle = redColor ? classes.buttonRed: classes.buttonGreen;

    return (
        <>
            <div className={classes.optionButton}>
                { !hiddenLabel && <div className={labelStyle} onClick={onClick}>
                    <span>{title}</span>
                </div>}
                <Fab className={buttonStyle} onClick={onClick}>
                    <Tooltip
                        title={title}
                        placement="left"
                    >
                        {
                            typeof icon === 'string' ?
                                <img
                                    className={classes.documentoIcon}
                                    src={icon}
                                    alt={title}
                                />
                                :
                                icon
                        }
                    </Tooltip>
                </Fab>
            </div>

        </>
    )
}

export default withStyles(styles)(LabelButton);