import React, { useEffect, useState } from "react";
import { inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  CircularProgress,
} from "@material-ui/core";
import {
  Close as CloseIcon,
} from "@material-ui/icons";
import style from "./AvaliacaoAntropometricaStyle";
import moment from "moment";
import { InputPeso } from "../../../components/Input/InputPeso";
import { InputCm } from "../../../components/Input/InputCm";
import { InputMm } from "../../../components/Input/InputMm";
import StatusImc from "./StatusImc";
import Button from '@material-ui/core/Button';
import {createAvaliacaoAntropometrica, calcImc} from '../../../services/AvaliacaoAntropometricaService';
import Notification from "../../../components/Notification"; 
import CustomDateTimePicker from "../../../components/CustomDateTimePicker";
import classNames from "classnames";
import string from "../../../utils/string";

const NovoLancamento = (props) => {
  const {
    classes,
    historicoLancamentoSelecionado,
    carregaListaHistoricoLancamento,
    ultimoLancamentoAvaliacaoAntropometrica,
    isVisualizarLancamento,
    avaliacaoAntropometricaSelecionado,
    actionClose,
    sujeitoAtencao,
    avaliacaoAntropometricaStore,
    tipoGrafico,
    idade,
  } = props;
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [imc, setImc] = useState({ valor: '', descricao: '' });
  const [dadosLancamento, setDadosLancamento] = useState({});
  const [data, setData] = useState(moment());
  const [notification, setNotification] = useState({isOpen: false, message: '', variant: ''});
  const [inativador, setInativador] = useState("");

  useEffect(() => {
    avaliacaoAntropometricaSelecionado && preencherDadosAvaliacaoAntropometricaSelecionado();
    verificaLancamentoInativo();

  }, []);
  
  useEffect(() => {
    if (historicoLancamentoSelecionado){
      verificaLancamentoInativo();
      setData(historicoLancamentoSelecionado?.data)
      setDadosLancamento(historicoLancamentoSelecionado)
    }
  }, [historicoLancamentoSelecionado]);

  useEffect(() => {
    if (!avaliacaoAntropometricaSelecionado){
      setData(ultimoLancamentoAvaliacaoAntropometrica?.data)
      setDadosLancamento(ultimoLancamentoAvaliacaoAntropometrica)
    }
  }, [ultimoLancamentoAvaliacaoAntropometrica])

  useEffect(() => {
    calculaPesoAltura();
  }, [dadosLancamento?.estatura, dadosLancamento?.peso]);

  const handleDateChange = (e) => {
    setData(e)
  };

  const preencherDadosAvaliacaoAntropometricaSelecionado = () => {
    setData(avaliacaoAntropometricaSelecionado.data);
    setDadosLancamento(avaliacaoAntropometricaSelecionado);
  };

const handleChange = (event, fieldName) => {
  const { value } = event.target;
  setDadosLancamento(prevState => ({
    ...prevState,
    [fieldName]: value,
  }));
};

    const calculaPesoAltura = async () => {   
        if (dadosLancamento?.estatura && dadosLancamento?.peso) {
            const alturaNumeroSemSufixo = typeof dadosLancamento.estatura === 'string' ?  string.removeMask(dadosLancamento.estatura,' cm') : dadosLancamento.estatura;
            const alturaEmMetro = alturaNumeroSemSufixo*0.01;
            const pesoNumeroSemSufixo = typeof dadosLancamento.peso === 'string' ? string.removeMask(dadosLancamento.peso,' kg') : dadosLancamento.peso;
            const imc = pesoNumeroSemSufixo/(alturaEmMetro * alturaEmMetro);
            const descricao = await getImcDescricao(sujeitoAtencao.sexo?.id  || sujeitoAtencao?.sexoId, idade, pesoNumeroSemSufixo, alturaEmMetro);
            setImc({ valor: imc.toFixed(1), descricao });
        }

        return; 
    };

    const getImcDescricao = async (sexoId, idade, peso, altura) => {
      if (sexoId && idade && peso && altura) {
        const imcDescricao = await calcImc({
          sexoId,
          idade,
          peso,
          altura,
        });
        if (imcDescricao) {
          return imcDescricao;
        }
      }
    };

    const handleClickLimpar = () => {
      setDadosLancamento(null);
    };

    const contemErros = () => {
      const valida = !(dadosLancamento.peso || 
        dadosLancamento.estatura ||
        dadosLancamento.quadril ||
        dadosLancamento.cintura || 
        dadosLancamento.perimetroCefalico ||
        dadosLancamento.circunferenciaBraquial ||
        dadosLancamento.pregaCutanea)
        
      return valida;
    };

    const handleClickEnviar = async() => {
      try{
        setLoadingEnviar(true);
        !data && setData(moment())

        if(contemErros()){
          const notification = {
            isOpen: true,
            message: 'Nenhum campo preenchido.',
            variant: 'error'
          };
          showAlertMessage(notification);
          return;
        }
        const dadosSemSufixo = {
          estatura: typeof dadosLancamento?.estatura === "string" ? string.removeMask(dadosLancamento.estatura,' cm') : dadosLancamento.estatura,
          peso: typeof dadosLancamento?.peso === "string" ? string.removeMask(dadosLancamento.peso,' kg') : dadosLancamento?.peso,
          quadril: typeof dadosLancamento?.quadril === "string" ? string.removeMask(dadosLancamento.quadril,' cm') : dadosLancamento?.quadril,
          cintura: typeof dadosLancamento?.cintura === "string" ? string.removeMask(dadosLancamento.cintura,' cm') : dadosLancamento.cintura,
          circunferenciaBraquial: typeof dadosLancamento?.circunferenciaBraquial === "string" ? string.removeMask(dadosLancamento.circunferenciaBraquial,' cm') : dadosLancamento.circunferenciaBraquial,
          perimetroCefalico: typeof dadosLancamento?.perimetroCefalico === "string" ? string.removeMask(dadosLancamento.perimetroCefalico,' cm') : dadosLancamento.perimetroCefalico,
          abdomen: typeof dadosLancamento?.abdomen === "string" ? string.removeMask(dadosLancamento.abdomen,' cm') : dadosLancamento.abdomen,
          bracoDireito: typeof dadosLancamento?.bracoDireito === "string" ? string.removeMask(dadosLancamento.bracoDireito,' cm') : dadosLancamento.bracoDireito,
          bracoEsquerdo: typeof dadosLancamento?.bracoEsquerdo === "string" ? string.removeMask(dadosLancamento.bracoEsquerdo,' cm') : dadosLancamento.bracoEsquerdo,
          coxaDireita: typeof dadosLancamento?.coxaDireita === "string" ? string.removeMask(dadosLancamento.coxaDireita,' cm') : dadosLancamento.coxaDireita,
          coxaEsquerda: typeof dadosLancamento?.coxaEsquerda === "string" ? string.removeMask(dadosLancamento.coxaEsquerda,' cm') : dadosLancamento.coxaEsquerda,
          pregaCutanea: typeof dadosLancamento?.pregaCutanea === "string" ? string.removeMask(dadosLancamento.pregaCutanea,' mm') : dadosLancamento.pregaCutanea,
        }        
        const sujeitoAtencaoId = { id: sujeitoAtencao.id };
        const avaliacaoAntropometrica = {
          ativo: true,
          data: moment(data).format("YYYY-MM-DDTHH:mm:ss"),
          ...dadosSemSufixo,
          sujeitoAtencao: sujeitoAtencaoId
        }
        
        await createAvaliacaoAntropometrica(avaliacaoAntropometrica);
        const notification = {
          isOpen: true,
          message: 'Lançamento da avaliação antropométrica realizado com sucesso!',
          variant: 'success'
        };
        showAlertMessage(notification);
        carregaListaHistoricoLancamento();
        avaliacaoAntropometricaStore.isTipoGraficoSemanal ? avaliacaoAntropometricaStore.findAllAvaliacaoAntropometricaMediaDiaria({ativo: true, sujeitoAtencao}) : avaliacaoAntropometricaStore.buscaDadosGrafico({sujeitoAtencao, tipo: tipoGrafico});
        
      } catch {
        const notification = {
          isOpen: true,
          message: 'Erro ao lançar a avaliação antropométrica.',
          variant: 'error'
        };
        showAlertMessage(notification)
      } finally {
        setLoadingEnviar(false);
      }      
    };

    const showAlertMessage = (notificationEnviado) => {
      setNotification(notificationEnviado);

      const timeoutId = setTimeout(() => {
        closeAlertMessage();
        clearTimeout(timeoutId);
      }, 3000);
    };
  
    const closeAlertMessage = () => {
      const notification = {
        isOpen: false,
        message: '',
        variant: ''
      };
      setNotification(notification);
    };

    const verificaLancamentoInativo = () => {
      setInativador(historicoLancamentoSelecionado?.entradaProntuario?.entradaProntuarioInativacao?.inativador?.nome || avaliacaoAntropometricaSelecionado?.entradaProntuario?.entradaProntuarioInativacao?.inativador?.nome)
    };


  return (
    <div className={classNames(classes.contentAvaliacao, {[classes.contentAvaliacaoSemBotoes]: isVisualizarLancamento})}>
      <div className={classes.tituloNovoLancamento}> 
        { isVisualizarLancamento ? 
        <>
          Ver lançamento
         { inativador && <div className={classes.informeLancamentoInativado}>
            Esse lançamento foi inativado por <span> { inativador } </span>
          </div>}
        </>
        : " Novo lançamento" 
        } 
      </div>
      <div
          onClick={actionClose}
          className={classes.buttonClose}
      >
          <CloseIcon/>
      </div>
      <div className={classes.contentDadosAvaliacao}>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> Data e hora: </span>
          <CustomDateTimePicker
            onChange={handleDateChange}
            value={data}
            invalidLabel=""
            invalidDateMessage=""
            disabled={isVisualizarLancamento}
          />
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> Peso: </span>
          <InputPeso
            className={classes.inputAlturaPeso}
            classInput={classes.inputBlockField}
            classInputRoot={classes.inputRootBlockField}
            value={dadosLancamento?.peso ? String(dadosLancamento?.peso).replace(".", ",") : ""}
            onChange={(e) => handleChange(e, "peso")}
            placeholder={"kg"}
            disabled={isVisualizarLancamento}
          />
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> Estatura: </span>
          <InputCm
            className={classes.inputAlturaPeso}
            classInput={classes.inputBlockField}
            classInputRoot={classes.inputRootBlockField}
            value={dadosLancamento?.estatura ? String(dadosLancamento?.estatura).replace(".", ",") : ""}
            onChange={(e) => handleChange(e, "estatura")}
            placeholder={"cm"}
            disabled={isVisualizarLancamento}
            mask={"cmWithDecimal"}
          />
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> IMC: </span>
          <div className={classes.inputImc}> 
            {dadosLancamento?.estatura && dadosLancamento?.peso && <StatusImc imc={imc.valor} imcDescricao={imc.descricao}/>}
          </div>
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> Quadril: </span>
          <InputCm
            className={classes.inputAlturaPeso}
            classInput={classes.inputBlockField}
            classInputRoot={classes.inputRootBlockField}
            value={dadosLancamento?.quadril || ""}
            onChange={(e) => handleChange(e, "quadril")}
            placeholder={"cm"}
            disabled={isVisualizarLancamento}
            mask={"cm"}
          />
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> Cintura: </span>
          <InputCm
            className={classes.inputAlturaPeso}
            classInput={classes.inputBlockField}
            classInputRoot={classes.inputRootBlockField}
            value={dadosLancamento?.cintura || ""}
            onChange={(e) => handleChange(e, "cintura")}
            placeholder={"cm"}
            disabled={isVisualizarLancamento}
            mask={"cm"}
          />
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> Perímetro cefálico: </span>
          <InputCm
            className={classes.inputAlturaPeso}
            classInput={classes.inputBlockField}
            classInputRoot={classes.inputRootBlockField}
            value={dadosLancamento?.perimetroCefalico ? String(dadosLancamento?.perimetroCefalico).replace(".", ",") : ""}
            onChange={(e) => handleChange(e, "perimetroCefalico")}
            placeholder={"cm"}
            disabled={isVisualizarLancamento}
            mask={"cmWithDecimal"}
          />
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> Circ. braquial: </span>
          <InputCm
            className={classes.inputAlturaPeso}
            classInput={classes.inputBlockField}
            classInputRoot={classes.inputRootBlockField}
            value={dadosLancamento?.circunferenciaBraquial || ""}
            onChange={(e) => handleChange(e, "circunferenciaBraquial")}
            placeholder={"cm"}
            disabled={isVisualizarLancamento}
            mask={"cm"}
          />
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> Pg cutânea: </span>
          <InputMm
            className={classes.inputAlturaPeso}
            classInput={classes.inputBlockField}
            classInputRoot={classes.inputRootBlockField}
            value={dadosLancamento?.pregaCutanea || ""}
            onChange={(e) => handleChange(e, "pregaCutanea")}
            placeholder={"mm"}
            disabled={isVisualizarLancamento}
          />
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> Abdômen: </span>
          <InputCm
            className={classes.inputAlturaPeso}
            classInput={classes.inputBlockField}
            classInputRoot={classes.inputRootBlockField}
            value={dadosLancamento?.abdomen || ""}
            onChange={(e) => handleChange(e, "abdomen")}
            placeholder={"cm"}
            disabled={isVisualizarLancamento}
          />
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> Braço Direito: </span>
          <InputCm
            className={classes.inputAlturaPeso}
            classInput={classes.inputBlockField}
            classInputRoot={classes.inputRootBlockField}
            value={dadosLancamento?.bracoDireito || ""}
            onChange={(e) => handleChange(e, "bracoDireito")}
            placeholder={"cm"}
            disabled={isVisualizarLancamento}
          />
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> Braço Esquerdo: </span>
          <InputCm
            className={classes.inputAlturaPeso}
            classInput={classes.inputBlockField}
            classInputRoot={classes.inputRootBlockField}
            value={dadosLancamento?.bracoEsquerdo || ""}
            onChange={(e) => handleChange(e, "bracoEsquerdo")}
            placeholder={"cm"}
            disabled={isVisualizarLancamento}
          />
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> Coxa Direita: </span>
          <InputCm
            className={classes.inputAlturaPeso}
            classInput={classes.inputBlockField}
            classInputRoot={classes.inputRootBlockField}
            value={dadosLancamento?.coxaDireita || ""}
            onChange={(e) => handleChange(e, "coxaDireita")}
            placeholder={"cm"}
            disabled={isVisualizarLancamento}
          />
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <span className={classes.titulo}> Coxa Esquerda: </span>
          <InputCm
            className={classes.inputAlturaPeso}
            classInput={classes.inputBlockField}
            classInputRoot={classes.inputRootBlockField}
            value={dadosLancamento?.coxaEsquerda || ""}
            onChange={(e) => handleChange(e, "coxaEsquerda")}
            placeholder={"cm"}
            disabled={isVisualizarLancamento}
          />
        </Grid>
      </div>
      { !isVisualizarLancamento && <div className={classes.contentButtonsActionNovoLancamento}>
        <Button 
          onClick={handleClickLimpar} 
          disabled={false}
          className={[classes.buttonsAcaoNovoLancamento, classes.buttonCancelarNovoLancamento]}
        > 
          Limpar 
        </Button>
        <Button 
          onClick={handleClickEnviar} 
          disabled={loadingEnviar}
          className={[classes.buttonsAcaoNovoLancamento, classes.buttonGreenNovoLancamento]}
        > 
          Enviar 
          {loadingEnviar && <CircularProgress className={classes.circularCarrega} size={14} color={'#FFF'}/>}
        </Button>
        
      </div>}
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </div>
  );
};

const NovoLancamentoWithStyles = withStyles(style)(NovoLancamento);
export default inject("avaliacaoAntropometricaStore")(NovoLancamentoWithStyles);
