import colors from "../../../template/Colors";

const styles = (theme) => ({
  content: {
    display: "grid",
    gridTemplateRows: "80px 71px 1fr",
    position: "relative",
    overflow: "hidden",
    background: "#f5f5f5",

    "& thead th": {
      textAlign: "left",
      position: "sticky",
      top: 0,

      "&:first-child": {
        padding: "0 0 0 24px",
      },

      "&:last-child": {
        paddingRight: 0,
      },
    },
    "& tbody td": {
      padding: "0 0 0 24px",
      maxWidth: "120px",
      overflowWrap: "break-word",

      "& .status": {
        width: 16,
        height: 16,
        borderRadius: 100,
        display: "inline-block",
      },
      "& .true": {
        backgroundColor: "#00C1BF",
      },
      "& .false": {
        backgroundColor: "#FB7676",
      },
      "& .agendado": {
        backgroundColor: colors.status.agendado,
      },
      "& .aguardando": {
        backgroundColor: colors.status.aguardando,
      },
      "& .confirmado": {
        backgroundColor: colors.status.confirmado,
      },
      "& .atendendo": {
        backgroundColor: colors.status.atendendo,
      },
      "& .atendido": {
        backgroundColor: colors.status.atendido,
      },
      "& .cancelado": {
        backgroundColor: colors.status.cancelado,
      },
      "& .excluido": {
        backgroundColor: colors.status.excluido,
      },
      "& .bloqueado": {
        backgroundColor: colors.status.bloqueado,
      },
      "& .faltou": {
        backgroundColor: colors.status.faltou,
      },
    },
  },

  scrollContainer: {
    height: "100%",
    "&>div": {
      height: "100%",
    },
  },

  tableDadosAniversariantes: {
    overflow: "auto",
  },

  notFoundContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    background: "#fff",

    "& h3": {
      color: colors.commons.gray10,
      fontWeight: "normal",
      fontSize: "1rem",
    },
  },

  inputData: {
    color: colors.commons.fontColor,
    paddingTop: 0,
  },
  spaceTop: {
    display: "flex",
    flexDirection: "row",
  },
  filtros: {
    display: "flex",
    flexDirection: "row",
    padding: "13px 16px 8px 16px",
    boxShadow:
      "10px 10px 25px rgb(112 124 151 / 5%), 15px 15px 35px rgb(112 124 151 / 5%)",
    background: "#fff",
    zIndex: 1,
    marginTop: 1,
    alignItems: "center",
  },
  spacing: {
    paddingRight: 16,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tituloFiltros: {
    color: colors.commons.gray7,
    fontSize: "12px !important",
  },
  notchedOutline: {
    border: "0",
  },
  inputTextField: {
    fontSize: "14px",
    border: "0",
    minHeight: "6px",
    height: 32,
    color: colors.commons.fontColor,
    backgroundColor: colors.commons.gray2,
    margin: "0 0 0 0",
    borderRadius: "100px",
  },
  classIcons: {
    fontSize: 24,
  },
  buttonsDownloadPrint: {
    display: "flex",
    position: "absolute",
    bottom: "20px",
    right: "14px",
    width: "88px",
    justifyContent: "space-between",
  },
  listaAgendamentoSituacao: {
    overflow: "auto",
    height: "calc(100% - 16px)",
    margin: "16px",
    borderRadius: "16px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    background: "#fff",
    "&> div": {
      height: "100%",
      "&> div": {
        height: "100%",
      },
    },
  },
  selectSituacao: {
    width: "100%",
    background: "#F2F2F2",
    cursor: "pointer",
    borderRadius: "100px",
    height: "33px",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "space-between",
    "&>span": {
      color: "#505050",
      marginLeft: "15px",
      maxWidth: "65%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "&>svg": {
      marginRight: "5px",
    },
  },
  checkboxOptions: {
    display: "flex",
    flexDirection: "column",
    "&>label": {
      marginLeft: 11,
      borderRadius: "100px",
      marginBottom: 8,
      paddingLeft: 5,
      overflow: "hidden",
    },
  },
  wrapperColorBox: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
  colorBox: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    border: "1.5px solid rgba(220, 220, 220, 0.2)",
  },
  popper: {
    zIndex: 20,
    width: "fit-content",
    left: "-20px",
  },
  paperSituacao: {
    padding: "10px 0",
  },
  colorArrow: {
    color: colors.commons.gray7,
    background: "#F2F2F2",
  },
  spacingConvenio: {
    maxWidth: 175,
    paddingRight: 16,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  rowInfos: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: colors.commons.gray9,
    fontFamily: "Poppins",
    height: "18px",
    marginBottom: "9px",
    fontSize: 12,
    "& span": {
      fontWeight: 700,
      fontSize: 14,
    },
  },
  contentInfos: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  contentTable: {
    overflow: "auto",
    background: "#fff",
  },
  wrapperCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px",
    "&>label": {
      marginLeft: 0,
      flex: 1,
    },
  },
  contentSituacao: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "end",
  },
  paper: {
    width: "252px",
    right: "-20px",
    left: "auto",
  },
});

export default styles;
