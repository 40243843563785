import React from 'react';
import { withStyles } from '@material-ui/core';
import AbbreviatedLabel from '../../Labels/AbbreviatedLabel';
import Tooltip from '@material-ui/core/Tooltip';

const styles = ({
  eventItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  eventItemText: {
    paddingTop: '8px',
    paddingLeft: '8px',
    whiteSpace: 'normal'
  }
});

const EventCard = ({ event, classes }) => {
  const eventItem = event.event;
  return (
    <div className={classes.eventItemContainer}>
      <span className={classes.eventItemText} >{eventItem.title}</span>
      {eventItem?.id && (
        <Tooltip 
          title={`Agendamento realizado pelo ${eventItem.paciente?.id ? 'Vizi Saúde' : 'App Health'}`} 
          placement="top"
        > 
          <div>
            {eventItem.paciente?.id ?
              <AbbreviatedLabel text="VS" color="#6639F4" /> :
              <AbbreviatedLabel text="AH" color="#229A97" />
            }
          </div>
        </Tooltip>
      )}
    </div>
  )
};

export default withStyles(styles)(EventCard);