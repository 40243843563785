import { action, observable } from 'mobx';
import BaseStore from "./Base.store";
import { 
  updatePerfilPublico,
  createPerfilPublicoUsuario,
  createPerfilPublicoUnidade,
  findAllConvenioSistema, 
  updatePerfilPublicoFotoBanner,
  updatePerfilPublicoFotoPerfil,
  findAllAvaliacaoByPerfilPublico,
  findByUnidadeLogadaPerfilPublico,
  findByUsuarioLogadoPerfilPublico,
  updatePerfilPublicoAvaliacao
} from '../services/PerfilPublicoService';
import { isValid } from '../utils/socialMediaLinks';
import moment from 'moment';
import string from '../utils/string';
import { uploadFileWithNestor } from '../utils/uploadFileWithNestor';
import localStorageService, { ACCESS_TOKEN_KEY } from "../services/storage";
import { ElementsList } from '../pages/ProfissionalSaude/constants/perfilViziConstants';

const perfilPublicoUnidadeDefault = {
  id: '',
  ativo: true,
  atendeCrianca: false,
  atendePresencial: false,
  utilizaTelemedicina: false,
  convenios: [],
  especialidades: [],
  formacao: '',
  formasPagamento: [],
  permiteProfissionalUnidade: false,
  permiteBuscaProfissional: false,
  horarioAtendimento: '',
  exibeMedidasSeguranca: false,
  medidasSeguranca: '',
  permiteBuscaGoogle: true,
  email: '',
  nome: '',
  nomeLinkPublico: '',
  facebook: '',
  instagram: '',
  linkedin: '',
  twitter: '',
  fotoPerfil: null,
  banner: null,
  perfisPublicos: [],
  idiomas: [],
  telefonePrincipal: '',
  telefoneSecundario: '55',
  corPersonalizada: '',
};

const perfilPublicoProfissionalDefault = {
  id: '',
  ativo: true,
  atendeCrianca: false,
  atendePresencial: false,
  utilizaTelemedicina: false,
  permiteBuscaProfissional: false,
  exibeMedidasSeguranca: false,
  medidasSeguranca: '',
  permiteBuscaGoogle: true,
  horarioAtendimento: "",
  convenios: [],
  especialidades: [],
  idiomas: [],
  email: '',
  nome: '',
  nomeLinkPublico: '',
  facebook: '',
  instagram: '',
  linkedin: '',
  twitter: '',
  fotoPerfil: null,
  banner: null,
  avaliacaoQuantidade: 0,
  avaliacaoNotaMedia: 0,
  corPersonalizada: '',
  telefonePrincipal: '',
  telefoneSecundario: '',
};

const convenioSistemaPageableDefault = {
  search: '',
  pageableDTO: {
    pageNumber: 0,
    pageSize: 99,
    sortField: 'descricao'
  }
};

const avaliacaoFilterDefault = {
  dataInicial: moment().startOf("month").format("YYYY-MM-DD"),
  dataFinal: moment().endOf("month").format("YYYY-MM-DD"),
};

const perceptionsByTypeCountersDefault = {
  recepcao: 0,
  pontualidade: 0,
  dificuldadeAoAgendar: 0,
  explicacoesClaras: 0,
  ajudou: 0,
  atencioso: 0,
  opcoesPagamento: 0,
  localizacao: 0,
  ambienteAgradavel: 0,
  acessibilidade: 0,
};

const profissionalErrorsDefault = {
  nome: {
    name: 'Nome',
    error: false
  },
  fotoPerfil: {
    name: 'Foto de perfil',
    error: false
  },
  formasPagamento: {
    name: 'Meios de Pagamento',
    error: false
  },
  atendimento: {
    name: 'Atendimento(online ou presencial)',
    error: false
  },
  especialidades: {
    name: 'Especialidades',
    error: false
  }
};

const unidadeErrorsDefault = {
  nome: {
    name: 'Nome',
    error: false
  },
  fotoPerfil: {
    name: 'Foto de perfil',
    error: false
  },
  especialidades: {
    name: 'Especialidades',
    error: false
  }
};

const updatePerfilPublicoErrorMessage = 'Falha ao atualizar perfil público.';
const createPerfilPublicoErrorMessage = 'Falha ao criar perfil público';

export default class PerfilPublicoStore extends BaseStore {
  @observable perfilPublicoUnidade = perfilPublicoUnidadeDefault;
  @observable perfilPublicoProfissional = perfilPublicoProfissionalDefault;
  @observable convenioSistemaPageable = convenioSistemaPageableDefault;
  @observable convenioSistemaLastPage = false;
  @observable convenioSistemaList = [];
  @observable loadingProfileImage = false;
  @observable loadingBannerImage = false;
  @observable canDisableSaveButtonThroughEspecialidade = false;
  @observable accessToken = null;
  @observable perfilPublicoProfissionalLogadoAvaliacoes = [];
  @observable notification = {
    isOpen: false,
    variant: '',
    message: ''
  };
  @observable positivePerceptions = [];
  @observable negativePerceptions = [];
  @observable positivePerceptionsCountersByType = perceptionsByTypeCountersDefault;
  @observable negativePerceptionsCountersByType = perceptionsByTypeCountersDefault;
  @observable avaliacaoFilter = avaliacaoFilterDefault;
  @observable withoutDateRange = false;
  @observable avaliacaoPageable = {
    pageSize: 10,
    pageNumber: 0,
    sortField: 'gostou',
    sortDir: 'ASC'
  };
  @observable lastAvaliacaoPage = false;
  @observable perfilPublicoProfissionalErrors = profissionalErrorsDefault;
  @observable perfilPublicoUnidadeErrors = unidadeErrorsDefault;
  @observable perfilUnidadeIsIncomplete = false;
  @observable perfilProfissionalIsIncomplete = false;
  @observable loadingData = false;
  @observable habilitaCorPersonalizado = false;

  constructor(rootStore) {
    super();
    this.profissionalSaudeStore = rootStore.profissionalSaudeStore;
    this.unidadeStore = rootStore.unidadeStore;
    this.getAccessToke();
  };

  @action initPerfilPublicoUnidadeDefault() {
    this.perfilPublicoUnidade = perfilPublicoUnidadeDefault;
  }

  @action initPerfilPublicoProfissionalDefault() {
    this.perfilPublicoProfissional = perfilPublicoProfissionalDefault;
  }

  @action resetAvaliacaoFilter() {
    this.withoutDateRange = false;
    this.avaliacaoFilter = avaliacaoFilterDefault;
  }

  getAccessToke = async () => {
    this.accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
  }

  validateSocialMediaLinks = (value) => {
    if (value && isValid(value)) {
      return value;
    } else {
      return '';
    }
  };

  @action async updatePerfilPublicoProfissional() {
    const { 
      facebook, 
      instagram, 
      linkedin, 
      twitter, 
      especialidades: perfilPublicoEspecialidades, 
      convenios, 
      telefonePrincipal, 
      telefoneSecundario 
    } = this.perfilPublicoProfissional;
    const especialidades = this.convertEspecialidadesValuesStringToNumber(perfilPublicoEspecialidades);

    
    const perfilPublico = {
      ...this.perfilPublicoProfissional,
      especialidades,
      telefonePrincipal: string.removeSpecialChars(telefonePrincipal),
      telefoneSecundario: string.removeSpecialChars(telefoneSecundario),
      facebook: this.validateSocialMediaLinks(facebook), 
      instagram: this.validateSocialMediaLinks(instagram),
      linkedin: this.validateSocialMediaLinks(linkedin),
      twitter: this.validateSocialMediaLinks(twitter),
      convenios: convenios?.filter((convenio) => convenio.convenioSistema?.id).map((convenio) => ({
        id: convenio?.convenioSistema?.id,
      }))
    };
    
    delete perfilPublico.avaliacaoQuantidade;
    delete perfilPublico.avaliacaoNotaMedia;
    delete perfilPublico.idPublico;
    delete perfilPublico.perfisPublicos;
    
    try {
      const response = await updatePerfilPublico(perfilPublico);
      if (!response.data?.data?.updatePerfilPublico?.id) {
        throw response.data.error[0];
      } else {
        await this.loadPerfilPublicoUsuarioLogado();
      }
    } catch (error) {
      throw(updatePerfilPublicoErrorMessage)
    }
  };

  @action async updatePerfilPublicoUnidade() {
    const { unidade, unidadePublica } = this.unidadeStore;

    const especialidades = this.convertEspecialidadesValuesStringToNumber(this.perfilPublicoUnidade.especialidades);
    const perfilPublico = {
      id: this.perfilPublicoUnidade.id,
      ativo: unidade.perfilPublico,
      telefonePrincipal: string.removeSpecialChars(unidade.telefonePrincipal).substr(unidade.telefonePrincipalDDI.length),
      permiteProfissionalUnidade: this.perfilPublicoUnidade.permiteProfissionalUnidade,
      nome: unidade.nomeFantasia,
      facebook: this.validateSocialMediaLinks(this.perfilPublicoUnidade.facebook), 
      instagram: this.validateSocialMediaLinks(this.perfilPublicoUnidade.instagram),
      linkedin: this.validateSocialMediaLinks(this.perfilPublicoUnidade.linkedin),
      twitter: this.validateSocialMediaLinks(this.perfilPublicoUnidade.twitter),
      permiteBuscaProfissional: this.perfilPublicoUnidade.permiteBuscaProfissional,
      convenios: this.perfilPublicoUnidade.convenios,
      formasPagamento: this.perfilPublicoUnidade.formasPagamento,
      idiomas: this.perfilPublicoUnidade.idiomas,
      especialidades: especialidades,
      fotoPerfil: this.perfilPublicoUnidade.fotoPerfil,
      banner: this.perfilPublicoUnidade.banner,
      email: unidadePublica.email,
      sobre: unidadePublica.sobre,
      telefoneSecundario: unidadePublica.telefoneSecundario,
      horarioAtendimento: this.perfilPublicoUnidade.horarioAtendimento,
      exibeMedidasSeguranca: this.perfilPublicoUnidade.exibeMedidasSeguranca,
      medidasSeguranca: this.perfilPublicoUnidade.medidasSeguranca,
      permiteBuscaGoogle: this.perfilPublicoUnidade.permiteBuscaGoogle,
      atendePresencial: this.perfilPublicoUnidade.atendePresencial,
      utilizaTelemedicina: !!this.perfilPublicoUnidade.utilizaTelemedicina,
      corPersonalizada: this.habilitaCorPersonalizado ? this.perfilPublicoUnidade.corPersonalizada : null,
    };
    try {
      const response = await updatePerfilPublico(perfilPublico);
      if (!response.data?.data?.updatePerfilPublico?.id) {
        throw response.data.error[0];
      } else {
        this.perfilPublicoUnidade = {
          ...this.perfilPublicoUnidade,
          ...response.data.data.updatePerfilPublico,
        }
      }
    } catch (error) {
      throw(updatePerfilPublicoErrorMessage);
    }
  }

  @action async createPerfilPublicoUnidade() {
    const { unidade, unidadePublica } = this.unidadeStore;

    const perfilPublico = {
      ativo: unidade.perfilPublico,
      nome: unidade.nomeFantasia,
      permiteProfissionalUnidade: this.perfilPublicoUnidade.permiteProfissionalUnidade,
      exibeMedidasSeguranca: this.perfilPublicoUnidade.exibeMedidasSeguranca,
      medidasSeguranca: this.perfilPublicoUnidade.medidasSeguranca,
      permiteBuscaGoogle: this.perfilPublicoUnidade.permiteBuscaGoogle,
      facebook: this.validateSocialMediaLinks(this.perfilPublicoUnidade.facebook), 
      instagram: this.validateSocialMediaLinks(this.perfilPublicoUnidade.instagram),
      linkedin: this.validateSocialMediaLinks(this.perfilPublicoUnidade.linkedin),
      twitter: this.validateSocialMediaLinks(this.perfilPublicoUnidade.twitter),
      permiteBuscaProfissional: this.perfilPublicoUnidade.permiteBuscaProfissional,
      idiomas: this.perfilPublicoUnidade.idiomas,
      fotoPerfil: this.perfilPublicoUnidade.fotoPerfil,
      banner: this.perfilPublicoUnidade.banner,
      email: unidadePublica.email,
      horarioAtendimento: this.perfilPublicoUnidade.horarioAtendimento,
      telefonePrincipal: string.removeSpecialChars(unidade.telefonePrincipal).substr(unidade.telefonePrincipalDDI.length),
      atendePresencial: !!this.perfilPublicoUnidade.atendePresencial,
      utilizaTelemedicina: !!this.perfilPublicoUnidade.utilizaTelemedicina,
      unidade
    };

    try {
      const response = await createPerfilPublicoUnidade(perfilPublico);
      if (!response.data?.data?.createPerfilPublicoUnidade?.id) {
        throw response.data.error[0];
      } else {
        this.perfilPublicoUnidade = {
          ...this.perfilPublicoUnidade,
          ...response.data.data.updatePerfilPublico,
        }
      }
    } catch(error) {
      throw(createPerfilPublicoErrorMessage);
    }
  }

  @action async createPerfilPublicoProfissional() {
    const { profissionalSaude, profissionalSaudePublico } = this.profissionalSaudeStore;

    delete profissionalSaude.compartilharProntuario;

    const perfilPublico = {
      ativo: profissionalSaude.perfilPublico,
      atendeCrianca: profissionalSaude.atendeCrianca,
      atendePresencial: !!profissionalSaude.atendePresencial,
      convenios: profissionalSaude?.convenios?.filter((convenio) => convenio.convenioSistema?.id).map((convenio) => ({
        id: convenio?.convenioSistema?.id,
      })),
      email: profissionalSaude.usuario.email,
      experiencia: profissionalSaudePublico.informacao.experiencia,
      especialidades: this.perfilPublicoProfissional.especialidades,
      formacao: profissionalSaudePublico.informacao.formacao,
      formasPagamento: profissionalSaudePublico.informacao.formasPagamento,
      sobre: profissionalSaudePublico.sobre,
      exibeMedidasSeguranca: this.perfilPublicoProfissional.exibeMedidasSeguranca,
      medidasSeguranca: this.perfilPublicoProfissional.medidasSeguranca,
      permiteBuscaGoogle: this.perfilPublicoProfissional.permiteBuscaGoogle,
      telefonePrincipal: string.removeSpecialChars(profissionalSaudePublico.telefonePrincipal),
      telefoneSecundario: string.removeSpecialChars(profissionalSaudePublico.telefoneSecundario),
      nome: profissionalSaude.nome,
      idiomas: profissionalSaudePublico.informacao.idiomas,
      permiteBuscaProfissional: this.perfilPublicoProfissional.permiteBuscaProfissional,
      facebook: this.validateSocialMediaLinks(this.perfilPublicoProfissional.facebook), 
      instagram: this.validateSocialMediaLinks(this.perfilPublicoProfissional.instagram),
      linkedin: this.validateSocialMediaLinks(this.perfilPublicoProfissional.linkedin),
      twitter: this.validateSocialMediaLinks(this.perfilPublicoProfissional.twitter),
      utilizaTelemedicina: profissionalSaude.utilizaTelemedicina,
      horarioAtendimento: this.perfilPublicoProfissional.horarioAtendimento,
      profissionaisSaudes: [
        {
          ...profissionalSaude,
          dataNascimento: moment(profissionalSaude.dataNascimento).format('YYYY-MM-DD'),
        }
      ],
      fotoPerfil: this.perfilPublicoProfissional.fotoPerfil,
      banner: this.perfilPublicoProfissional.banner,
    }

    try {
      const response = await createPerfilPublicoUsuario(perfilPublico);
      if (!response.data?.data?.createPerfilPublicoUsuario?.id) {
        throw response.data.error[0];
      } else {
        await this.loadPerfilPublicoUsuarioLogado();
      }
    } catch(error) {
      throw(createPerfilPublicoErrorMessage);
    }
  }

  convertEspecialidadesValuesStringToNumber = (especialidades) => {
    const especialidadesUpdated = especialidades.map(especialidade => {
      return {
        ativo: especialidade.ativo,
        especialidadeMedica: especialidade.especialidadeMedica,
        anamneseModelo: especialidade.anamneseModelo,
        valorOnline: typeof(especialidade.valorOnline) === 'string' ?
          string.currencyMaskToFloat(especialidade.valorOnline) :
          especialidade.valorOnline,
        valorPresencial: typeof(especialidade.valorPresencial) === 'string' ?
          string.currencyMaskToFloat(especialidade.valorPresencial) :
          especialidade.valorPresencial,
      };
    });
    return especialidadesUpdated;
  }

  @action async createProfissionalPerfilPublicoWithMainData() {
    const { profissionalSaude } = this.profissionalSaudeStore;

    delete profissionalSaude.compartilharProntuario;

    const perfilPublico = {
      ativo: true,
      nome: profissionalSaude.nome,
      atendePresencial: !!profissionalSaude.atendePresencial,
      utilizaTelemedicina: profissionalSaude.utilizaTelemedicina,
      profissionaisSaudes: [
        {
          ...profissionalSaude,
          dataNascimento: moment(profissionalSaude.dataNascimento).format('YYYY-MM-DD'),
        }
      ],
    };

    try {
      const response = await createPerfilPublicoUsuario(perfilPublico);
      if (!response.data?.data?.createPerfilPublicoUsuario?.id) {
        throw response.data.error[0];
      } else {
        this.perfilPublicoProfissional = {
          ...this.perfilPublicoProfissional,
          ...response.data.data.createPerfilPublicoUsuario,
        }
      }
    } catch(error) {
      throw(createPerfilPublicoErrorMessage);
    }
  }

  @action async createUnidadePerfilPublicoWithMainData() {
    await this.unidadeStore.getUnidadeLogada();
    
    const { unidade, unidadePublica } = this.unidadeStore;

    const perfilPublico = {
      ativo: unidade?.perfilPublico,
      nome: unidade?.nomeFantasia,
      email: unidadePublica?.email,
      atendePresencial: false,
      utilizaTelemedicina: false,
    };
    try{
      const response = await createPerfilPublicoUnidade(perfilPublico);
      if (!response.data?.data?.createPerfilPublicoUnidade?.id) {
        throw response.data.error[0];
      } else {
        await this.loadPerfilPublicoUnidadeLogada();
      }
    } catch(error) {
      throw(createPerfilPublicoErrorMessage);
    }
  }

  @action async shouldCreateMininumUnidadePerfilPublico() {
    const { unidade } = this.unidadeStore;
    return !this?.perfilPublicoUnidade?.id && unidade?.perfilPublico;
  }

  @action updateProfileImage = async (perfilId, blob, type) => {
    this.loadingProfileImage = true;
    const result = await uploadFileWithNestor(blob);
    if (result.data.id) {
      if (type === 'profissional') {
        this.perfilPublicoProfissional = {
          ...this.perfilPublicoProfissional,
          fotoPerfil: result.data.id
        }
      } else {
        this.perfilPublicoUnidade = {
          ...this.perfilPublicoUnidade,
          fotoPerfil: result.data.id
        }
      }
      const response = await updatePerfilPublicoFotoPerfil(result.data.id, perfilId);
      if (!response.data.data.updatePerfilPublicoFotoPerfil) {
        this.loadingProfileImage = false;
        throw `Falha ao atualizar a foto do perfil ${type === 'profissional' ? 'do profissional' : 'da unidade'} .`;
      }
    }
    this.loadingProfileImage = false;
  }
  
  @action updateBannerImage = async (perfilId, blob, type) => {
    this.loadingBannerImage = true;
    const result = await uploadFileWithNestor(blob);
    if (result.data.id) {
      if (type === 'profissional') {
        this.perfilPublicoProfissional = {
          ...this.perfilPublicoProfissional,
          banner: result.data.id
        }
      } else {
        this.perfilPublicoUnidade = {
          ...this.perfilPublicoUnidade,
          banner: result.data.id
        }
      }
      const response = await updatePerfilPublicoFotoBanner(result.data.id, perfilId);
      if (!response.data.data.updatePerfilPublicoFotoBanner) {
        this.loadingProfileImage = false;
        throw `Falha ao atualizar o banner do perfil ${type === 'profissional' ? 'do profissional' : 'da unidade'} .`;
      }
    }
    this.loadingBannerImage = false;
  }

  @action removeProfileImage = async (perfilId, type) => {
    if (type === 'profissional') {
      this.perfilPublicoProfissional = {
        ...this.perfilPublicoProfissional,
        fotoPerfil: null,
      }
    } else {
      this.perfilPublicoUnidade = {
        ...this.perfilPublicoUnidade,
        fotoPerfil: null,
      }
    }
    const response = await updatePerfilPublicoFotoPerfil(null, perfilId);
    if (!response.data.data.updatePerfilPublicoFotoPerfil) {
      this.loadingProfileImage = false;
      throw `Falha ao atualizar a foto de perfil ${type === 'profissional' ? 'do profissional' : 'da unidade'} .`;
    }
  };

  @action removeBannerImage = async (perfilId, type) => {
    if (type === 'profissional') {
      this.perfilPublicoProfissional = {
        ...this.perfilPublicoProfissional,
        banner: null,
      }
    } else {
      this.perfilPublicoUnidade = {
        ...this.perfilPublicoUnidade,
        banner: null,
      }
    }
    const response = await updatePerfilPublicoFotoBanner(null, perfilId);
    if (!response.data.data.updatePerfilPublicoFotoBanner) {
      this.loadingProfileImage = false;
      throw `Falha ao remover o banner do perfil ${type === 'profissional' ? 'do profissional' : 'da unidade'} .`;
    }
  };

  @action findAllConvenioSistemaList = async () => {
    if (!this.convenioSistemaLastPage) {
      try {
        const { content, last } = await findAllConvenioSistema(this.convenioSistemaPageable.search, this.convenioSistemaPageable.pageableDTO);
        const processed = content.map(item => ({name: item.descricao, value: item.id}));
        this.convenioSistemaList = [...this.convenioSistemaList, ...processed];
        this.convenioSistemaLastPage = last;
      } catch (error) {
        throw(error);
      }
    }
  };

  @action findAllAvaliacaoProfissionalLogado = async () => {
    try {
      if (this.avaliacaoPageable.pageNumber === 0) {
        this.resetPerceptions();
      }

      const response = await findAllAvaliacaoByPerfilPublico(
        this.perfilPublicoProfissional.id, 
        this.withoutDateRange ? null : this.avaliacaoFilter.dataInicial,
        this.withoutDateRange ? null : this.avaliacaoFilter.dataFinal,
        this.avaliacaoPageable,
      );
      if (response.data?.data?.findAllAvaliacaoByPerfilPublico?.content) {
        this.perfilPublicoProfissionalLogadoAvaliacoes = [
          ...this.perfilPublicoProfissionalLogadoAvaliacoes, 
          ...response.data.data.findAllAvaliacaoByPerfilPublico.content
        ];
        this.lastAvaliacaoPage = response.data.data.findAllAvaliacaoByPerfilPublico.last;
      }
    } catch(error) {
      throw error
    }
  };

  @action updateAvaliacao = async (avaliacaoId, value) => {
    try {
      const response = await updatePerfilPublicoAvaliacao(avaliacaoId, value);
      if (response.data?.data?.updatePerfilPublicoAvaliacao?.id) {
        const avaliacao = response.data.data.updatePerfilPublicoAvaliacao;
        this.toogleAvaliacaoLikeById(avaliacao);
      } else {
        throw new Error('Falha ao atualizar avaliação');
      } 
    } catch(error) {
      this.openNotification(error.message, 'error');
    }
  };

  toogleAvaliacaoLikeById = (avaliacao) => {
    const avaliacaoIndex = this.getAvaliacaoIndexById(avaliacao.id);
    const aux = [...this.perfilPublicoProfissionalLogadoAvaliacoes];
    aux[avaliacaoIndex] = avaliacao;
    this.perfilPublicoProfissionalLogadoAvaliacoes = aux;
  };

  getAvaliacaoIndexById = (id) => {
    return this.perfilPublicoProfissionalLogadoAvaliacoes.findIndex(avaliacao => avaliacao.id === id);
  }

  resetPerceptions = () => {
    this.positivePerceptions = [];
    this.positivePerceptionsCountersByType = perceptionsByTypeCountersDefault;
    this.negativePerceptions = [];
    this.negativePerceptionsCountersByType = perceptionsByTypeCountersDefault;
  };

  @action counterPositivePerceptionsByType = async () => {
    this.positivePerceptionsCountersByType = perceptionsByTypeCountersDefault;
    this.positivePerceptions.forEach(perception => {
      switch(perception.experiencia) {
        case 'Boa recepção':
          this.positivePerceptionsCountersByType.recepcao += 1;
          break;
        case 'Atendimento pontual':
          this.positivePerceptionsCountersByType.pontualidade += 1;
          break;
        case 'Agendamento facil':
          this.positivePerceptionsCountersByType.dificuldadeAoAgendar += 1;
          break;
        case 'Explicações de forma clara':
          this.positivePerceptionsCountersByType.explicacoesClaras += 1;
          break;
        case 'Ajudou com meu problema':
          this.positivePerceptionsCountersByType.ajudou += 1;
          break;
        case 'Profissional Atencioso(a)':
          this.positivePerceptionsCountersByType.atencioso += 1;
          break;
        case 'Várias opções de pagamento':
          this.positivePerceptionsCountersByType.opcoesPagamento += 1;
          break;
        case 'Consultório bem localizado':
          this.positivePerceptionsCountersByType.localizacao += 1;
          break;
        case 'Ambiente agradavel':
          this.positivePerceptionsCountersByType.ambienteAgradavel += 1;
          break;
        default:
          this.positivePerceptionsCountersByType.acessibilidade += 1;
      }
    });
  }

  @action counterNegativePerceptionsByType = async () => {
    this.negativePerceptionsCountersByType = perceptionsByTypeCountersDefault;
    this.negativePerceptions.forEach(perception => {
      switch(perception.experiencia) {
        case 'Recepção poderia ser melhor':
          this.negativePerceptionsCountersByType.recepcao += 1;
          break;
        case 'Atraso no atendimento':
          this.negativePerceptionsCountersByType.pontualidade += 1;
          break;
        case 'Agendamento confuso':
          this.negativePerceptionsCountersByType.dificuldadeAoAgendar += 1;
          break;
        case 'Comunicação confusa':
          this.negativePerceptionsCountersByType.explicacoesClaras += 1;
          break;
        case 'Não me ajudou':
          this.negativePerceptionsCountersByType.ajudou += 1;
          break;
        case 'Consulta muito rápida':
          this.negativePerceptionsCountersByType.atencioso += 1;
          break;
        case 'Poucas opções de pagamento':
          this.negativePerceptionsCountersByType.opcoesPagamento += 1;
          break;
        case 'Difícil localização':
          this.negativePerceptionsCountersByType.localizacao += 1;
          break;
        case 'Ambiente inapropriado':
          this.negativePerceptionsCountersByType.ambienteAgradavel += 1;
          break;
        default:
          this.negativePerceptionsCountersByType.acessibilidade += 1;
      }
    });
  };

  verifyIfEspecialidadeContainValue = () => {
    const { profissionalSaude } = this.profissionalSaudeStore;
    return this.perfilPublicoProfissional?.especialidades?.some(especialidade => { 
      if(profissionalSaude?.atendePresencial && !especialidade?.valorPresencial){
        return true
      }
      if(profissionalSaude?.utilizaTelemedicina && !especialidade?.valorOnline){
        return true
      }
      return false
    })
  }

  checkPerfilProfissionalIsComplete = () => {
    const { profissionalSaude, profissionalSaudePublico } = this.profissionalSaudeStore;
    this.perfilPublicoProfissionalErrors = {
      nome: {
        name: 'Nome',
        error: profissionalSaude.nome?.length === 0,
      },
      fotoPerfil: {
        name: 'Foto de perfil',
        error: !Boolean(this.perfilPublicoProfissional?.fotoPerfil),
      },
      formasPagamento: {
        name: 'Meios de Pagamento',
        error:  profissionalSaudePublico.informacao.formasPagamento?.length === 0,
      },
      atendimento: {
        name: 'Atendimento(online ou presencial)',
        error: !profissionalSaude.atendePresencial && !profissionalSaude.utilizaTelemedicina,
      },
      especialidades: {
        name: 'Especialidades',
        error: this.perfilPublicoProfissional?.especialidades?.length === 0 || this.verifyIfEspecialidadeContainValue(),
      }
    };
    this.canDisableSaveButtonThroughEspecialidades = this.perfilPublicoProfissionalErrors.especialidades.error;
    this.perfilProfissionalIsIncomplete = 
      this.perfilPublicoProfissionalErrors.nome.error ||
      this.perfilPublicoProfissionalErrors.fotoPerfil.error ||
      this.perfilPublicoProfissionalErrors.formasPagamento.error ||
      this.perfilPublicoProfissionalErrors.especialidades.error ||
      this.perfilPublicoProfissionalErrors.atendimento.error;
  }

  @action checkPerfilUnidadeIsComplete = () => {
    const { unidade } = this.unidadeStore;
    this.perfilPublicoUnidadeErrors = {
      nome: {
        name: 'Nome fantasia',
        error: unidade?.nomeFantasia?.length === 0,
      },
      fotoPerfil: {
        name: 'Foto de perfil',
        error: !this.perfilPublicoUnidade.fotoPerfil,
      },
      especialidades: {
        name: 'Especialidades',
        error: this.perfilPublicoUnidade.especialidades.length === 0,
      }
    }
    
    this.perfilUnidadeIsIncomplete = 
      this.perfilPublicoUnidadeErrors.nome.error ||
      this.perfilPublicoUnidadeErrors.fotoPerfil.error ||
      this.perfilPublicoUnidadeErrors.especialidades.error;
  }

  @action loadPerfilPublicoUsuarioLogado = async () => {
    const profissionalLogadoPerfilPublico = await findByUsuarioLogadoPerfilPublico();
    if (profissionalLogadoPerfilPublico) {
      this.perfilPublicoProfissional = {
        ...this.perfilPublicoProfissional,
      }
      this.checkPerfilProfissionalIsComplete();
    } else {
        this.initPerfilPublicoProfissionalDefault();
    }
  };

  @action loadPerfilPublicoUnidadeLogada = async () => {
    const unidadeLogadaPerfilPublico = await findByUnidadeLogadaPerfilPublico();
    if (unidadeLogadaPerfilPublico) {
      this.perfilPublicoUnidade = {
        ...this.perfilPublicoUnidade,
        ...unidadeLogadaPerfilPublico
      }
      this.checkPerfilUnidadeIsComplete();
    } else {
      this.initPerfilPublicoUnidadeDefault();
    } 
  };

  @action onFieldChange = (value, campo) => {
    if (campo === ElementsList.ESPECIALIDADES) {
      const { especialidades } = this.perfilPublicoProfissional;
      const lastValueInserted = value.pop();
      const alreadySelected = !especialidades?.some(({ especialidadeMedica }) => especialidadeMedica.especialidade === lastValueInserted?.especialidade);
      if (alreadySelected) {
        this.perfilPublicoProfissional = {
          ...this.perfilPublicoProfissional,
          [campo]: [...this.perfilPublicoProfissional[campo], {
            ativo: true,
            valorOnline: 0,
            valorPresencial: 0,
            especialidadeMedica: lastValueInserted
          }]
        };
      }
      this.checkPerfilProfissionalIsComplete();
      return;
    }

    this.perfilPublicoProfissional = {
      ...this.perfilPublicoProfissional,
      [campo]: value
    }

    if (campo === ElementsList.FORMAS_PAGAMENTO || campo === ElementsList.ESPECIALIDADES) {
      this.checkPerfilProfissionalIsComplete();
    }
  };

  @action changeFieldsPublicoProfissionalStore(values) {
    const arrayKey = Object.keys(values);

    arrayKey.forEach(item => {
      this.perfilPublicoProfissional[item]=values[item];
    })
  }
}
