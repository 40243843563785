import React from "react";
import { inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles/index";
import { ReactSelect, ReactSelectCheckbox } from "../Select/SelectSearch";
import { Button } from "../ui/Buttons";
import { findAllProcedimentos } from "../../services/ProcedimentoService";
import { TextFieldSearch } from "../TextField";
import { getUnidadeLogado } from "../../services/UsuarioService";
import { findByUnidadeComAgenda } from "../../services/RelatorioService";

const styleInputSearch = () => ({
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFF",
    "& > h3": {
      color: "#5F6368",
      fontSize: "16px",
    },
    "& > span": {
      color: "#626060",
      fontSize: "14px",
      textDecorationLine: "underline",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  searchButton: {
    borderRadius: 85,
    width: "50%",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    "& > span": {
      fontSize: "14px",
    },
  },
  buttons: {
    marginTop: "8px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  contentField: {
    display: "flex",
    flexDirection: "column",
  },
  notchedOutline: {
    border: '0',
  },
  inputContainer: {
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '30px',
    '&::placeholder': {
      opacity: 0.5,
    },
  },
  classIcons: {
    fontSize: 24,
  },
});

const FiltrosAgendamentosSituacao = (props) => {
  const {
    classes,
    functionsFilters,
    optionsFilters,
    valueFilters,
    filtroProfissionais,
    unidadeStore,
  } = props;

  const {
    convenio,
    profissionalSaude,
    tipo,
    sala,
    procedimento
  } = valueFilters;

  const { convenios, tiposOption, salas } = optionsFilters;

  const loadAllProcedimentos = async (search, loadedOptions, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'nome',
    }
    const searchDTO = {
      pageableDTO,
      nome: search,
    }

    try {
      const { content, last } = (
        await findAllProcedimentos(searchDTO)
      ).data.data.findAllProcedimentos
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
    }
  }

  const loadAllProfissionais = async (search, loadedOptions, { page }) => {
    const unidadeAtual = await getUnidadeLogado();
    const { mostrarApenasProfissionaisAtivo } = unidadeStore;    
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'nome',
    }
    const variables = {
      pageableDTO,
      search,
      unidadeId: unidadeAtual.id,
      ativo: mostrarApenasProfissionaisAtivo,
    }

    try {
      const { content, last } = (
        await findByUnidadeComAgenda(variables)
      )
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
    }
  }


  return (
    <div className={classes.content}>
      <div className={classes.title}>
        <h3>Filtrar</h3>
        <span onClick={() => functionsFilters.redefinirFiltros()}>
          Redefinir filtros
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.contentField}>
          <span className={classes.tituloFiltros}> Convênio: </span>
          <ReactSelect
            placeholder={"Selecione"}
            value={convenio}
            onChange={functionsFilters.handleConvenioChange}
            options={convenios || []}
            isClearable
            getOptionLabel={(option) => option.descricao}
            getOptionValue={(option) => option.id}
          />
        </div>
        {filtroProfissionais && (
          <div className={classes.contentField}>
            <span className={classes.tituloFiltros}> Profissional: </span>
            <TextFieldSearch
              placeholder='Selecione'
              classNotched={classes.notchedOutline}
              classInput={classes.inputContainer}
              classIcons={classes.classIcons}
              loadOptions={loadAllProfissionais}
              getOptionLabel={(option) => option.nome}
              getOptionValue={(option) => option.id}
              value={profissionalSaude}
              onChange={functionsFilters.handleChangeProfissionalSaude}
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition='fixed'
            />
          </div>
        )}
        <div className={classes.contentField}>
          <span className={classes.tituloFiltros}> Procedimento: </span>
          <TextFieldSearch
            placeholder='Selecione'
            classNotched={classes.notchedOutline}
            classInput={classes.inputContainer}
            classIcons={classes.classIcons}
            loadOptions={loadAllProcedimentos}
            getOptionLabel={option => option.nome}
            getOptionValue={option => option}
            value={procedimento}
            onChange={functionsFilters.handleChangeProcedimento}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition='fixed'
          />
        </div>
        <div className={classes.contentField}>
          <span className={classes.tituloFiltros}> Tipo: </span>
          <ReactSelectCheckbox
            placeholder={"Selecione"}
            value={tipo}
            onChange={functionsFilters.handleChangeTipo}
            options={tiposOption || []}
            getOptionLabel={(option) => option.descricao}
            getOptionValue={(option) => option.id}
          />
        </div>
        <div className={classes.contentField}>
          <span className={classes.tituloFiltros}> Sala: </span>
          <ReactSelect
            placeholder={"Selecione"}
            value={sala}
            onChange={functionsFilters.handleChangeSalas}
            options={salas || []}
            isClearable
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <Button onClick={() => functionsFilters.handleSearch()} fullWidth>
          Aplicar filtro
        </Button>
      </div>
    </div>
  );
};

const stores = [
  'extratoStore',
  'unidadeStore',
]
const FiltrosAgendamentosSituacaoWithStyles = withStyles(styleInputSearch)(
  FiltrosAgendamentosSituacao
);
export default inject(...stores)(FiltrosAgendamentosSituacaoWithStyles);
