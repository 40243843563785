import colors from "../../../../template/Colors";

const styles = (theme) => ({
  content: {
    display: "grid",
    gridTemplateRows: "80px auto 1fr",
    position: "relative",
    overflow: "hidden",

    "& thead th": {
      textAlign: "left",
      position: "sticky",
      top: 0,

      "&:first-child": {
        padding: "0 0 0 24px",
        width: "50%",
      },

      "&:last-child": {
        paddingRight: 0,
      },
    },
    "& tbody td": {
      padding: "0 0 0 24px",
      maxWidth: "120px",
      overflowWrap: "break-word",

      "& .status": {
        width: 16,
        height: 16,
        borderRadius: 100,
        display: "inline-block",
      },
    },
  },

  inputData: {
    color: colors.commons.fontColor,
    paddingTop: 0,
  },

  filtros: {
    display: "flex",
    flexDirection: "row",
    padding: "13px 16px 8px 16px",
    boxShadow:
      "10px 10px 25px rgb(112 124 151 / 5%), 15px 15px 35px rgb(112 124 151 / 5%)",
    background: "#fff",
    zIndex: 1,
    marginTop: 1,
    alignItems: "center",
  },
  tituloFiltros: {
    color: colors.commons.gray7,
    fontSize: "12px !important",
  },
  buttonsDownloadPrint: {
    display: "flex",
    position: "absolute",
    bottom: "20px",
    right: "14px",
    width: "88px",
    justifyContent: "space-between",
  },
  spacingConvenio: {
    maxWidth: 175,
    paddingRight: 16,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  rowInfos: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: colors.commons.gray9,
    fontFamily: "Poppins",
    height: "18px",
    marginBottom: "9px",
    fontSize: 12,
    "& span": {
      fontWeight: 700,
      fontSize: 14,
    },
  },
  contentInfos: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
});

export default styles;
