import React, { useState } from 'react'

import { withStyles } from '@material-ui/core'

import PencilIcon from '../../../../components/Icon/Pencil'
import { TextFieldSearch } from '../../../../components/TextField'

import string from '../../../../utils/string'

import { styles } from './styles'
import TrashIcon from '../../../../components/Icon/Trash'
import InputForm from '../../../../components/Input/InputForm'
import { applyCurrencyMask } from '../../../../utils/CurrencyMask'

const ContentProcedimentos = ({
  classes,
  options,
  handleChangeProcedimento,
  handleRemoveProcedimento,
  handleChangeQuantidade,
  loadProcedimentos,
  titulo,
  disabled,
}) => {
  const [editMode, setEditMode] = useState(null)

  const toggleEditMode = (index) => {
    setEditMode((prevState) => {
      if (prevState === index) return null
      return index
    })
  }

  const renderTableCell = (index, options, item) => {
    return (
      <>
        {editMode === index
          ? renderInput({
              classes,
              isSelect: options.isSelect,
              value: options.value,
              onChange: options.onChange,
              loadOptions: options.loadOptions,
            })
          : item}
      </>
    )
  }

  const handleChangeProcedimentoTable = (e, index) => {
    handleChangeProcedimento(e, index)
    toggleEditMode(null)
  }

  const getValorProcedimento = (item) => {
    const valor = item?.convenioProcedimentos.find((convenioProcedimento) => {
        return convenioProcedimento?.convenio?.id === titulo?.convenio?.id
      })
    return applyCurrencyMask(valor?.valor || 0)
  }

  const isDisabled = (item) => {
    const isReturn = item?.controleSessaoId || disabled
    return {
      pointerEvents: isReturn ? 'none' : 'auto',
      colors: isReturn ? 'transparent' : '#505050',
    }
  }

  return (
    <table className={classes.container}>
      <thead>
        <tr>
          <th>Consulta/procedimento</th>
          <th>Sessões</th>
          <th>Valor</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {options.map((item, index) => {
          return (
            <tr key={index}>
              <td>
                {renderTableCell(
                  index,
                  {
                    isSelect: true,
                    value: item,
                    onChange: (e) => handleChangeProcedimentoTable(e, index),
                    loadOptions: loadProcedimentos,
                  },
                  string.truncate(
                    item?.nome ||
                      item?.procedimento?.nome ||
                      item?.agendamentoTipo?.descricao,
                    20
                  )
                )}
              </td>
              <td>
                {renderTableCell(
                  index,
                  {
                    isSelect: false,
                    value: item?.totalQuantity,
                    onChange: (e) => {
                      handleChangeQuantidade(e, index)
                    },
                  },
                  item?.totalQuantity
                )}
              </td>
              <td>{getValorProcedimento(item)}</td>
              <td>
                <div
                  className={classes.wrapperActions}
                  style={{
                    pointerEvents: isDisabled(item)?.pointerEvents,
                  }}
                >
                  <PencilIcon
                    color={isDisabled(item)?.colors}
                    size='20'
                    onClick={() => toggleEditMode(index)}
                  />
                  <TrashIcon
                    color={isDisabled(item)?.colors}
                    size='20'
                    onClick={() => handleRemoveProcedimento(item, index)}
                  />
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const renderInput = ({ value, onChange, loadOptions, classes, isSelect }) => {
  if (!isSelect)
    return (
      <InputForm
        onChange={onChange}
        placeholder={'Digite a quantidade'}
        type={'number'}
        defaultValue={value}
        className={classes.inputContainer}
      />
    )
  return (
    <TextFieldSearch
      classNotched={classes.notchedOutline}
      classInput={classes.inputTextField}
      classIcons={classes.classIcons}
      placeholder={''}
      onChange={onChange}
      value={value}
      menuPlacement={'auto'}
      getOptionLabel={(option) => option.nome}
      getOptionValue={(option) => option.id}
      withPaginate
      loadOptions={loadOptions}
      debounceTimeout={300}
      additional={{
        page: 0,
      }}
    />
  )
}

export default withStyles(styles)(ContentProcedimentos)
