import React, { useState } from 'react';
import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles/index";
import moment from "moment";
import Colors from "../../template/Colors"
import Notification from '../../components/Notification';
import AtendimentoModal from "./AtendimentoModal";
import Scroll from "../../components/InfiniteScroll/Scroll";

const styles = () => ({
    lista: {
        overflow: 'hidden !important',
        display: 'grid',
        gridTemplateColumns: '160px 160px 160px 1fr 1fr',
        gridColumnGap: '10px',
        alignItems: 'center',
        height: 40,
        background: '#F8F9FA',
        color: '#5F6368',
        cursor: "pointer",
        fontFamily: "Poppins !important",
        fontSize: 14,
        border: '1px solid #fff',
    },

    dataHora: {
        fontFamily: "Poppins !important",
        display: 'flex',
        flexDirection: 'row',
        minHeight: 40,
        fontSize: 14,
        fontWeight: 600,
        color: 'white',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
    },

    agendado: {
        background: Colors.commons.blueLight,
    },

    agendadoDataHora: {
        background: Colors.status.agendado,
        color: 'white',
    },

    listaAgendamento: {
        position: 'relative',
    },

    scrollContainer: {
        height: 'calc(100vh - 194px)',
    },

    headerFiltro: {
        background: '#219A97',
        color: 'white',
        height: 64,
        display: 'grid',
        gridTemplateColumns: '160px 160px 160px 1fr 1fr',
        fontSize: '16px !important',
        fontWeight: 700,
        alignItems: 'center',
        border: '1px solid white',
        gridColumnGap: '10px',
    },


    headerDataHora: {
        fontSize: '16px !important',
        textAlign: 'center',
        margin: '20px 0',
    },

    headerItens: {
        fontSize: '16px !important',
    },

});


const ListaHorariosDisponiveis = (props) => {
    const { horariosDisponiveis, classes, atendimentoStore, loadMore } = props;

    const [notification, setNotification] = useState({
        open: false,
        message: 'Erro',
        type: 'error'
    })

    const closeAlertMessage = () => {
        setNotification(prevState => ({ ...prevState, open: false }))
    }

    const handleSelectEvent = (item) => {
        const { onSelectAgendamento } = props;

        onSelectAgendamento(item);
    };

    const loadMorePage = () => {
        if(atendimentoStore.horariosDisponiveis.loading ||
            atendimentoStore.horariosDisponiveis.last ) return;
        loadMore(atendimentoStore.horariosDisponiveis.pageNumber + 1);
    };

    return (
        <div className={classes.listaAgendamento}>
            <div>
                <div className={classes.headerFiltro}>
                    <div className={classes.headerDataHora}> Data consulta</div>
                    <div className={classes.headerItens}> Hora Início</div>
                    <div className={classes.headerItens}> Hora Fim</div>
                    <div className={classes.headerItens}> Profissional de Saúde </div>
                </div>
                <Scroll
                    loadMore={loadMorePage}
                    hasMore={!atendimentoStore.listaFilter.last}
                    pageStart={0}
                    className={classes.scrollContainer}
                >
                    {horariosDisponiveis.map((item, index) => ((
                        <div key={index} className={classNames(classes.lista, classes.agendado)} onClick={() => handleSelectEvent(item)}>
                            <div className={classNames(classes.dataHora, classes.agendadoDataHora)}>
                                {item.data && (
                                    <div className={classes.dataHora}>
                                        {`${moment(item.data).format("DD/MM/YYYY")}`}
                                    </div>
                                )}
                            </div>
                            <div>
                                {item.horaInicio && item.horaInicio}
                            </div>
                            <div>
                                 {item.horaFim && item.horaFim}
                            </div>
                            <div>
                                {item.profissionalSaudeNome && item.profissionalSaudeNome}
                            </div>
                        </div>
                    )))}
                </Scroll>
            </div>
            { atendimentoStore.open && <AtendimentoModal /> }
            <Notification
                close={closeAlertMessage}
                reset={closeAlertMessage}
                isOpen={notification.open}
                variant={notification.type}
                message={notification.message}
            />
        </div>
    )
}

export default withStyles(styles)(ListaHorariosDisponiveis)