import { colors } from "@material-ui/core";
import Colors from "../../../template/Colors";

const styles = {
  InputTextField:{
    background:"#F2F2F2",
    borderRadius:"10px",
    height:"25px",
  },
  buttonClose: {
    position: 'absolute',
    right: '15px',
    top: 18,
    minHeight: 26,
    height: 32,
    width: 32,
    backgroundColor:"transparent",
    color: "#FFF",
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    
  },
  boxInfosAdicionarDados: {
    background: "#FFF",
    width:"310px",
    height:"100px",
    borderRadius: 13,
    padding: 16,
    overflow: 'auto',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "8px",
    overflow:"hidden",
  },
  headerContentAdicionarDados: {
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    height: "530px",
    padding: "16px 24px 16px 16px",
    background: "#219A97",
    gap:"8px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    color: "#FFF",
    height:"40px",
  },
  titleCriarCampo:{
    color:"#868686",
    fontSize:"14px",
    fontWeight:400,
    margin:0,
  },
  addIcon: {
    display:"flex",
    alignItems:"center",
    width: "42px",
    height: "35px",
    justifyContent:"center",
    background:"#707C97",
    borderRadius:"25px",
    color:"#FFF",
    '&:hover':{
      background:"#8d9dbf",
      cursor:"pointer",
    },
  },
  contentTextAdd:{
    display:"flex",
    alignItems:"center",
    gap:"5px",
  },
  containerGrid:{
    background: "#FFF",
    width: "310px !important",
    borderRadius: "10px",
    padding: "16px",
    overflow:"scroll",
    alignContent: "stretch",
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    gridTemplateColumns: "1fr 1fr",
    gap:"8px",
    overflowX:"hidden",
  },
  camposContainer:{
    height: "37px",
    display: "flex",
    alignItems: "center",
    background: "#F2F2F2",
    width:" 135px",
    alignContent: "center",
    justifyContent: "center",
    border:"1px solid #0000001a",
    borderRadius: "8px",
    color:"#868686",
    fontSize:"14px",
    fontWeight:"700",
    padding:"8px",
    cursor:"pointer",

  }, 
}

export default styles; 
