import React from "react";
import debounce from "lodash.debounce";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles/index";
import moment from "moment";
import {
    CircularProgress,
} from "@material-ui/core";
import Api from "../../../config/api";
import styles from "../../../../app/assets/jss/pages/prontuarioAtendimentoStyle"
import Receituario from "../../Atendimento/Receituario/Receituario";
import ReceituarioModelo from "../../Atendimento/Receituario/ReceituarioModelo";
import PreencherDocumento from "../../Configuracoes/ModelosDocumento/Documento/PreencherDocumento";
import Imprimir from "../../../pages/Atendimento/Prontuario/Imprimir";
import SADTModal from '../../Atendimento/SADT/SADTModal';
import AnamneseModal from "../../Atendimento/Anamnese/AnamneseModal";

import * as SujeitoAtencaoService from "../../../services/SujeitoAtencaoService";
import { defaultObjView, TypeModalEnum } from "../../../stores/SADT.store";
import { TypeModalEnumReceituario } from "../../../stores/Receituario.store";
import ModalProcedimentosRealizados from "./ModalProcedimentosRealizados";
import LocalStorage from "../../../services/localStorage";
import InformacoesImportantesModal from "../../Atendimento/InformacoesImportantes/InformacoesImportantesModal";
import AvaliacaoAntropometricaModal from "../../Atendimento/AvaliacaoAntropometrica/AvaliacaoAntropometricaModal";
import AfericaoVitalModal from "../../Atendimento/AfericaoVital/AfericaoVitalModal";
import { getProfissionalSaudeLogado } from "../../../services/UsuarioService";
import PageTitle from "../../../components/PageTitle/PageTitle";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import { tabsContent } from "../../../stores/Prontuario.store";
import Prontuario from "../../Atendimento/Tabs/Prontuario/Prontuario";
import OptionsProntuario from "../../Atendimento/Prontuario/components/OptionsProntuario";
import Odontograma from "../../Atendimento/Tabs/Odontograma/Odontograma";
import AtendimentoModal from "../../Atendimento/AtendimentoModal";
import MinimizedTaskbar from "../../Atendimento/MinimizedTaskbar";
import ModalGuiaInternacao from "../../Configuracoes/ModelosDocumento/GuiaInternacao/ModalGuiaInternacao";
import classNames from "classnames";
import ConfirmAlertModal from "../../../components/ConfirmModal/ConfirmAlertModal";
import { findAllDocumentoModelo } from "../../../services/DocumentoService";

const MODAL = {
    RECEITUARIO: 'receituario',
    DOCUMENTO: 'documento',
    ANAMNESE: 'anamnese',
}

let page = {
    search: "",
    pageNumber: 0,
    pageSize: 9999,
    sortDir: "ASC",
    sortField: "nome",
    ativo: true
};

@inject("prontuarioStore", "sujeitoAtencaoStore", "usuarioStore", "SADTStore", "receituarioStore", "configuracaoImpressaoStore", "odontogramaStore", "atendimentoStore", "guiaInternacaoStore")
@observer
class ProntuarioSubMenu extends React.Component {
    constructor(props) {
        super(props);
        this.blockPage = this.props.history.block(this.onBlock);
        this.state = {
            openPreencherDocumento: false,
            documentos: [],
            filter: null,
            sujeito: null,
            isLoading: false,
            openProcedimentoRealizadoModal: false,
            procedimentoRealizadoId: '',
            hiddenProcedimentosButton: true,
            showModalAnamnese: false,
            anamneseSelecionado: null,
            openInformacoesImportantes: false,
            openAvaliacaoAntropometrica: false,
            avaliacaoAntropometricaSelecionado: null,
            isNovoLancamento: false,
            openTriagem: false,
            triagemSelecionado: null,
            profissionalSaudeAtual: null,
            unidadeAtual: null,
            isDuplicar: false,
            tabContentSelected: tabsContent.PRONTUARIO,
            modalsStates: {
                receituario: 'closed',
                documento: 'closed',
                anamnese: 'closed',
            },
            modalEditIndex: null,
            showModalDadosMinimizados: false,
            sujeitoAtencaoToSetOnAgendamento: null,
            openModalGuiaInternacao: false,
            openModalWriteMessage: false
        };
        this.documentoFileInput = React.createRef();
        this.agendamentoPulling = null;
        this.props.prontuarioStore.checkUserRoleEntradaProntuario()

        this.debounceUpdateAlturaPeso = debounce(
            this.debounceUpdateAlturaPeso,
            1000
        );

        this.pageNumberEntradas = 0;
        this.props.atendimentoStore.onExit();
    }

    async componentDidMount() {
        const { prontuarioStore, usuarioStore, sujeitoAtencaoStore, odontogramaStore } = this.props;
        odontogramaStore.isTelaAmpliada = false;
        prontuarioStore.checkUserRoleEntradaProntuario();
        const unidadeAtual = await usuarioStore.getUnidadeAtual();
        usuarioStore
            .getProfissionalSaudeAtual()
            .then((response) => {

                this.setState({
                    profissionalSaudeAtual: response,
                    unidadeAtual: unidadeAtual,
                });

                let filter = {
                    sujeitoAtencaoId: +this.props.sujeitoAtencaoStore.objView.id,
                    unidadeId: unidadeAtual.id,
                };

                if (unidadeAtual) {
                    const unidadeId = unidadeAtual.id;
                    const redeId = unidadeAtual.rede && unidadeAtual.rede.id;
                    Api.post("", {
                        query: `
                            query ($page: SearchDTOInput) {
                                findAllDocumentoModelo(searchDTO: $page) {
                                    content {
                                        camposEntrada{
                                            campo
                                            campoAmigavel
                                            campoCompleto
                                            valor
                                        }
                                        camposSistema{
                                            campo
                                            descricao
                                            entidade
                                            entidadeComCampo
                                            entidadeEnum
                                        }
                                        modelo
                                        name: nome
                                        value: id
                                        label: nome
                                    }
                                }
                            }`,
                        variables: { page: { ...page, redeId, unidadeId } }
                    })
                        .then(({ data: response }) => {
                            this.setState({
                                documentos: response.data.findAllDocumentoModelo.content,
                                isLoading: false
                            });
                        })
                        .catch(error => {
                            prontuarioStore.openNotification('Erro ao carregar documentos modelo', 'error');
                        });
                }

                sujeitoAtencaoStore.objView.id ?
                    this.carregaEntradaProntuario(filter, sujeitoAtencaoStore.objView)
                    :
                    SujeitoAtencaoService.findByIdSujeitoAtencao(
                        this.props.match.params.id
                    ).then((res) => {
                        const sujeito = res.data.data.findByIdSujeitoAtencao;
                        prontuarioStore.sujeitoAtencaoSelected = sujeito;

                        this.setState({ filter, sujeito });
                        this.carregaEntradaProntuario(filter, sujeito);
                        this.setState({ isLoading: false });
                    });
            })
            .catch((err) => {
                console.log("Erro ao processar operação. ", err);
            });
    }

    carregaEntradaProntuario = async (filter, sujeitoAtencao) => {
        const { prontuarioStore } = this.props;
        this.setState({ filter, sujeito: sujeitoAtencao });
        prontuarioStore.sujeitoAtencaoSelected = sujeitoAtencao;

        prontuarioStore.findAllEntradaProntuario(
            { filter },
            sujeitoAtencao
        );
        this.setState({ isLoading: false });
    }

    UNSAFE_componentWillMount() {
        this.props.resetNotification();
    }

    componentWillUnmount() {
        const { prontuarioStore } = this.props;
        prontuarioStore.resetPacienteAndAgendamentoSelected();

        if (prontuarioStore.previewDocumento.open) {
            prontuarioStore.closePreviewDocumento();
        }
    }

    debounceUpdateAlturaPeso() {
        this.props.prontuarioStore.updateSujeitoAtencaoAlturaPesoNoAtendimento();
    }

    findAllAgendamento = async () => {
        try {
            let searchMock = {
                search: `${this.props.sujeitoAtencaoStore.objView.nome}`,
            };

            const { prontuarioStore } = this.props;
            await prontuarioStore.findAllAgendamento(searchMock);
        } catch (error) {
            console.error(error);
        }
    };

    reloadEntradas = ({ filter = {}, withLoading = false } = {}, sujeito) => {
        const { prontuarioStore } = this.props;
        this.pageNumberEntradas = 0;
        prontuarioStore.findAllEntradaProntuario(
            { filter, withLoading },
            sujeito
        );
    };
    onBlock = (page) => {
        const { prontuarioStore } = this.props;
        this.navigation = () => this.props.history.push(page.pathname);
        if (!prontuarioStore.mensagemProntuario) {
            return true
        }
        if (!this.state.openModalWriteMessage) {
            this.setState({ openModalWriteMessage: true });
        }
        else {
            this.blockPage();
            return true;
        }
        return false;
    };
    onCloseModal = () => {
        this.setState({ openModalWriteMessage: false });
    };

    allowNextPage = () => {
        this.navigation && this.navigation();
    };


    handleChangeMensagemProntuario = (e) => {
        const { prontuarioStore } = this.props;
        const { value } = e.target;

        getProfissionalSaudeLogado().then(response => {
            LocalStorage.put(
                `${prontuarioStore.sujeitoAtencaoSelected.id}-${response?.id}`,
                value
            );
        });

        prontuarioStore.mensagemProntuario = value;
    };

    createEntradaProntuarioTipoTexto = () => {
        const { prontuarioStore } = this.props;
        prontuarioStore.createEntradaProntuarioTipoTexto()
            .then(() => {
                this.handleChangeMensagemProntuario({ target: { value: '' } });
            })
            .catch(error => {
                console.error(error);
            });
        this.reloadEntradas(
            { filter: this.state.filter, withLoading: false },
            this.state.sujeito
        );
    };

    loadMoreMensagens = () => {
        const { prontuarioStore } = this.props;

        if (prontuarioStore.loadingMoreMensagens) {
            return;
        }

        this.pageNumberEntradas += 1;
        prontuarioStore.findAllEntradaProntuario(
            {
                filter: this.state.filter,
                withLoading: false,
                pageNumber: this.pageNumberEntradas,
            },
            this.state.sujeito
        );
    };

    handleOpenReceituario = () => {
        const { prontuarioStore, receituarioStore } = this.props;

        prontuarioStore.receituario = {
            open: true,
        };
        receituarioStore.typeModal = TypeModalEnumReceituario.RECEITUARIO_MODAL;
        receituarioStore.isProntuario = true;
    };

    handleCloseReceituario = () => {
        const { prontuarioStore, receituarioStore } = this.props;
        receituarioStore.tipoListagemSelecionada = "sugestoes";
        receituarioStore.amountTemp = "";
        receituarioStore.ascriptionTemp = "";
        receituarioStore.drugTemp = {};
        receituarioStore.recipesTemp = [];
        receituarioStore.usoTemp = null;
        prontuarioStore.receituario = {
            open: false
        };

        this.reloadEntradas(
            { filter: this.state.filter, withLoading: false },
            this.state.sujeito
        );
    };

    handleCloseReceituarioModelo = () => {
        const { receituarioStore, prontuarioStore } = this.props;
        if (receituarioStore.isProntuario) {
            receituarioStore.openModalModelo = false;
            prontuarioStore.receituario = {
                open: true
            };
        } else {
            receituarioStore.openModalModelo = false;
        }
    };

    handleDocument = () => {
        this.setState({
            openPreencherDocumento: true,
        });
    };

    handleClickSADT = () => {
        const { SADTStore } = this.props;
        SADTStore.guiaServico = null;
        SADTStore.openModal = true;
        this.props.SADTStore.typeModal = TypeModalEnum.SADT_MODAL;
    };

    handleCloseProcedimentoRealizado = () => {
        this.setState({ openProcedimentoRealizadoModal: false })
    };

    handleCloseSADT = () => {
        const { SADTStore } = this.props;
        this.props.SADTStore.tipoListagemSelecionada = 'sugestoes';
        this.props.SADTStore.idModeloSelecionado = '';
        SADTStore.readOnly = false;
        SADTStore.objView = defaultObjView;

        if (SADTStore.typeModal === TypeModalEnum.NOVO_MODELO_MODAL && SADTStore.isProntuario) {
            SADTStore.typeModal = TypeModalEnum.SADT_MODAL;

            SADTStore.objView.observacao = SADTStore.observacaoTemporaria || "";
            SADTStore.objView.guiaServicoTussViews = SADTStore.guiaServicoTussViewsTemporaria || [];
            SADTStore.objView.indicacaoClinica = SADTStore.indicacaoClinicaTemporaria || null;

            SADTStore.observacaoTemporaria = "";
            SADTStore.guiaServicoTussViewsTemporaria = [];
            SADTStore.indicacaoClinicaTemporaria = null;
        } else {
            SADTStore.openModal = false;
        }

        this.reloadEntradas(
            { filter: this.state.filter, withLoading: false },
            this.state.sujeito
        );
    };

    handleClosePreencherDocumento = () => {
        this.setState({
            openPreencherDocumento: false,
            isDuplicar: false
        });
        this.reloadEntradas(
            { filter: this.state.filter, withLoading: false },
            this.state.sujeito
        );
    };

    handleCloseAnamneseModal = () => {
        this.setState({
            showModalAnamnese: false,
            anamneseSelecionado: null
        }, () => this.reloadEntradas({ filter: this.state.filter, withLoading: false },
            this.state.sujeito));
    };

    handleClickAnamnese = () => {
        this.setState({
            showModalAnamnese: true
        })
    };

    handleOpenImprimir = async () => {
        await this.props.configuracaoImpressaoStore.getConfig('OUTROS');
        this.setState({ openImprimir: true });
    };

    handleCloseImprimir = () => {
        this.setState({ openImprimir: false });
    };

    handleClickInformacoesImportantes = async () => {
        const { prontuarioStore } = this.props;
        await prontuarioStore.refreshSelectedSujeitoAtencao;
        prontuarioStore.openModalDadosImportantes = true;
    }

    onCloseInformacaoModal = () => {
        const { prontuarioStore } = this.props;
        prontuarioStore.openModalDadosImportantes = false;
    }

    handleClickAvaliacaoAntropometrica = async () => {
        this.setState({
            openAvaliacaoAntropometrica: true,
            isNovoLancamento: true,
        });
    }

    onCloseAvaliacaoAntropometrica = () => {
        this.setState({
            openAvaliacaoAntropometrica: false,
            avaliacaoAntropometricaSelecionado: null
        });
        this.reloadEntradas(
            { filter: this.state.filter, withLoading: false },
            this.state.sujeito
        );
    }

    handleClickTriagem = async () => {
        this.setState({ openTriagem: true });
    }

    onCloseTriagem = () => {
        this.setState({
            openTriagem: false,
            triagemSelecionado: null
        });
        this.reloadEntradas(
            { filter: this.state.filter, withLoading: false },
            this.state.sujeito
        );
    }

    calculaIdade = (dataNascimento) => {
        if (!dataNascimento) {
            return null;
        }
        let idadeSujeito = moment().diff(dataNascimento, "years");

        if (idadeSujeito >= 18) {
            return idadeSujeito + " anos"
        }

        let dataNascimentoMoment = moment(dataNascimento),
            dataHoje = moment(),
            intervals = [{ label: 'anos', value: 'years' }, { label: 'meses', value: 'months' }, { label: 'dias', value: 'days' }],
            out = [];

        for (let i = 0; i < intervals.length; i++) {
            let diff = dataHoje.diff(dataNascimentoMoment, intervals[i].value);
            dataNascimentoMoment.add(diff, intervals[i].value);
            out.push(diff + ' ' + intervals[i].label);
        }
        return out.join(', ');
    };

    changeTabContentSelected = (tabSelected) => {
        if(tabSelected === 0) {
            this.reloadEntradas();
        }
        this.setState({
            tabContentSelected: tabSelected
        });
    };

    renderTabContent = () => {
        const { history } = this.props;
        const { tabContentSelected } = this.state;

        return tabContentSelected === 0 ?
            <Prontuario
                loadMoreMensagens={this.loadMoreMensagens}
                reloadEntradas={this.reloadEntradas}
                handleClickSADT={this.handleClickSADT}
                prontuarioStore={this.props.prontuarioStore}
                atualizaState={this.atualizaState}
            /> : <Odontograma history={history} />
    }

    atualizaState = (values) => {
        const arrayKey = Object.keys(values);

        arrayKey.forEach(item => {
            this.setState({
                [item]: values[item]
            })
        })
    }

    handleCloseAtendimentoModal = () => {
        const { atendimentoStore } = this.props;
        atendimentoStore.open = false;
    };

    handleChangeModalState = (name, payload) => {
        this.setState((prevState) => ({
            modalsStates: {
                ...prevState.modalsStates,
                [name]: payload
            }
        }));
    }

    isModalMinimized = (name) => {
        const { modalsStates } = this.state;
        return modalsStates[name] === 'minimized';
    }

    handleCloseModalInfosDadosMinimizados = () => {
        this.setState({
            showModalDadosMinimizados: false,
            sujeitoAtencaoToSetOnAgendamento: null,
        });
    }

    handleChangePacienteWithDadosMinimizados = async (idAgendamento) => {
        const { modalsStates } = this.state;
        const isMinimized = Object.values(modalsStates).some(item => item === 'minimized');
        if (isMinimized) {
            this.setState({
                showModalDadosMinimizados: true,
                sujeitoAtencaoToSetOnAgendamento: idAgendamento
            });
            return;
        } else {
            this.selectAgendamento(idAgendamento);
        }
    }

    handleOpenModalGuiaInternacao = () => {
        const { guiaInternacaoStore } = this.props;
        guiaInternacaoStore.openGuiaInternacaoModal = true;
    };

    handleReloadEntradasAfterSave = () => {
        this.reloadEntradas(
            { filter: this.state.filter, withLoading: false },
            this.state.sujeito
        );
    }

    getDocumentosModelo = async({tipo})=>{
        try{
           const response = await findAllDocumentoModelo({
               ativo: true,
               search: "",
               pageableDTO: {
                    pageNumber: 0,
                    pageSize: 9999,
                   sortDir: 'ASC', 
                   sortField: 'nome',
               } ,
               tipo,
           })
           this.setState({
               documentos: response.content,
               isLoading: false
           })}catch(error){
           this.props.prontuarioStore.openNotification('Erro ao carregar documentos modelo', 'error');
        }
       }

    render() {
        const { classes, prontuarioStore, odontogramaStore, atendimentoStore, guiaInternacaoStore } = this.props;
        const {
            openAvaliacaoAntropometrica,
            openTriagem,
            unidadeAtual,
            profissionalSaudeAtual,
            tabContentSelected,
            openModalWriteMessage
        } = this.state;

        const {
            sujeitoAtencaoSelected,
            receituario,
            usuarioLogado,
        } = prontuarioStore;

        const { isTelaAmpliada } = odontogramaStore;
        const { open } = atendimentoStore;

        const { openGuiaInternacaoModal } = guiaInternacaoStore;

        const receituarioIsOpen =
            !!(sujeitoAtencaoSelected && usuarioLogado && receituario?.open);

        const SADTIsOpen =
            !!(sujeitoAtencaoSelected && usuarioLogado && this.props.SADTStore.openModal);

        const imprimirProntuarioIsOpen =
            !!(sujeitoAtencaoSelected && usuarioLogado && this.state.openImprimir);

        const procedimentoRealizadoModalIsOpen = !!(sujeitoAtencaoSelected && usuarioLogado && this.state.openProcedimentoRealizadoModal)

        const anamneseIsOpen = !!(sujeitoAtencaoSelected && usuarioLogado && this.state.showModalAnamnese);

        const possuiOdontograma = odontogramaStore.verificaEspecialidadeOdontologia(profissionalSaudeAtual)

        if (this.state.isLoading) {
            return (
                <div className={classes.tableContainer}>
                    <div className={classes.contentLoadingContainer}>
                        <CircularProgress color="primary" />
                    </div>
                </div>
            );
        }

        return (
            <>
                <PageTitle title="Paciente - Prontuário" />
                <div className={classNames(
                    classes.contentTab,
                    possuiOdontograma && classes.contentTabOdonto,
                    prontuarioStore.openFiltroData && classes.contentTabFiltrado,
                    tabContentSelected === 1 && classes.contentTabSelectedOdontograma,
                )}>
                    {
                        (odontogramaStore.verificaEspecialidadeOdontologia(profissionalSaudeAtual) && !isTelaAmpliada) && <MultiToggleButtons
                            options={["Prontuário", "Odontograma"]}
                            tabSelected={tabContentSelected}
                            changeTabSelected={this.changeTabContentSelected}
                            classes={{ buttonsContainer: classes.buttonsTab }}
                        />
                    }
                    {this.renderTabContent()}
                </div>
                <OptionsProntuario
                    handleOpenReceituario={this.handleOpenReceituario}
                    handleDocument={this.handleDocument}
                    handleClickSADT={this.handleClickSADT}
                    handleClickAnamnese={this.handleClickAnamnese}
                    handleClickInformacoesImportantes={this.handleClickInformacoesImportantes}
                    handleClickAvaliacaoAntropometrica={this.handleClickAvaliacaoAntropometrica}
                    handleClickTriagem={this.handleClickTriagem}
                    handleOpenImprimir={this.handleOpenImprimir}
                    handleClickGuiaInternacao={this.handleOpenModalGuiaInternacao}
                    tabSelected={tabContentSelected}
                    hiddenProcedimentosButton={true}
                />
                <MinimizedTaskbar
                    onOpenReceita={(index) => {
                        this.handleOpenReceituario();
                        this.setState({ modalEditIndex: index })
                    }}
                    isReceitaMinimized={this.isModalMinimized(MODAL.RECEITUARIO)}
                    isDocumentoMinimized={this.isModalMinimized(MODAL.DOCUMENTO)}
                    isAnamneseMinimized={this.isModalMinimized(MODAL.ANAMNESE)}
                    onOpenAnamnese={(index) => {
                        this.handleClickAnamnese();
                        this.setState({ modalEditIndex: index })
                    }}
                    onOpenDocumento={(index) => {
                        this.handleDocument();
                        this.setState({ modalEditIndex: index });
                    }}
                    indexSelected={this.state.modalEditIndex}
                />

                {(receituarioIsOpen || this.isModalMinimized(MODAL.RECEITUARIO)) && (
                    <Receituario
                        entrada={receituario?.entrada}
                        open={receituario.open}
                        sujeitoAtencaoId={sujeitoAtencaoSelected.id}
                        profissionalSaude={profissionalSaudeAtual}
                        unidadeAtual={unidadeAtual}
                        screen="Paciente - Prontuário"
                        handleMinimize={() => {
                            this.handleChangeModalState(MODAL.RECEITUARIO, 'minimized');
                            this.setState({ modalEditIndex: null });
                            this.handleCloseReceituario();
                        }}
                        handleClose={() => {
                            this.handleChangeModalState(MODAL.RECEITUARIO, 'closed');
                            this.handleCloseReceituario();
                        }}
                        history={this.props.history}
                    />
                )}
                {this.props.receituarioStore.openModalModelo && (
                    <ReceituarioModelo
                        open={this.props.receituarioStore.openModalModelo}
                        handleClose={this.handleCloseReceituarioModelo}
                        profissionalSaude={profissionalSaudeAtual}
                        unidadeAtual={unidadeAtual}
                        screen="Paciente - Prontuário"
                    />
                )}

                {SADTIsOpen && (
                    <SADTModal
                        open={this.props.SADTStore.openModal}
                        handleClose={this.handleCloseSADT}
                        sujeitoAtencao={sujeitoAtencaoSelected}
                        screen="Paciente - Prontuário"
                        history={this.props.history}
                    />
                )}

                {procedimentoRealizadoModalIsOpen && (
                    <ModalProcedimentosRealizados
                        handleClose={this.handleCloseProcedimentoRealizado}
                        idSujeitoAtencao={sujeitoAtencaoSelected.id}
                        idProcedimentoRealizado={this.state.procedimentoRealizadoId}
                        showModal={procedimentoRealizadoModalIsOpen}
                        pageTitle={"Paciente - prontuario - procedimento"}
                    />
                )
                }

                {(anamneseIsOpen || this.isModalMinimized(MODAL.ANAMNESE)) && (
                    <AnamneseModal
                        openModal={anamneseIsOpen}
                        handleMinimize={() => {
                            this.handleChangeModalState(MODAL.ANAMNESE, 'minimized');
                            this.setState({ modalEditIndex: null });
                            this.handleCloseAnamneseModal();
                        }}
                        handleClose={() => {
                            this.handleChangeModalState(MODAL.ANAMNESE, 'closed');
                            this.handleCloseAnamneseModal();
                        }}
                        sujeitoAtencao={sujeitoAtencaoSelected}
                        anamneseSelecionado={this.state.anamneseSelecionado}
                        screen="Paciente - Prontuário"
                    />
                )}

                {((sujeitoAtencaoSelected && usuarioLogado && this.state.openPreencherDocumento) || this.isModalMinimized(MODAL.DOCUMENTO)) && (
                    <div>
                        <PreencherDocumento
                            open={this.state.openPreencherDocumento}
                            handleMinimize={() => {
                                this.handleChangeModalState(MODAL.DOCUMENTO, 'minimized');
                                this.setState({ modalEditIndex: null });
                                this.handleClosePreencherDocumento();
                            }}
                            handleClose={() => {
                                this.handleChangeModalState(MODAL.DOCUMENTO, 'closed');
                                this.handleClosePreencherDocumento();
                            }}
                            isDuplicar={this.state.isDuplicar}
                            previewDocumento={prontuarioStore.previewDocumento}
                            sujeitoAtencaoId={sujeitoAtencaoSelected.id}
                            sujeitoAtencao={sujeitoAtencaoSelected}
                            profissionalSaude={profissionalSaudeAtual}
                            getDocumentosModelo={this.getDocumentosModelo}
                            unidadeAtual={unidadeAtual}
                            options={this.state.documentos}
                            screen="Paciente - Prontuário"
                            history={this.props.history}
                        />
                    </div>
                )}

                {imprimirProntuarioIsOpen && this.state.openImprimir && (
                    <Imprimir
                        open={this.state.openImprimir}
                        handleClose={this.handleCloseImprimir}
                        sujeitoAtencao={sujeitoAtencaoSelected}
                        profissionalSaude={profissionalSaudeAtual}
                        unidadeAtual={unidadeAtual}
                    />
                )}

                {prontuarioStore.openModalDadosImportantes && (
                    <div className={classes.floatingModal}>
                        <InformacoesImportantesModal
                            openedByButton
                            onClose={this.onCloseInformacaoModal}
                            screen="Paciente - Prontuário"
                        />
                    </div>
                )}
                {openAvaliacaoAntropometrica && (
                    <AvaliacaoAntropometricaModal
                        openModal={openAvaliacaoAntropometrica}
                        handleClose={this.onCloseAvaliacaoAntropometrica}
                        sujeitoAtencao={sujeitoAtencaoSelected}
                        idadeSujeitoAtencao={this.calculaIdade(sujeitoAtencaoSelected.dataNascimento)}
                        avaliacaoAntropometricaSelecionado={this.state.avaliacaoAntropometricaSelecionado}
                        isNovoLancamento={this.state.isNovoLancamento}
                        screen="Paciente - Prontuário"
                    />
                )}

                {openTriagem && (
                    <AfericaoVitalModal
                        openModal={openTriagem}
                        handleClose={this.onCloseTriagem}
                        sujeitoAtencao={sujeitoAtencaoSelected}
                        afericaoVitalSelecionado={this.state.triagemSelecionado}
                        isProntuario={true}
                        screen="Paciente - Prontuário"
                    />
                )}

                {openGuiaInternacaoModal && (
                    <ModalGuiaInternacao
                        defaultEntity="guiaInternacao"
                        isOpen={openGuiaInternacaoModal}
                        onClose={() => guiaInternacaoStore.openGuiaInternacaoModal = false}
                        sujeitoAtencaoId={sujeitoAtencaoSelected.id}
                        reloadEntradaPronturio={this.handleReloadEntradasAfterSave}
                    />
                )}
                <ConfirmAlertModal
                    open={openModalWriteMessage}
                    title={"Deseja sair desse prontuário?"}
                    text={"Existe uma evolução em aberto. O ideal é salvá-la antes de sair."}
                    titleSecundaryButton={"Sair"}
                    onClose={this.onCloseModal}
                    onConfirm={this.allowNextPage}
                    onCancel={this.onCloseModal}
                />
                {open && (
                    <AtendimentoModal
                        open={open}
                        onClose={this.handleCloseAtendimentoModal}
                    />
                )}
            </>
        );
    }
}

export default withStyles(styles)(ProntuarioSubMenu);
