import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Grid, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import { observer } from "mobx-react-lite";
import { Print as PrintIcon } from "@material-ui/icons";
import styles from "../RelatorioStyle";
import { TextFieldSearch } from "../../../components/TextField";
import SelectCustomIcon from "../../Configuracoes/ModelosDocumento/Anamnese/SelectCustomIcon";
import { StatusAtivoIcon, StatusInativoIcon } from "../../../assets/img/svg";
import { findAllMunicipio, findAllSujeitoAtencaoQuemIndicou } from "../../../services/RelatorioService";
import Table from "../../../components/Table/Table";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import HeaderRelatorio from "../HeaderRelatorio";
import Notification from "../../../components/Notification";
import ButtonYellow from "../../../components/Button/ButtonYellow";
import RelatorioIndicacoesPdfDocument from "../../../template/pdf/relatorio/Indicacoes";
import { inject } from "mobx-react";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import cidadeFormatada from "../../../utils/cidadeFormatada";
import TelefoneFormatado from '../../../components/Input/TelefoneFormatado';
import PageTitle from "../../../components/PageTitle/PageTitle";

const columns = [
  {
    Header: "",
    getValue: (indicado) => {
      return <div className={classnames("status", String(indicado.ativo))}/>;
    },
  },
  {
    Header: 'Paciente',
    field: "nome",
    getValue: (indicado) => {
        return indicado.nome
    },
},{
    Header: 'Indicado por',
    field: "quemIndicou",
    getValue: (indicado) => {
        return indicado.quemIndicou
    },
}, {
    Header: 'Telefone',
    getValue: (indicado) => {
      return <TelefoneFormatado
        telefone={indicado?.contato?.telefonePrincipal || indicado?.contato?.telefoneSecundario}
        telefoneDDI={indicado?.contato?.telefonePrincipalDDI || indicado?.contato?.telefoneSecundarioDDI}
        checkTelefoneInternacional
      />
    },
}, {
  Header: "Cidade",
  field: "endereco.municipio.nome",
  getValue: (indicado) => {
    return cidadeFormatada({
      municipio: indicado.endereco?.municipio?.nome, 
      uf: indicado.endereco?.municipio?.uf, 
      espanhaMunicipio: indicado.espanhaMunicipio?.descricao
    })
  },
},
];

const statusList = [
  { label: "Ativo", value: true, icon: StatusAtivoIcon },
  { label: "Inativo", value: false, icon: StatusInativoIcon },
];

const RelatorioIndicacoes = observer((props) => {
    const { classes, unidadeStore } = props;

    const [indicacoesLoading, setIndicacoesLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [indicacoes, setIndicacoes] = useState([]);
    const [lastScroll, setLastScroll] = useState(false);
    const [searchNome, setSearchNome] = useState('');
    const [cidade, setCidade] = useState(null);
    const [status, setStatus] = useState({
      label: "Ativo",
      value: true,
      icon: StatusAtivoIcon,
    });
    const [ordenarTabela, setOrdenarTabela] = useState({
      sortField: "quemIndicou",
      sortDir: "ASC",
    });
    const [notification, setNotification] = useState({
      isOpen: false,
      message: "",
    });
    const [atualizaListaSearch, setAtualizaListaSearch] = useState(false)
    const [printing, setPrinting] = useState(false);
    const [totalElements, setTotalElements] = useState(0);

    useEffect(() => {
      loadIndicacoes();
    }, []);
    
    useEffect(() => {
      loadIndicacoes();
    }, [pageNumber, ordenarTabela, status.value, cidade, atualizaListaSearch]);

    const loadIndicacoes = () => {
      if (indicacoesLoading){
        return
      }
      setIndicacoesLoading(true);
      findAllSujeitoAtencaoQuemIndicou({
          ativo: status.value,
          search: searchNome,
          municipioId: cidade?.id,
          pageable: {
            pageSize: 30,
            pageNumber,
            sortDir: ordenarTabela.sortDir,
            sortField: ordenarTabela.sortField
          }
        }).then((response) => {
          const { content, last, totalElements } = response;
          const listaIndicacoes = [...indicacoes, ...content]
          setLastScroll(last)
          setIndicacoes(listaIndicacoes);
          setTotalElements(totalElements);
        }).catch(error => {
          const notification = {
            isOpen: true,
            message: "Erro ao carregar a lista de indicações"
          };
          showAlertMessage(notification);
        }).finally(() => {
          setIndicacoesLoading(false);
        })
    }

    const onClickPesquisar = () => {
      clearPesquisar();
      setAtualizaListaSearch(!atualizaListaSearch);
    }

    const onKeypressSearch = (e) => {
        if (e.key === 'Enter' && searchNome.length >= 3) {
          clearPesquisar();
          setAtualizaListaSearch(!atualizaListaSearch);
        }
    }

    const handleClickClearPesquisar = e => {
      setSearchNome("");
      setAtualizaListaSearch(!atualizaListaSearch);
      clearPesquisar();
    }

    const clearPesquisar = () => {
        setPageNumber(0);
        setIndicacoes([]);
        setLastScroll(false);
    }

    const handleSearchChange = e => {
        setSearchNome(e.target.value);
    };

    const loadMore = () => {
        if (indicacoesLoading) {
          return;
        }
    
        setPageNumber( indicacoes.length > 0 ? pageNumber + 1 : 0 )
    };

    const handleMunicipioLoadOptions = async (
      search,
      loadedOptions,
      { page }
    ) => {
      return handleLoadMoreOptions({
        search,
        loadedOptions,
        data: { page },
      });
    };

    const handleLoadMoreOptions = async ({ search, data }) => {
      let searchDTO = {};
  
      if (data.searchDTO) {
        searchDTO = {
          ...data.searchDTO,
        };
      }
      const response = await findAllMunicipio({
        pageNumber: data.page,
        search,
        ...searchDTO,
      });
  
      return {
        options: response.content,
        hasMore: !response.last,
        additional: {
          page: data.page + 1,
        },
      };
    };
  
    const handleMunicipioChange = (e) => {
      clearPesquisar();
      setCidade(e);
    };

    const onStatusChange = (e) => {
      clearPesquisar();
      setStatus(e);
    };
    const handleClickOrdenar = async (value) => {
      clearPesquisar();

      const sortDir =
        ordenarTabela.sortField !== value
          ? "ASC"
          : ordenarTabela.sortDir === "ASC"
          ? "DESC"
          : "ASC";
  
      setOrdenarTabela({
        sortDir: sortDir,
        sortField: value,
      });
    };

  const showAlertMessage = (notification) => {
    setNotification(notification)

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    setNotification(notification);
  };
  
  const printRelatorio = () => {
    setPrinting(true);
  }

  return (
    <div className={classes.content}>
      <HeaderRelatorio
        search={onClickPesquisar}
        clearSearch={handleClickClearPesquisar}
        value={searchNome}
        title='Indicações'
        totalTitle='cadastros'
        onChange={handleSearchChange}
        onKeyPress={onKeypressSearch}
        totalAgendamentos={totalElements}
        hiddenButtons
        hiddenFilter
      />

<div className={classes.filtros}>
  <PageTitle title="Relatórios - Indicações"/>
        <Grid item xs={3} className={classes.spacing}>
          <span className={classes.tituloFiltros}> Cidade: </span>
          <TextFieldSearch
            placeholder="Selecione"
            classNotched={classes.notchedOutline}
            classInput={classes.inputTextField}
            classIcons={classes.classIcons}
            loadOptions={handleMunicipioLoadOptions}
            withPaginate
            value={cidade}
            onChange={handleMunicipioChange}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Grid>
        <Grid item xs={3} className={classes.spacing}>
          <span className={classes.tituloFiltros}> Situação: </span>
          <SelectCustomIcon
            isSelect
            onChange={onStatusChange}
            value={status}
            options={statusList}
            className={classes.selectSituacao}
          />
        </Grid>
      </div>

      <div className={classes.tableDadosAniversariantes}>
        <Scroll
          loadMore={loadMore}
          hasMore={!lastScroll}
          pageStart={0}
          initialLoad={false}
          className={classes.scrollContainer}
        >
          { indicacoes.length === 0 && !indicacoesLoading && 
            <div className={classes.notFoundContainer}>
              <h3>Nenhum item encontrado</h3>
            </div>
          }
          {indicacoes.length > 0 &&
            <Table
              className={classes.table}
              dados={indicacoes}
              columns={columns}
              clickable={false}
              comOrdenacao
              ordenarTabela={ordenarTabela}
              handleClickOrdenar={handleClickOrdenar}
            />
          }
          {
            indicacoesLoading && <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <CircularProgress size={30} />
          </Grid>
          }
        </Scroll>
        <div className={classes.buttonsDownloadPrint}>
          <ButtonYellow onClick={printRelatorio}>
            <PrintIcon />
          </ButtonYellow>
        </div>
      </div>
      { printing && 
        <ImpressaoHtml
          isPrintMustache={printing}
          handlePrintMustache={() => setPrinting(false)}
          htmlStringComponent={ <RelatorioIndicacoesPdfDocument
            cidade={cidade}
            situacao={status.label}
            indicacoes={indicacoes}
            pesquisadoPor={searchNome}
            utilizaTelefoneInternacional={unidadeStore.unidade.utilizaTelefoneInternacional}
          />}
        />}
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.isOpen}
        variant={'error'}
        message={notification.message}
      />
    </div>
  );
});

const RelatorioIndicacoesWithStyles = withStyles(styles)(RelatorioIndicacoes)
export default inject("configuracaoImpressaoStore", "unidadeStore")(RelatorioIndicacoesWithStyles)