import React from 'react'

import { Button } from '../../../components/ui/Buttons'
import { CONTACTS } from '../../../utils/constantes'
import { InfoDataValidade } from '../components'

export const getColumns = ({ nameRede, handleClick, loadingButton }) => {
  const isExpired = row => {
    if (!row.dataVencimento) return false
    const dataVencimento = new Date(row.dataVencimento)
    const dataAtual = new Date()
    const diff = dataVencimento.getTime() - dataAtual.getTime()
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24))
    return dataVencimento < dataAtual || diffDays <= 1
  }

  const isDisabled = row => {
    if (!row.dataVencimento) return false
    const dataVencimento = new Date(row.dataVencimento)
    dataVencimento.setDate(dataVencimento.getDate() + 5)
    
    const dataAtual = new Date()
    return dataVencimento < dataAtual
  }

  const isLoading = row => {
    if (loadingButton?.id === row.id) return loadingButton.loading
    return false
  }

  return [
    {
      Header: nameRede,
      getValue: row => {
        return row.nome || '-'
      },
    },
    {
      Header: 'Data de validade',
      getValue: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <InfoDataValidade data={row} />
            </div>
          </>
        )
      },
    },
    {
      Header: 'ㅤ',
      getValue: row => {
        if (!isExpired(row)) return null
        return (
          <Button
            kind='secondary'
            style={{ background: '#F9BE73' }}
            onClick={() => window.open(`https://wa.me/${CONTACTS.WHATSAPP_NUMBER}`)}
          >
            Renovar
          </Button>
        )
      },
    },
    {
      Header: 'Ação',
      getValue: row => {
        const disabled = isDisabled(row)
        const loading = isLoading(row)
        return (
          <Button
            kind='primary'
            disabled={disabled}
            isLoading={loading}
            onClick={() => handleClick(row)}
          >
            Acessar
          </Button>
        )
      },
    },
  ]
}
