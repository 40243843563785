import { inject } from 'mobx-react';
import { observer } from "mobx-react-lite";
import GridLayout from 'react-grid-layout';
import { withStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "./styles.css";
import MiniAgenda from "./MiniAgenda";
import string from '../../../utils/string'
import Notification from "../../Notification";
import { findAllSala } from "../../../services/SalaService";
import ActionsButtons from "../AgendaFloatButtons/ActionsButtons"
import { getUnidadeLogado, permiteVerAgendaOutrosProfissionais } from '../../../services/UsuarioService';
import { configuracaoMultiplosHorariosAtendimento, findAllMultiplosAgendamentos, findByConfiguracaoMultiAgenda, saveConfiguracaoMultiAgenda } from '../../../services/Agendas/MultiAgendaService';
import localStorageService, { LAYOUT_MULTI_AGENDA, MULTI_AGENDA_SELECIONADAS } from '../../../services/storage';
const styles = () => ({
    item: {
        backgroundColor: '#EEEEEE',
        height: '100%',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid rgba(134, 134, 134, 0.4)',
        display: 'flex',
        flexDirection: 'column'
    },
    cardHeader: {
        height: '42px'
    },
    listaAgendamentos: {
        overflow: 'auto',
        marginBottom: '16px'
    },
    reactSelect: {
        width: "200px",
        zIndex: 100
    },
    gridLayout: {
        height: '100% !important',
        overflow: 'auto'
    }
})

const configuracaoMultiAgendaDefault = {
    id: "",
    configuracaoAgenda: {
        profissionalSaude: null,
        sala: null,
    }
}

const MultiAgenda = observer(props => {
    const { classes, profissionalSaudeStore, atendimentoStore,
        onSelectAgendamento,
        onRemoveAgendamento,
    } = props;
    const [layout, setLayout] = useState();
    const [agendas, setAgendas] = useState();
    const [agendasSelecionadas, setAgendasSelecionadas] = useState();
    const [agendasDisponiveis, setAgendasDisponiveis] = useState([]);
    const [agendasCarregadas, setAgendasCarregadas] = useState();
    const [hideBlocked, setHideBlock] = useState(false);
    const [hideEmpty, setHideEmpty] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [configuracaoMultiAgenda, setConfiguracaoMultiAgenda] = useState(configuracaoMultiAgendaDefault);
    const [notification, setNotification] = useState({
        open: false,
        message: '',
        type: 'error'
    })

    const layoutDefault = [{ i: '0', x: 0, y: 2, w: 4, h: 6, minW: 3, minH: 5, maxW: 12, maxH: 24 }]
    const agendaDefault = [{ id: 0, nome: 'Selecione a agenda', tipo: 'SELECIONAR', ativo: true }];


    const closeAlertMessage = () => {
        setNotification(prevState => ({ ...prevState, open: false }))
    }
    const buscarAgendasDisponiveisParaSelecionar = async () => {
        let salasEProfissionais = []

        const permiteVerOutrosProfissionais = await permiteVerAgendaOutrosProfissionais();
        const unidadeAtual = await getUnidadeLogado();

        if (permiteVerOutrosProfissionais) {
            const profissionaisSaude = permiteVerOutrosProfissionais && await profissionalSaudeStore.findByUnidadeComAgenda(unidadeAtual.id);
            const agendasProfissionais = profissionaisSaude && profissionaisSaude.map(item => { return { ...item, tipo: 'PROFISSIONAL', ativo: true } });
            salasEProfissionais.push(...agendasProfissionais)
        }

        const findAllSalas = await findAllSala(
            {
                ativo: true,
                search: '',
                pageableDTO: {
                    pageNumber: 0,
                    pageSize: 2000,
                    sortDir: 'ASC',
                    sortField: 'nome'
                }
            }
        );
        const salas = findAllSalas.data.data.findAllSala.content;

        const agendasSalas = salas.map(item => { return { ...item, tipo: 'SALA' } });

        salasEProfissionais.push(...agendasSalas);

        setAgendasCarregadas(salasEProfissionais);
    }


    useEffect(() => { filtrarAgendasDisponiveis() }, [agendasCarregadas, agendasSelecionadas])

    const filtrarAgendasDisponiveis = () => {
        if (agendasCarregadas) {
            const removeSelecionados = agendasCarregadas.filter(item => !agendasSelecionadas.find(selecionado => selecionado.id === item.id))
            setAgendasDisponiveis(removeSelecionados);
        }
    }


    const buscarAgendasSelecionadas = async () => {
        if (!agendasSelecionadas) {
            const multiAgendaSelecionada = await localStorageService.get(MULTI_AGENDA_SELECIONADAS);
            if (multiAgendaSelecionada) {
                setAgendasSelecionadas(multiAgendaSelecionada);
            } else {
                setAgendasSelecionadas(agendaDefault);
            }
        }
    }

    const filterEvents = (eventos) => {
        const status = (atendimentoStore.situacoes.filter(value => !value.disabled)).map(({ value }) => value);
        return eventos.filter(value => status.includes(value.situacao));
    }

    const carregarAgendas = async () => {
        const agendasComId = agendasSelecionadas.filter(item => item.id !== 0 && item);
        const agendasSemId = agendasSelecionadas.filter(item => !item.id && item);

        const permiteVerOutrosProfissionais = await permiteVerAgendaOutrosProfissionais();

        const searchDTO = {
            agendas: agendasComId,
            date: atendimentoStore.selectedDate,
            permiteVerOutrosProfissionais
        }

        const multiConfiguracoes = await configuracaoMultiplosHorariosAtendimento(searchDTO);
        const multiAgendamentos = await findAllMultiplosAgendamentos(searchDTO);

        const agendas = agendasComId.map(agenda => {
            const agendamentos = multiAgendamentos[`agendamentos${string.converteUUIDeID(agenda.id)}`].content
            const configVigente = multiConfiguracoes[`config${string.converteUUIDeID(agenda.id)}`][0];

            const eventosFiltrados = filterEvents(agendamentos);

            return { selecionado: agenda, configVigente, agendamentos, eventosFiltrados }
        });

        const agendasSemSelecao = agendasSemId.map(item => { return { ...item, selecionado: item } })

        setAgendas([...agendas, ...agendasSemSelecao]);
        setLoading(false);
    }

    const carregaLayout = async (configuracaoMultiAgendaReturn = null) => {
        if (!layout || configuracaoMultiAgendaReturn) {
            const configuracaoMultiAgenda = configuracaoMultiAgendaReturn ? configuracaoMultiAgendaReturn : await findByConfiguracaoMultiAgenda();
            if(!configuracaoMultiAgenda) {
                const layoutLocalStorage = await localStorageService.get(LAYOUT_MULTI_AGENDA);
                if(layoutLocalStorage) {
                    setLayout(layoutLocalStorage);
                    return
                }
            }
            const { configuracaoAgendaOrder } = configuracaoMultiAgenda || {};

            if (configuracaoMultiAgenda) {
                const config = configuracaoAgendaOrder.reduce((acc, item) => {
                    const { i, x, y, w, h, minW, minH, maxW, maxH, statitc, moved, ativo, profissionalSaude, sala, id } = item;
                    const agendaConfig = profissionalSaude || sala;
                    const tipo = profissionalSaude?.id ? 'PROFISSIONAL' : 'SALA';

                    if(item.ativo) {
                        acc.layouts.push({
                          i,
                          x,
                          y,
                          w,
                          h,
                          minW,
                          minH,
                          maxW,
                          maxH,
                          static: statitc,
                          moved,
                          ativo
                        });
                      
                        acc.agenda.push({ ativo, ...agendaConfig, tipo, idAgendamento: id});

                    }
                    return acc;
                                    
                }, { layouts: [], agenda: [] });
                                
                setLayout(config.layouts);
                setAgendasSelecionadas(config.agenda);
                setConfiguracaoMultiAgenda(configuracaoMultiAgenda)
            } else {
                setLayout(layoutDefault);
                setAgendasSelecionadas(agendaDefault);
            }
        }
    }


    const changeAgenda = (agendaAnterior, novaAgenda) => {
        let agendas = agendasSelecionadas.map(item => {
            if (item?.id === agendaAnterior?.id) {
                return novaAgenda;
            }
            return item;
        })

        setAgendasSelecionadas(agendas);
    }

    const possuiAgendasSelecionadas = () => {
        const agendasSelecionadasValida = agendasSelecionadas && agendasSelecionadas.some(item => item.id !== 0 && item);

        return agendasSelecionadasValida
    }

    const saveConfigMultiAgenda = async() => {
        try {
            setLoadingSave(true)
            if(!possuiAgendasSelecionadas()) {
                openNotification(
                    'É necessário selecionar uma agenda',
                    'error'
                )
                return;
            }

            const possuiConfiguracaoMultiAgenda = configuracaoMultiAgenda?.id;

            const agendasSelecionadasComId = agendasSelecionadas.filter(item => item.id !== 0);
    
            const configuracaoAgendasSelecionadas = agendasSelecionadasComId.map((item, index) => {
                const layoutSelecionado = layout[index];
                const {i, x, y, w, h, minW, minH, maxW, maxH, moved} = layoutSelecionado;
                const tipo = item.tipo === 'PROFISSIONAL' ? "profissionalSaude" : 'sala';
    
                return {
                    ...(configuracaoMultiAgenda?.id && {configuracaoMultiAgenda: {
                        id: configuracaoMultiAgenda?.id
                    }}),
                    ativo: item.ativo,
                    h,
                    i,
                    ...(item?.idAgendamento && {id: item.idAgendamento}),
                    maxH,
                    maxW,
                    minH,
                    minW,
                    moved,
                    [tipo]: {
                        id: item.id
                    },
                    statitc: layoutSelecionado.static,
                    w,
                    x,
                    y,
                }
            })

            const configuracaoAgendas = possuiConfiguracaoMultiAgenda ? getConfiguracaoAgendasUpdate(configuracaoAgendasSelecionadas) : configuracaoAgendasSelecionadas

            const acao = possuiConfiguracaoMultiAgenda ? 'update' : 'create';
    
            const configuracaoMultiAgendaReturn = await saveConfiguracaoMultiAgenda(configuracaoAgendas, acao);

            acao === 'create' && carregaLayout(configuracaoMultiAgendaReturn);

            openNotification(
                'Configuração salva com sucesso',
                'success'
            )

        } catch (error) {
            openNotification(
                'Erro ao salvar as configurações',
                'error'
            )
        } finally {
            setLoadingSave(false);
        }
    }

    const getConfiguracaoAgendasUpdate = (agendasSelecionadas) => {
        const configMultiAgenda = configuracaoMultiAgenda.configuracaoAgendaOrder;
        const configMultiAgendaAdaptada = [];

        configMultiAgenda.forEach(item => {
            const { profissionalSaude, statitc, moved } = item || {};
            const nomeAtributo = profissionalSaude?.id ? 'profissionalSaude' : 'sala';
            const matchingItem = agendasSelecionadas.some(element => element[nomeAtributo]?.id === item[nomeAtributo].id);

            if(matchingItem) {
                const configAtivo = agendasSelecionadas.find(agenda => agenda[nomeAtributo]?.id === item[nomeAtributo].id)

                configMultiAgendaAdaptada.push({
                    ...configAtivo,
                    id: item.id
                })
            } else {
                configMultiAgendaAdaptada.push({
                    ...(configuracaoMultiAgenda?.id && {configuracaoMultiAgenda: {
                        id: configuracaoMultiAgenda?.id
                    }}),
                    ativo: false,
                    id: item.id,
                    ...layoutDefault[0],
                    moved,
                    [nomeAtributo]: {
                        id: item[nomeAtributo].id
                    },
                    statitc,
                })
            } 
        });

        const agendasSemCadastro = agendasSelecionadas.filter(element => !configMultiAgenda.includes(element));

        return [...agendasSemCadastro, ...configMultiAgendaAdaptada];
    }

    const openNotification = (message, type = null) => {
        setNotification({
            open: true,
            message,
            type
        });
        const timeoutId = setTimeout(() => {
            closeAlertMessage();
            clearTimeout(timeoutId);
        }, 3000);
    }


    const salvarLayoutStorage = () => {
        if (layout) {
            localStorageService.set(LAYOUT_MULTI_AGENDA, layout);
        }
        if (agendasSelecionadas) {
            localStorageService.set(MULTI_AGENDA_SELECIONADAS, agendasSelecionadas)
        }
    }

    useEffect(() => {
        buscarAgendasSelecionadas();
        buscarAgendasDisponiveisParaSelecionar();
        !agendasSelecionadas && setAgendasSelecionadas(agendaDefault);
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
            if (agendasSelecionadas) {
                carregarAgendas()
            }
        }, 30000);
        return () => clearInterval(timer);
    });

    useEffect(() => {
        if (agendas) {
            carregaLayout();
        }
    }, [agendas])

    useEffect(() => {
        if (agendasSelecionadas) { carregarAgendas() }
    }, [agendasSelecionadas, atendimentoStore.status])

    useEffect(() => {
        if (atendimentoStore.disableModalButtons && agendasSelecionadas) { carregarAgendas() }
    }, [atendimentoStore.disableModalButtons])

    useEffect(() => {
        salvarLayoutStorage();
    }, [layout, agendasSelecionadas])

    const adicionarAgenda = () => {
        const jaPossuiAgendaVazia = agendasSelecionadas.find(item => item.id === 0);
        if (jaPossuiAgendaVazia) return;

        const adicionarAgenda = [
            ...agendasSelecionadas,
            { id: 0, nome: 'Selecione a agenda', tipo: 'SELECIONAR' }
        ]

        const quantidadeAgendas = layout.length

        setLayout([...layout, { i: quantidadeAgendas.toString(), x: 0, y: Infinity, w: 4, h: 6, minW: 3, minH: 5, maxW: 12, maxH: 24, ativo: true }])
        setAgendasSelecionadas(adicionarAgenda)
    }

    const removerAgenda = ({ agenda, index }) => {
        if (loading) return;
        const agendaRemovida = agendasSelecionadas.filter(item => {
            return item.id !== agenda.id
        });

        const novoLayout = layout.filter(item => {
            return item.i !== index.toString()
        })

        const redefinirIndexLayout = novoLayout.map((item, index) => { return { ...item, i: index.toString() } })

        setLoading(true);
        setLayout(redefinirIndexLayout);
        setAgendasSelecionadas(agendaRemovida);
    }
    const onLayoutChange = (newLayout) => {
        setLayout(newLayout);
    }

    return (
        <>
            <GridLayout
                className={classes.gridLayout}
                layout={layout}
                cols={20}
                rowHeight={30}
                width={2400}
                onLayoutChange={(newLayout) => onLayoutChange(newLayout)}
            >
                {layout && agendas && layout.map((each, index) => {
                    return (
                        <div key={index.toString()}>
                            <MiniAgenda
                                agendasDisponiveis={agendasDisponiveis}
                                agendamentos={agendas[index]?.eventosFiltrados}
                                todosAgendamentos={agendas[index]?.agendamentos}
                                configVigente={agendas[index]?.configVigente}
                                agendaSelecionada={agendas[index]?.selecionado}
                                changeAgenda={changeAgenda}
                                selectedDate={atendimentoStore.selectedDate}
                                onSelectAgendamento={onSelectAgendamento}
                                onRemoveAgendamento={onRemoveAgendamento}
                                hideBlocked={hideBlocked}
                                hideEmpty={hideEmpty}
                                loading={loading}
                                removerAgenda={(agenda) => removerAgenda({ agenda, index })}
                            />
                        </div>
                    )
                })}
            </GridLayout>
            <ActionsButtons
                addButtonAction={() => adicionarAgenda()}
                hideBlocked={hideBlocked}
                hideEmpty={hideEmpty}
                onClickBlock={() => setHideBlock(!hideBlocked)}
                onClickEmpty={() => setHideEmpty(!hideEmpty)}
                saveConfigMultiAgenda={() => saveConfigMultiAgenda()}
                disabledSave={loadingSave}
            />
            <Notification
                close={closeAlertMessage}
                reset={closeAlertMessage}
                isOpen={notification.open}
                variant={notification.type}
                message={notification.message}
            />
        </>
    )
})

export default withStyles(styles)(inject("profissionalSaudeStore", "atendimentoStore")(MultiAgenda));