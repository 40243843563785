import Api from '../config/api'

export const findByIdSujeitoAtencao = id =>
  Api.post('', {
    query: `
				query ($id: UUID){
					findByIdSujeitoAtencao(id: $id){
						id 
						nome 
						nomeSocial 
						dataNascimento 
						cpf
						fotoPerfil
						documentoTipo{
                          id
                          descricao
                        }
						rg 
						cns 
						comoConheceu
						altura
						peso
						dadosConvenio {
							convenio {
								id
								descricao
							}
						numeroCarteira
						validadeCarteira
							}
						quemIndicou
						religiao
						endereco {
							municipio {
								nome
							}
						}
						informacoesImportantes {
							informacao
							id
							inativacao {
								dataInativacao
								id
								inativador{
									nome
								}
								motivo
							}
						}
						estadoCivil {
							descricao
						}
						sexo {
							descricao
							id
						}
						contato {
							telefonePrincipal
							email
						}
						raca {
							descricao
						}
						dataNascimento
						ocupacao {
							descricao
						}
					}
				}	
		`,
    variables: { id },
  })

export const findByIdSujeitoAtencaoImpressao = id =>
  Api.post('', {
    query: `
			query ($id: UUID) {
				findByIdSujeitoAtencao(id: $id) {
				  id
				  nome
				  nomeSocial
				  dataNascimento
				  sexo{
				    descricao
				  }
				  ativo
				  cpf
				  documento
				  documentoTipo {
					  id
					  descricao
				  }
				  rg
				  religiao
				  profissionalSaude{
				    nome
				  }
				  estadoCivil{
				    descricao
				  }
				  dadosConvenio {
				    convenio {
				      id
				      descricao
				    }
				    numeroCarteira
				    validadeCarteira
				  }
				  endereco{
				    cep
				    nomeLogradouro
				    numero
				    bairro
				    estado{
				      nome
				    }
				    municipio{
				      nome
				    }
				    complemento
				  }
				  contato{
				    telefonePrincipal
				    telefonePrincipalDDI
				    telefoneSecundario
				    telefoneSecundarioDDI
				    email
				  }
				  raca{
				    descricao
				  }
				  religiao
				  nomeMae
				  nomePai
				  escolaridade{
				    descricao
				  }
				  ocupacao{
				    descricao
				  }
				  cns
				  altura
				  peso
				  quemIndicou
				  observacao
				  espanhaMunicipio{
				    descricao
				    provincia{
				        descricao
				        comunidadeAutonoma{
				            descricao
				        }
				    }
				  }
				}
			}	
		`,
    variables: { id },
  })

export const quantidadeSujeitoDeAtencaoCadastrados = () =>
  Api.post('', {
    query:
      '' +
      `{
			quantidadeSujeitoDeAtencaoCadastrados
			}`,
  })

export const findByIdSujeitoAtencaoAtendimento = id =>
  Api.post('', {
    query: `
			query ($id: UUID){
				findByIdSujeitoAtencao(id: $id){
					id
					nome
					nomeSocialOuNome
					dataNascimento
					nomeResponsavel
					documentoResponsavel
					documento
					observacao
					documentoTipo {
						descricao
						id
					}
					dadosConvenio {
						convenio {
							id
							descricao
							convenioAgendamentoTipo {
								agendamentoTipo {
									id
									descricao
								}
								valor
							}
						}
					}
					dadosConvenios {
						convenio {
						  descricao
						  id
						}
						numeroCarteira
						id
						validadeCarteira
					}
					contato{
						telefonePrincipal
						telefonePrincipalDDI
						telefoneSecundario
						telefoneSecundarioDDI
          			}		
					fotoPerfil
					endereco{
						municipio {
							nome
							uf
						}
					}
				}
			}	
		`,
    variables: { id },
  })

export const consultaDeCep = cep =>
  Api.post('', {
    query: `
        query($cep: String){
             consultaDeCep(cep: $cep) {
                cep
                tipoLogradouro {
					value:id
                	label:tipo
                }
                logradouro
                bairro
                municipio {
                  id
                  nome
                  estado{
                    id
                    nome
										uf
                  }
                }
              }
            }`,
    variables: { cep },
  })

export const findByCPFAndTelefone = (telefone, cpf) =>
  Api.post('', {
    query: `
		query ($telefone: String, $cpf: String){
			findByCPFAndTelefone(telefone: $telefone, cpf: $cpf)
		}`,
    variables: { telefone, cpf },
  })

export const getSujeitoAtencaoReceitaControlada = async id => {
  try {
    const response = await Api.post('', {
      query: `
			{
				findByIdSujeitoAtencao(id: "${id}") {
				id,
				nome,
				fotoPerfil
				endereco {
				  bairro
				  cep
				  complemento
				  estado {
					uf
					id
				  }
				  municipio {
					nome
					id
				  }
				  nomeLogradouro
				  numero
				}
			  }
			}
			`,
    })
    const paciente = response.data.data.findByIdSujeitoAtencao
    return paciente
  } catch (error) {
    console.error(error)
  }
}

export const updateSujeitoAtencaoInformacoesImportantes = async variables => {
  try {
    const response = await Api.post('', {
      query: `
				mutation($sujeitoAtencao: SujeitoAtencaoInput, $informacoesImportantes: [SujeitoAtencaoInformacaoImportanteInput]) {
					updateSujeitoAtencaoInformacoesImportantes(sujeitoAtencao: $sujeitoAtencao, informacoesImportantes: $informacoesImportantes) {
						informacoesImportantes {
							informacao
						}
					}
				}
			`,
      variables,
    })

    if (response?.data?.data) {
      const {
        informacoesImportantes,
      } = response?.data?.data?.updateSujeitoAtencaoInformacoesImportantes
      return informacoesImportantes
    } else {
      throw new Error(response.data.errors[0])
    }
  } catch (error) {
    console.log(error.message)
  }
}

export const createSujeitoAtencaoInformacaoImportante = async variables => {
  const response = await Api.post('', {
    query: `
			mutation($sujeitoAtencao: SujeitoAtencaoInput, $informacaoImportante: SujeitoAtencaoInformacaoImportanteInput) {
				createSujeitoAtencaoInformacaoImportante(sujeitoAtencao: $sujeitoAtencao, informacaoImportante: $informacaoImportante) {
					informacoesImportantes {
						informacao
						id
						inativacao {
							dataInativacao
							id
							inativador{
								nome
							}
							motivo
						}
					}
				}
			}
		`,
    variables,
  })

  if (response?.data?.data) {
    const {
      informacoesImportantes,
    } = response?.data?.data?.createSujeitoAtencaoInformacaoImportante
    return informacoesImportantes
  } else {
    throw new Error(response.data.errors[0])
  }
}

export const inativarSujeitoAtencaoInformacaoImportante = async variables => {
  const response = await Api.post('', {
    query: `
			mutation($idSujeitoAtencaoInformacaoImportante: UUID, $motivo: String) {
				inativarSujeitoAtencaoInformacaoImportante(idSujeitoAtencaoInformacaoImportante: $idSujeitoAtencaoInformacaoImportante, motivo: $motivo) {
					dataInativacao
					id
					inativador {
						nome
					}
					motivo
				}
			}
		`,
    variables,
  })

  if (response?.data?.data) {
    return response?.data?.data?.inativarSujeitoAtencaoInformacaoImportante
  } else {
    throw new Error(response.data.errors[0])
  }
}

export const sujeitoAtencaoCsv = async variables => {
  try {
    const response = await Api.post('', {
      query: `
				query ($search: SearchSujeitoAtencaoDTOInput, $pageableDTO: PageableDTOInput) {
					sujeitoAtencaoCsv(search: $search, pageableDTO: $pageableDTO)
				}
			`,
      variables,
    })

    if (response?.data?.data) {
      return response?.data?.data?.sujeitoAtencaoCsv
    } else {
      throw new Error(response.data.errors[0])
    }
  } catch (error) {
    console.log(error.message)
  }
}

export const findAllSujeitoAtencaoUnificacao = async ({
  search,
  pageNumber,
  filtrarUnificados,
}) => {
  const searchDTO = {
    search,
    filtrarUnificados,
    pageNumber,
    pageSize: 20,
    sortDir: 'ASC',
    sortField: 'nome',
  }

  const response = await Api.post('', {
    query: `
		query ($searchDTO: SearchSujeitoAtencaoDTOInput) {
			findAllSujeitoAtencao(searchDTO: $searchDTO) {
				last
				content {
					id
					nome
					dataNascimento	
					cpf
					contato {
						telefonePrincipal
						telefonePrincipalDDI
						telefoneSecundario
						telefoneSecundarioDDI
					}					
				}
			}
			}
		`,
    variables: { searchDTO },
  })

  if (response?.data?.data) {
    return response?.data?.data?.findAllSujeitoAtencao
  } else {
    throw new Error(response.data.errors[0])
  }
}

export const unificarSujeitosAtencao = async variables => {
  const response = await Api.post('', {
    query: `
		mutation($motivo: String, $origens: [UUID], $destino: UUID){
			unificarSujeitosAtencao(motivo: $motivo, origens: $origens, destino: $destino){
				id
			}
		}
		`,
    variables,
  })

  return response
}

export const pagamentosSujeitoAtencaoProfissionalLogado = async variables => {
  const response = await Api.post('', {
    query: `
		query ($sujeitoAtencaoId: UUID, $pageableDTO: PageableDTOInput) {
			findAllTituloParcelaProfissionalSaudeLogado(sujeitoAtencaoId: $sujeitoAtencaoId, pageableDTO: $pageableDTO) {
			  id
			  tipo
			  dataVencimento
			  dataPagamento
			  nome
			  totalParcelas
			  tipoRepeticao
			  numeroParcela
			  formaPagamento {
				nome
			  }
			  procedimentos {
				id
				nome
			  }
			  profissionalSaude {
				nome
			  }
			  valor
			}
		  }
		`,
    variables,
  })

  if (response?.data?.data) {
    return response?.data?.data?.findAllTituloParcelaProfissionalSaudeLogado
  } else {
    throw new Error(response.data.errors[0])
  }
}

export const pagamentosSujeitoAtencaoOutrosProfissionais = async variables => {
  const response = await Api.post('', {
    query: `
		query ($sujeitoAtencaoId: UUID, $pageableDTO: PageableDTOInput) {
			findAllTituloParcela(sujeitoAtencaoId: $sujeitoAtencaoId, pageableDTO: $pageableDTO) {
			  id
			  tipo
			  dataVencimento
			  dataPagamento
			  nome
			  totalParcelas
			  tipoRepeticao
			  numeroParcela
			  formaPagamento {
				nome
			  }
			  procedimentos {
				id
				nome
			  }
			  profissionalSaude {
				nome
			  }
			  valor
			}
		  }
		`,
    variables,
  })

  if (response?.data?.data) {
    return response?.data?.data?.findAllTituloParcela
  } else {
    throw new Error(response.data.errors[0])
  }
}

export const liberarAcessoVizi = (sujeitoAtencaoId, pacienteId = null) => {
  const mutationParams =
    sujeitoAtencaoId && pacienteId
      ? '$sujeitoAtencaoId: UUID, $pacienteId: UUID'
      : '$sujeitoAtencaoId: UUID'
  const liberarAcessoViziParams =
    sujeitoAtencaoId && pacienteId
      ? 'sujeitoAtencaoId: $sujeitoAtencaoId, pacienteId: $pacienteId'
      : 'sujeitoAtencaoId: $sujeitoAtencaoId'
  const variables =
    sujeitoAtencaoId && pacienteId
      ? { sujeitoAtencaoId, pacienteId }
      : { sujeitoAtencaoId }

  return Api.post('', {
    query: `
			mutation(${mutationParams}) {
				liberarAcessoVizi(${liberarAcessoViziParams}) {
					id
				}
			}
		`,
    variables: variables,
  })
}

export const ativarSujeitoAtencao = sujeitoAtencaoId => {
  return Api.post('', {
    query: `
			mutation($sujeitoAtencaoId: UUID) {
				ativarSujeitoAtencao(sujeitoAtencaoId: $sujeitoAtencaoId) {
					id
				}
			}
		`,
    variables: {
      sujeitoAtencaoId,
    },
  })
}

export const desativarSujeitoAtencao = sujeitoAtencaoId => {
  return Api.post('', {
    query: `
			mutation($sujeitoAtencaoId: UUID) {
				desativarSujeitoAtencao(sujeitoAtencaoId: $sujeitoAtencaoId) {
					id
				}
			}
		`,
		variables: {
			sujeitoAtencaoId
		}
	});
};

export const findAllCampoPaciente = async () => {
	const response = await Api.post("", {
		query: `
		query{
			findAllCampoPaciente(campo:"") {
				campo
				nome
				ordem
			}
		}
		`
	});
	const {findAllCampoPaciente} = response?.data?.data;
	if(!findAllCampoPaciente) {
		throw new Error('Erro ao buscar campos do paciente');
	}
	return findAllCampoPaciente;
}

export const findAllCampoPacienteByProfissionalSaudeLogadoList = async () => {
	const response = await Api.post("", {
		query: `
		query {
			findAllCampoPacienteByProfissionalSaudeLogadoList {
				campoPaciente {
					campo
					nome
					ordem
					caminhoValor
				}
				ordem
			}
		}
		`
	});
	const {findAllCampoPacienteByProfissionalSaudeLogadoList} = response?.data?.data;
	if(!findAllCampoPacienteByProfissionalSaudeLogadoList) {
		throw new Error('Erro ao buscar campos do paciente');
	}
	return findAllCampoPacienteByProfissionalSaudeLogadoList;
}

export const updateFiltroCampoPacienteProfissionalSaudeLogado = async (variables) => {
	const response = await Api.post("", {
		query: `
		mutation($filtroCamposPaciente: [CampoPacienteInput]) {
			updateFiltroCampoPacienteProfissionalSaudeLogado(filtroCamposPaciente: $filtroCamposPaciente) {
				campoPaciente {
					campo
					nome
					ordem
				}
			}
		}
		`,
		variables
	});
	const {updateFiltroCampoPacienteProfissionalSaudeLogado} = response?.data?.data;
	if(!updateFiltroCampoPacienteProfissionalSaudeLogado) {
		throw new Error('Erro ao atualizar campo do paciente');
	}
	return updateFiltroCampoPacienteProfissionalSaudeLogado;
}

export const findByIdSujeitoAtencaoWithEstadoList = sujeitoAtencaoId => {
  return Api.post('', {
    query: `
			query ($sujeitoAtencaoId: UUID!) {
				findByIdSujeitoAtencao(id: $sujeitoAtencaoId) {
					id,
					nome,
					fotoPerfil,
					endereco {
						cep
						nomeLogradouro
						numero
						bairro
						municipio {
							id
							nome
							estado {
								id,
								uf
							}
						}
					}
				}
				findAllEstadoList {
					id,
					uf
				}
			}
		`,
    variables: {
      sujeitoAtencaoId,
    },
  })
}

export const updateEnderecoSujeitoAtencao = variables => {
  return Api.post('', {
    query: `
			mutation ($sujeitoAtencaoId: UUID, $endereco: EnderecoInput) {
				updateEnderecoSujeitoAtencao(sujeitoAtencaoId: $sujeitoAtencaoId, endereco: $endereco) {
					id,
					nome
				}
			}
		`,
    variables: variables,
  })
}

export const findSujeitoAtencaoByDocumento = documento => {
  return Api.post('', {
    query: `
			query ($documento: String) {
				findSujeitoAtencaoByDocumento(documento: $documento) {
					id,
					nome
				}
			}
		`,
    variables: {
      documento,
    },
  })
}

export const consultarInadimplencia = async(sujeitoId) => {

	const response = await Api.post("", {
		query: `
			query ($sujeitoId: UUID) {
				consultarInadimplencia(sujeitoId: $sujeitoId)
			}
		`,
		variables: {
			sujeitoId
		}
	});

	if(!response.data.data.consultarInadimplencia) {
		throw new Error('Erro ao consultar inadimplencia');
	}

	return response.data.data.consultarInadimplencia
}