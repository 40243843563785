import {
  withStyles,
} from "@material-ui/core/es";
import React, { useState, useEffect } from "react";
import { inject } from "mobx-react";
import { Grid } from "@material-ui/core";
import Colors from "../../../../../template/Colors";
import StatusImc from "../../StatusImc";
import { InputDateForm } from "../../../../../components/Modal/Input";
import PopoverDadosAdicionais from "./PopoverDadosAdicionais";
import ModalInativarEntradaProntuario from "../../../../../components/Modal/ModalInativarEntradaProntuario";
import Notification from "../../../../../components/Notification";
import classNames from "classnames";
import PopperCustomMenu from "../../../../../components/Popper/PopperCustomMenu";
import { calcImc } from '../../../../../services/AvaliacaoAntropometricaService';
import MoreIcon from "../../../../../components/Icon/MoreIcon";

const HistoricoLancamentos = (props) => {
  const {
    classes,
    avaliacaoAntropometrica,
    handleVisualizar,
    prontuarioStore,
    inativarAvaliacaoAntropometrica,
    avaliacaoAntropometricaSelecionado
  } = props;

  const menuOpcoesPopper = [
    {ativo: true, label: "Visualizar", onClick: () => handleVisualizarHistorico()},
    {ativo: avaliacaoAntropometrica.ativo, label: "Inativar", onClick: () => handleInativar()},
  ]

  const [showModalInativar, setShowModalInativar] = useState(false);
  const [motivoInativacao, setMotivoInativacao] = useState("");
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
  });
  const [loadingInativacao, setLoadingInativacao] = useState(false);
  const [imcDescricao, setImcDescricao] = useState('');

  useEffect(() => {
    const getImcDescricao = async (sexoId, idade, peso, altura) => {
      const imcDescricao = await calcImc({
        sexoId,
        idade,
        peso,
        altura,
      });
      if (imcDescricao) {
        setImcDescricao(imcDescricao);
      }
    };

    if (
      avaliacaoAntropometrica.idade && 
      avaliacaoAntropometrica.sexoId && 
      avaliacaoAntropometrica.estatura && 
      avaliacaoAntropometrica.peso 
    ) {
        getImcDescricao(
          avaliacaoAntropometrica.sexoId,
          avaliacaoAntropometrica.idade,
          avaliacaoAntropometrica.peso,
          avaliacaoAntropometrica.estatura / 100
        );
    }
  }, []);

  const quantidadeCamposPreenchido = () => {
    const dadosAvaliacaoAntropometrica = {
      peso: avaliacaoAntropometrica.peso,
      estatura: avaliacaoAntropometrica.estatura,
      quadril: avaliacaoAntropometrica.quadril,
      cintura: avaliacaoAntropometrica.cintura,
      perimetroCefalico: avaliacaoAntropometrica.perimetroCefalico,
      circunferenciaBraquial: avaliacaoAntropometrica.circunferenciaBraquial,
      pregaCutanea: avaliacaoAntropometrica.pregaCutanea
    }

    const transformaValuesDoObjetoEmArray = Object.values(
      dadosAvaliacaoAntropometrica
    );
    const valoresValidos = transformaValuesDoObjetoEmArray.filter(
      (item) => !!item
    );
    const quantidadeDeValue = valoresValidos.length ;

    return `${quantidadeDeValue} campos preenchidos`;
  };

  const handleVisualizarHistorico = () => {
    handleVisualizar();
  };

  const handleInativar = () => {
    setShowModalInativar(true);
  };

  const handleCloseModalInativar = () => {
    setShowModalInativar(false);
  };

  const handleChangeMotivoInativacao = (e) => {
    setMotivoInativacao(e.target.value);
  };

  const handleInativarModal = async () => {
    try {
      setLoadingInativacao(true);

      if (motivoInativacao.length > 0 && motivoInativacao.length < 2000) {
        await prontuarioStore.inativarEntradaProntuario(
          avaliacaoAntropometrica.entradaProntuario.id,
          motivoInativacao
        );
        prontuarioStore.findAllEntradaProntuario({ withLoading: false });
        inativarAvaliacaoAntropometrica();
        setShowModalInativar(false);
      }
    } catch (error) {
      const notification = {
        isOpen: true,
        message: "Erro ao inativar a avaliação antropometrica.",
      };
      showAlertMessage(notification);
    } finally {
      setLoadingInativacao(false);
    }
  };

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };

    setNotification(notification);
  };

  return (
    <div className={classes.row}>
      <Grid item xs={3} className={classes.spacing}>
        <span
          className={classNames(classes.titulo, {
            [classes.tituloInativo]: !avaliacaoAntropometrica.ativo,
          })}
        >
          Lançamento:
        </span>
        <InputDateForm
          iconposition="end"
          openTo="day"
          views={["year", "month"]}
          value={avaliacaoAntropometrica.data}
          disabled={true}
          classes={{
            input: classNames(classes.inputData, {
              [classes.dadoInativoRisco]: !avaliacaoAntropometrica.ativo,
            }),
            date: classNames(classes.date, {
              [classes.dadoInativo]: !avaliacaoAntropometrica.ativo,
            }),
            icon: classes.iconData,
          }}
        />
      </Grid>

      <Grid item xs={5} className={[classes.spacing, classes.spacingMargin]}>
        <span
          className={classNames(classes.titulo, {
            [classes.tituloInativo]: !avaliacaoAntropometrica.ativo,
          })}
        >
          Dados adicionais:
        </span>
        <PopoverDadosAdicionais
          quantidadeCamposPreenchido={quantidadeCamposPreenchido()}
          dadosAdicionais={avaliacaoAntropometrica}
        />
      </Grid>

      <Grid item xs={4} className={classes.spacing}>
        <span
          className={classNames(classes.titulo, {
            [classes.tituloInativo]: !avaliacaoAntropometrica.ativo,
          })}
        >
          IMC:
        </span>
        <div className={classes.inputImc}>
          {avaliacaoAntropometrica.imc && (
            <StatusImc
              imc={avaliacaoAntropometrica.imc}
              dadoAtivo={avaliacaoAntropometrica.ativo}
              imcDescricao={imcDescricao}
              isHistoricoLancamentos
            />
          )}
        </div>
      </Grid>
     {!avaliacaoAntropometricaSelecionado && 
      <PopperCustomMenu
        placement={"bottom-end"}
        iconButton={<MoreIcon style={{ transform: 'rotate(90deg)' }} color="#505050"/>}
        menuOpcoes={menuOpcoesPopper}
      />}
      { showModalInativar && <ModalInativarEntradaProntuario
        show={showModalInativar}
        onClose={handleCloseModalInativar}
        changeMotivo={handleChangeMotivoInativacao}
        inativar={handleInativarModal}
        motivo={motivoInativacao}
        loadingInativacao={loadingInativacao}
        screen="Atendimento - Avaliação antropométrica - histórico lançamento"
      /> }
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.isOpen}
        variant={"error"}
        message={notification.message}
      />
    </div>
  );
};

const style = {
  row: {
    display: "flex",
    "& button": {
      background: "#fff",
      height: "30px",
      
      "&:hover": {
        background: "#fff",
      }
    },
  },
  spacing: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 14,
  },
  spacingMargin: {
    margin: "0 14px",
  },
  titulo: {
    color: Colors.commons.gray7,
    fontSize: "12px !important",
  },
  inputImc: {
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "100px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
  },
  dadosAdicionais: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    boxSizing: "border-box",
    borderRadius: "100px",
    height: "32px",
    color: Colors.commons.gray9,
  },
  visibilityIcon: {
    color: "#5F6368",
  },
  buttonVisibility: {
    display: "flex",
    padding: "1px",
    borderRadius: 100,
    "&:hover": {
      background: "#DFDFDF",
    },
  },
  dadosAdicionaisBold: {
    fontWeight: 700,
  },
  moreVertIcon: {
    color: Colors.commons.gray9,
  },
  buttonMore: {
    cursor: "pointer",
    width: "35px",
    height: "30px",
    borderRadius: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
    "&:hover": {
      background: "#EAEAEA",
    },
  },
  popperMenu: {
    zIndex: 1,
  },
  popperPaper: {
    width: "110px",
  },
  inputData: {
    padding: "4px 0px 0px 13px",
  },
  dadoInativo: {
    opacity: 0.5,
  },
  dadoInativoRisco: {
    opacity: 0.5,
    textDecoration: "line-through",
  },
  tituloInativo: {
    opacity: 0.3,
  },
  iconData: {
    "&>button": {
      background: "#F2F2F2",
    },
  },
  date: {
    borderRadius: 100,
  },
};

const HistoricoLancamentosWithStyle = withStyles(style)(HistoricoLancamentos);
export default inject("prontuarioStore")(HistoricoLancamentosWithStyle);