import moment from 'moment';
import {inject} from 'mobx-react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {IconButton} from '@material-ui/core';
import {withStyles} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { findHorariosAtendimentosPermiteAgendamento } from '../services/ProntuarioService';

const DayPicker = (props) => {
    const { classes, atendimentoStore, filtroHeaderStore } = props;
    const [ diaSelecionado ,  setDiaSelecionado ]  =  useState(atendimentoStore.selectedDate.toDate()); 
    const [diasAtendimento, setDiasAtendimento] = useState([]);
    const [activeStartDate, setActiveStartDate] = useState(undefined);
    const [mes, setMes] = useState(new  Date ());

    useEffect(() => {
        setActiveStartDate(undefined)
    }, [mes])

    useEffect(() => {
        findHorariosAtendimento();
    }, [mes, filtroHeaderStore?.profissionalSaude?.id]);

    useEffect(() => {
        atendimentoStore.selectedDate && setDiaSelecionado(atendimentoStore.selectedDate.toDate());
    }, [atendimentoStore.selectedDate]);

    const clickDay = (day) => {
        atendimentoStore.selectedDate = moment(day);
        const { tipoProfissionalLogado, isSalaSelected } = atendimentoStore;
        if (tipoProfissionalLogado !== 'tecnico'){
            atendimentoStore.initObjectView(atendimentoStore.selectedDate);
        }
        if(tipoProfissionalLogado === 'tecnico' || isSalaSelected) {
            atendimentoStore.initObjectViewSala(atendimentoStore.selectedDate);
        }
    }

    const formatarDiasDaSemana = (locale, value) => ['D', 'S', 'T', 'Q', 'Q', 'S', ' S '][value.getDay ()];


    const onActiveStartDateChange = (mes) =>{
        setMes(mes);
    }

    const findHorariosAtendimento = async() => {
        try {
            const dataInicio = moment(mes.activeStartDate).startOf('month').format('YYYY-MM-DD');
            const dataFim = moment(mes.activeStartDate).endOf('month').format('YYYY-MM-DD');
            const { id } = filtroHeaderStore?.profissionalSaude;
            if(!id) return;
            const response = await findHorariosAtendimentosPermiteAgendamento({
                profissionalSaudeId: id,
                dataInicio,
                dataFim,
            });
            setDiasAtendimento(response);
        } catch (error) {
            console.error(error);
            setDiasAtendimento([]);
        }
    }

    const onClickCurrentDayButton = () => {
        const { tipoProfissionalLogado, isSalaSelected } = atendimentoStore;
        const dateToSet = new Date();
        dateToSet.setMonth(dateToSet.getMonth());
        const beginOfMonth = new Date(
            dateToSet.getFullYear(),
            dateToSet.getMonth(),
            1
        );
        setActiveStartDate(beginOfMonth);
        setDiaSelecionado(dateToSet);
        atendimentoStore.selectedDate = moment(dateToSet)
        
        if (tipoProfissionalLogado !== 'tecnico'){
            atendimentoStore.initObjectView(atendimentoStore.selectedDate);
        }

        if(tipoProfissionalLogado === 'tecnico' || isSalaSelected) {
            atendimentoStore.initObjectViewSala(atendimentoStore.selectedDate);
        }
    };

    return  (  
        <>
            <Calendar 
                onChange={setDiaSelecionado}
                calendarType={"US"}
                className={classes.calendario}
                value={diaSelecionado}
                prevLabel={<ArrowUpwardIcon className={classes.iconeMudarMes}/>}
                nextLabel={<ArrowDownwardIcon className={classes.iconeMudarMes}/>}
                prev2Label={null}
                next2Label={null}
                onClickDay={(day) => clickDay(day)}
                formatShortWeekday={formatarDiasDaSemana} 
                activeStartDate={activeStartDate}
                onActiveStartDateChange={ (mes) => onActiveStartDateChange(mes)}
                formatMonthYear={(locale, date) => moment(date).format('MMMM YYYY')}
                tileClassName={({ date }) => {
                    const isSelected = moment(date).format("YYYY-MM-DD") === moment(diaSelecionado).format("YYYY-MM-DD");
                    if(diasAtendimento.find(each => each === moment(date).format("YYYY-MM-DD")) && !isSelected){
                        return classes.highlight
                    }
                }}
            /> 
            <IconButton
                onClick={onClickCurrentDayButton}
                className={classes.botaoDataAtual}
            >
               Ir para data de hoje
            </IconButton>
        </>
    ) ; 
};

const styles = theme => ({
    botaoDataAtual:{
        fontSize: 14,
        color: '#27B0AD',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 0,
        padding: 5,
        marginTop: 5,
    },
    iconeMudarMes:{
        fontSize: 20,
    },
    calendario: {
        backgroundColor: "#F9F9F9",
        borderWidth: `0 !important`,
        "& .react-calendar__viewContainer": {
            "& .react-calendar__tile":{
                fontSize: 12,
                padding: "0.1em",
                borderRadius: "50%",
                maxWidth: '30px !important',
                height: '28px',
                margin: "0 3px",
                "&:hover":{
                    borderRadius: "50%",
                    maxHeight: 28,
                    maxWidth: "28px !important",
                    margin: '0 4px',
                },
               "@media (max-height: 600px)":{
                    maxWidth: '24px !important',
                    height: '24px',
                    margin: "0 6px",
                    "&:hover":{
                        borderRadius: "50%",
                        maxHeight: 24,
                        maxWidth: "24px !important",
                        margin: "0 6px",
                    }
                },
            },
            "& .react-calendar__tile--active": {
                backgroundColor: `#26ACA9 !important`,
                color: "#fff !important",
                padding: "0.1em",
                maxHeight: 28,
                maxWidth: "28px !important",
                borderRadius: "50%",
                fontSize: '12px',
                margin: '0 4px',
                "&:hover":{
                    borderRadius: "50%",
                    maxHeight: 28,
                    maxWidth: "28px !important",
                    margin: '0 4px',
                },
                "@media (max-height: 600px)":{
                    maxWidth: '24px !important',
                    height: '24px',
                    margin: "0 6px",
                    "&:hover":{
                        borderRadius: "50%",
                        maxHeight: 24,
                        maxWidth: "24px !important",
                        margin: "0 6px",
                    },
                },
            },
            "& .react-calendar__month-view__days":{
                "& .react-calendar__tile--now": {
                    backgroundColor: `#F9F9F9`,
                    padding: "0.1em",
                    maxHeight: 28,
                    maxWidth: "28px !important",
                    borderRadius: "50%",
                    fontSize: '13px',
                    margin: '0 4px',
                    color: '#00B0AE !important',
                    fontWeight: 700,

                    "& > abbr":{
                        background: '#D4EEEE',
                        minWidth: 25,
                        maxHeight: 26,
                        padding: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: "50%",
                    },
                    "@media (max-height: 600px)":{
                        maxWidth: '24px !important',
                        height: '24px',
                        margin: "0 6px",
                    },
                },
                "& .react-calendar__month-view__days__day--weekend":{
                    color: `rgba(0, 0, 0, 0.87)`,
                },
                "& .react-calendar__month-view__days__day--neighboringMonth":{
                    color: 'rgba(0, 0, 0, 0.38)',
                },
            },
            
            "& .react-calendar__year-view__months__month":{
                borderRadius: 0,
                padding: '1.3em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '80px',
                "&:hover":{
                    borderRadius: "0px !important",
                },
                "@media (max-height: 600px)":{
                    minWidth: '74px',
                },
            },
            "& .react-calendar__year-view":{
                "& .react-calendar__tile--now":{
                    background: '#F9F9F9',
                    border: '1px solid #000',
                    padding: '1.3em',
                    display: 'flex',
                    alignItems: 'center',
                    "&:hover":{
                        borderRadius: 0,
                    }
                },
                "& .react-calendar__tile--hasActive":{
                    background: "#26ACA9",
                    color: "#fff",
                    "&:hover":{
                        borderRadius: "0px !important",
                    },
                },
            },
            "& .react-calendar__decade-view":{
                "& .react-calendar__decade-view__years__year":{
                    borderRadius: 0,
                    padding: '1.3em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: '80px',
                    "&:hover":{
                        borderRadius: 0,
                    },
                },
                "& .react-calendar__tile--now":{
                    background: '#F9F9F9',
                    border: '1px solid #000',
                    "&:hover":{
                        borderRadius: 0,
                    }
                },
                "& .react-calendar__tile--hasActive":{
                    background: "#26ACA9",
                    color: "#fff",
                }
            },
            "& .react-calendar__century-view__decades__decade":{
                borderRadius: 0,
                padding: '1.3em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '80px',
                "&:hover":{
                    borderRadius: "0px !important",
                },
            },
            "& .react-calendar__decade-view__years__year":{
                padding: '1.3em 0.1em !important',
                "& .react-calendar__tile--now":{
                    background: '#F9F9F9',
                    border: '1px solid #000',
                },
            },
            "& .react-calendar__century-view":{
                "& .react-calendar__tile--hasActive":{
                    background: "#26ACA9 !important",
                    color: "#fff",
                },
                "& .react-calendar__tile--now":{
                    background: '#F9F9F9',
                    border: '1px solid #000',
                },
            },
        },

        "& .react-calendar__month-view__weekdays__weekday":{
            maxWidth: "30px !important",
            "& > abbr":{
                textDecoration: 'none !important',
            },
        },
        "& .react-calendar__month-view__weekdays": {
            color: 'rgba(0, 0, 0, 0.6)',
            fontWeight: 'normal !important',
            "& .react-calendar__month-view__weekdays__weekday":{
                padding: "0.1em",
                margin: 3
            },
        },
        "& .react-calendar__navigation": {
                display: 'grid !important',
                gridTemplateColumns: '1fr 20% 20%',
                gridTemplateAreas: `"label arrowLeft arrowRight"`,
                marginBottom: 0,
                marginLeft: 6,
                
            "& .react-calendar__navigation__arrow": {
                color: "rgba(0, 0, 0, 0.6)",
            },

            "& .react-calendar__navigation__prev-button":{
                gridArea: "arrowLeft",
            },
            "& .react-calendar__navigation__label": {
                color: "rgba(0, 0, 0, 0.6)",
                textTransform: 'capitalize',
                fontWeight: 500,
                gridArea: "label",
                justifySelf: 'flex-start',
            },
            "& .react-calendar__navigation__next-button":{
                gridArea: "arrowRight",
            }
        },
        "& .react-calendar__navigation__label":{
            width: '100%',
            textAlign: 'start',
        },
        "& .react-calendar__navigation__label__labelText--from":{
            fontSize: 14,
            color: "#26ACA9",
        }
    },
    highlight: {
        borderRadius: "0% !important",
        borderBottom: '3px solid #196B6C !important',
    },
});

const DayPickerWithStyles = withStyles(styles)(DayPicker);
export default inject("atendimentoStore", "filtroHeaderStore")(DayPickerWithStyles);
