import React, { Component, } from "react";
import Dialog from "../../../components/Dialog/Dialog";
import { Switch, Tooltip, withStyles } from "@material-ui/core";
import styles from "../MensagemModal/mensagemModalStyle";
import AdicionarDados from "../../Configuracoes/MensagemSistema/adicionarDados";
import Button from "../../../components/Button/Button";
import 'jodit';
import JoditEditor from "jodit-react"
import 'jodit/build/jodit.min.css';
import { FindMensagemSistemaById, UpdateMensagemSistema, createMensagemSistema } from "../../../services/MensagensService";
import InputForm from "../../../components/Input/InputForm";
import { inject, observer } from "mobx-react";
import { ReactSelect } from "../../../components/Select/SelectSearch";
import { ProfissionalIcon } from "../../../assets/img/svg";

@inject('filtroHeaderStore')

@observer
class ModalMensagem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastCursorPosition: null,
      messageText: "",
      unidade: null,
      mensagemSelecionada: null,
      nomeNovaMensagem: "",
      isLoading: false,
      pageableDTO: {
        pageNumber: 0,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "mensagem",
      },
      ativo: true,
      profissionalSelecionado: null,
      optionsSelect: [],
      somenteVizualizar:false
    }
  }

  jodit;
  setRef = (jodit) => (this.jodit = jodit);

  async componentDidMount() {
    const { openModal, mensagemSelected } = this.props;
    if (openModal && mensagemSelected) {
      this.carregarMensagemSelecionada(mensagemSelected, openModal);
    }
    this.getProfissionaisSaude();
  }

  componentDidUpdate(prevProps) {
    if (this.props.filtroHeaderStore.profissionalSaude !== prevProps.filtroHeaderStore.profissionalSaude) {
      this.getProfissionaisSaude()
    }
  };

  getProfissionaisSaude = () => {
    const { filtroHeaderStore } = this.props;
    const listaProfissionais = filtroHeaderStore.profissionaisSaude;
    const profissionalSelecionado = filtroHeaderStore.profissionalSaude
 
    this.setState({
      optionsSelect: listaProfissionais,
      profissionalSelecionado
    })

  }

  carregarMensagemSelecionada = async (mensagemSelected) => {
    const mensagem = await FindMensagemSistemaById(mensagemSelected); 
    if(mensagem.tipoPadrao){
      this.setState({ mensagemSelecionada: mensagem, ativo: mensagem.ativo, tipoPadrao: mensagem.tipoPadrao }, () => this.insertText(mensagem.mensagem));
      this.setState({somenteVizualizar:true})
    }else{
      this.setState({ mensagemSelecionada: mensagem, ativo: mensagem.ativo, tipoPadrao: mensagem.tipoPadrao }, () => this.insertText(mensagem.mensagem));
    }
  }

  formatCampos = (item) => {
    if(this.state.somenteVizualizar){
      return
    }
    if (item) {
      const {campo } = item;
      const campoFormatado = `\${${campo}}`;
      this.insertText(campoFormatado);
    }
  }

  insertText = (text) => {
    const texto = text.replaceAll(/\n/g, '<br>')
    
    const { lastCursorPosition } = this.state;

    if (lastCursorPosition && this.jodit) {
      this.jodit.selection.setCursorIn(this.state.lastCursorPosition);
    }

    this.jodit.selection.insertHTML(`${texto}`);
  }

  saveCursorPosition = () => {
    this.setState({ lastCursorPosition: this.jodit.selection.current() });
  }

  handleOnChange = (text) => {
    if (this.jodit) {
      this.jodit.events.off('beforeCommand') // evita multiplas execuções do metodo dessa forma ele realiza a ação de inserir um * ou _ uma unica vez 
      this.jodit.events.on('beforeCommand', (command) => {
        if (command === 'bold') {
          const selection = this.jodit.selection;
          const selectedText = selection.isCollapsed() ? '' : selection.html.trim();
          if (selectedText === '') {
            return;
          }
          if (selectedText.includes("*", [0])) {
            const textSelected = selectedText.replaceAll('*', "")
            const texto = `${textSelected.trim().replace(/^\s+|\s+$/g, '')}`;
            selection.insertHTML(`${texto}`);
            return;
          }
          const texto = `*${selectedText.trim().replace(/^\s+|\s+$/g, '')}*`;
          selection.insertHTML(`${texto}`);
          this.jodit.events.off('bold')
        }
        if (command === 'italic') {
          const selection = this.jodit.selection;
          const selectedText = selection.isCollapsed() ? '' : selection.html;
          if (selectedText === '') {
            return;
          }
          if (selectedText.includes("_", [0])) {
            const textSelected = selectedText.replaceAll('_', "");
            const texto = `${textSelected.trim().replace(/^\s+|\s+$/g, '')}`;
            selection.insertHTML(`${texto}`);
            return;
          }
          const texto = `_${selectedText.trim().replace(/^\s+|\s+$/g, '')}_`;
          selection.insertHTML(`${texto}`);
        }
      });
    }
    this.setState({ messageText: text });
  };

  handleClickSalvar = async () => {
    const { nomeNovaMensagem, pageableDTO, unidade, profissionalSelecionado } = this.state;
    const { handleOpenNotification, onClose, loadMensagens,filtroHeaderStore } = this.props;

    const profissionaisSaude = profissionalSelecionado?.id ? [{id: profissionalSelecionado.id}] : []

    try {
      this.setState({isLoading: true})
      const mensagemSistema = {
        nome: nomeNovaMensagem.nome,
        mensagem: this.jodit.text,
        ativo: true,
        unidade: unidade,
        tipoPadrao: false,
        profissionaisSaude: profissionaisSaude,
      }
      if (mensagemSistema.mensagem.length > 2000) {
        handleOpenNotification({ variant: "error", mensagem: "A mensagem excede o limite de 2000 caracteres ", isOpen: true, });
      }else{
        await createMensagemSistema(mensagemSistema);
        await loadMensagens(filtroHeaderStore.profissionalSaude.id);
        handleOpenNotification({ variant: "success", mensagem: "Mensagem salva com sucesso!!", isOpen: true, });
        onClose();
      }
    } catch (error) {
      handleOpenNotification({ variant: "error", mensagem: "Erro ao salvar mensagem!", isOpen: true, });
    }
    finally{
      this.setState({isLoading: false})
    }
  }

  handleClickEditar = async () => {
    const { mensagemSelecionada, ativo, pageableDTO } = this.state;
    const { handleOpenNotification, onClose, loadMensagens ,filtroHeaderStore} = this.props;
    
    try {
      this.setState({isLoading:true})
      const dadosUpdate = {
        ...mensagemSelecionada,
        mensagem: this.jodit.text,
        ativo: ativo,
        nome: mensagemSelecionada.nome,
        id: mensagemSelecionada.id,
      }
      if (dadosUpdate.mensagem.length > 2000) {
        handleOpenNotification({ variant: "error", mensagem: "A mensagem excede o limite de 2000 caracteres ", isOpen: true, });
      } else {
        await UpdateMensagemSistema(dadosUpdate);
        handleOpenNotification({ variant: "success", mensagem: "Mensagem salva com sucesso!!", isOpen: true, });
        onClose();
        await loadMensagens(filtroHeaderStore.profissionalSaude.id)
      }
    } catch (error) {
      handleOpenNotification({ mensagem: "Erro ao editar", variant: "error", isOpen: true, });
      throw new Error(error);
    }finally{
      this.setState({isLoading: false})
    }

  }

  hancleClickSwitch = () => {
    const { ativo } = this.state;
    this.setState({ ativo: !ativo });
  }

  handleChangeNome = (event) => {
    const { mensagemSelecionada } = this.state;
    const { value } = event.target;
   
    if (mensagemSelecionada) {
      this.setState({ mensagemSelecionada: { ...mensagemSelecionada, nome: value } });
    } else {
      this.setState({ nomeNovaMensagem: { nome: value } });
    }
  }


  onChangeSelectProfissional = (value) => {
    this.setState({ profissionalSelecionado: value });
  };

  config = {
    enter: 'br',
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    readonly: false,
    defaultMode: '1',
    saveCursorPosition: true,
    autofocus: true,
    width: "100%",
    height: "100%",
    removeButtons: [
      'source', 'strikethrough', 'fontsize', 'ul', 'ol', 'align', 'brush', 'image', 'hr', 'table', '|', 'undo', 'redo', 'showAll', 'eraser', 'outdent', 'indent', 'font', 'paragraph', 'link', 'print', 'fullsize', 'copyformat', 'underline','selectAll'
    ],
    buttons: [
      'italic',
      'bold',
    ]
  }


  render() {
    const { classes, openModal, onClose, mensagemSelected } = this.props;
    const { mensagemSelecionada, ativo, optionsSelect, profissionalSelecionado,messageText, nomeNovaMensagem ,isLoading,somenteVizualizar} = this.state;
     
      const nomeIsValid= nomeNovaMensagem?.nome?.length > 0 || mensagemSelecionada?.nome.length > 0;
      const isMessageValid= messageText.length > 0;
      const isSaveButtonDisabled = !nomeIsValid  || !isMessageValid;

    return (
      <div className={classes.container}>
        <div >
          <Dialog open={openModal} onClose={onClose} classes={{ paper: classes.styleDialog }} >
            <div className={classes.wrapperModal} >
              <div className={classes.envolved}>
                <div className={classes.modalTextMessage} >
                  <div className={classes.wrapper}>
                    <div className={classes.content}>
                      <div className={classes.header}>
                        <h1 className={classes.title}>{mensagemSelected ? "Editar Mensagem" : "Nova Mensagem"}</h1>
                        {!mensagemSelected &&
                        <div className={classes.select}>
                          <Tooltip
                            title="Profissional"
                            placement="top"
                          >
                            <img
                              src={ProfissionalIcon}
                              alt="Profissional"
                            />
                          </Tooltip>
                           <ReactSelect
                              onChange={this.onChangeSelectProfissional}
                              options={optionsSelect}
                              getOptionLabel={(option) => option.nome}
                              getOptionValue={(option) => option.id}
                              value={profissionalSelecionado}
                              className={classes.selectProfissional}
                              isClearable
                            />
                        </div>
                          }
                      </div>
                      <span>{mensagemSelected ? "Edite" : "Crie"} a mensagem que será enviada ao whatsApp de seus clientes  </span>
                      <div className={classes.contentInput}>
                        Nome :
                        <InputForm classes={{ input: classes.inputName }}
                          value={mensagemSelecionada?.nome}
                          onChange={(e) => this.handleChangeNome(e)}
                          placeholder={"Nome da mensagem"}
                          readOnly={somenteVizualizar}
                        />
                        {mensagemSelected &&
                          <Switch disabled={mensagemSelecionada?.tipoPadrao} onClick={this.hancleClickSwitch} checked={ativo} color="primary"></Switch>
                        }
                      </div>
                    </div>
                  </div>
                  <div className={classes.fieldText}>
                    <JoditEditor
                      editorRef={this.setRef}
                      config={somenteVizualizar? {...this.config,readonly:true}:this.config}
                      onChange={newContent => this.handleOnChange(newContent)}
                      onBlur={this.saveCursorPosition}
                     
                    />
                  </div>
                </div>
                <div className={classes.contentButton}>
                  <Button
                    className={classes.buttonSalvar}
                    onClick={mensagemSelecionada ? this.handleClickEditar : this.handleClickSalvar}
                    disabled={isSaveButtonDisabled || isLoading || somenteVizualizar }
                  >
                    Salvar
                  </Button>
                </div>
              </div>
              <AdicionarDados
                onclose={onClose}
                formatCampos={this.formatCampos}
                 />
            </div>
          </Dialog>
        </div>
      </div>
    )
  }
}


const ModalMensagemWithStyles = withStyles(styles)(ModalMensagem);
export default (ModalMensagemWithStyles)