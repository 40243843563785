import Api from "../config/api";

export const findConveniosByAtivo = (unidadeId) =>
  Api.post("", {
    query: `{
            convenios: findAllConvenioList(searchDTO: {
                unidadeId: ${unidadeId || 0},
                ativo: true,
                sortDir: "ASC",
                sortField: "descricao"
            }) {
                id
                descricao
                convenioAgendamentoTipo {
                    agendamentoTipo {
                        id
                    }
                    valor
                }
                convenioSistema {
                  id
                }
            }
        }`,
  });

export const findAllConvenio = (
  search = "",
  sort = { sortDir: "ASC", sortField: "descricao" },
  ativo = true,
  pageNumber = 0,
  pageSize = 30
) =>
  Api.post("", {
    query: `
        query ($searchDTO: SearchDTOInput) {
            findAllConvenio(searchDTO: $searchDTO) {
                last
                content {
                    id
                    descricao
                    ativo
                    registroAns
                    codigoUnidade
                    convenioProcedimentos{
                        convenio{
                            id
                        }
                        procedimento{
                            id
                        }
                        valor
                    }
                    produtoConvenios{
                        produto{
                            id
                        }
                        convenio{
                            id
                        }
                        valor
                    }
                    convenioSistema {
                      id
                    }
                }
            }
        }
    `,
    variables: {
      searchDTO: {
        search,
        ...sort,
        ativo,
        pageNumber,
        pageSize,
      },
    },
  });

export const saveConvenio = async (convenio) => {
  const method = convenio.id ? "updateConvenio" : "createConvenio";

  const response = await Api.post("", {
    query: `
            mutation ($convenio: ConvenioInput) {
                ${method}(convenio: $convenio) {
                    id
                    descricao
                    ativo
                    registroAns
                    codigoUnidade
                    convenioProcedimentos {
                        convenio {
                          id
                        }
                        procedimento {
                          id
                        }
                        valor
                    }
                    convenioAgendamentoTipo {
                        agendamentoTipo {
                            id
                        }
                        valor
                    }
                }
            }
        `,
    variables: { convenio },
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }
};

export const findByIdConvenio = async (id) => {
  const response = await Api.post("", {
    query: `
        query ($id: Long) {
            findByIdConvenio(id: $id) {
                descricao
                ativo
                unidade {
                  id
                }
                registroAns
                codigoUnidade
                convenioProcedimentos {
                    convenio {
                      id
                    }
                    procedimento {
                      id
                    }
                    valor                 
                }
                produtoConvenios {
                    produto {
                        id
                        nome
                    }
                    valor
                }
                convenioAgendamentoTipo {
                    agendamentoTipo {
                      id
                    }
                    valor
                }
                convenioSistema {
                    id
                    descricao
                    label:descricao
                    value:id
                    convenioLogo {
                      urlDownload
                    }
                }
            }
        }
    `,
    variables: { id },
  });
  return response.data.data.findByIdConvenio;
};

export const findAllConvenioRelatorio = (
  search = "",
  sort = { sortDir: "ASC", sortField: "descricao" },
  ativo = null,
  pageNumber = 0,
  pageSize = 999
) =>
  Api.post("", {
    query: `
        query ($searchDTO: SearchDTOInput) {
            findAllConvenio(searchDTO: $searchDTO) {
                last
                content {
                    id
                    descricao
                }
            }
        }
    `,
    variables: {
      searchDTO: {
        search,
        ...sort,
        ativo,
        pageNumber,
        pageSize,
      },
    },
  });

export const listarConveniosTipoConsulta = ({
  search = "",
  sort = { sortDir: "ASC", sortField: "descricao" },
  ativo = true,
  pageNumber = 0,
  pageSize = 30,
}) =>
  Api.post("", {
    query: `
        query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
            findAllConvenio(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
                last
                content {
                id
                descricao
                ativo
                }
            }
            }
    `,
    variables: {
      searchDTO: {
        search,
        ativo,
      },
      pageableDTO: {
        ...sort,
        pageNumber,
        pageSize,
      },
    },
  });

export const listaConveniosAtivo = () =>
  Api.post("", {
    query: `{
            convenios: findAllConvenioList(searchDTO: {
                ativo: true,
                sortDir: "ASC",
                sortField: "descricao"
            }) {
                id
                descricao
            }
        }`,
  });

export const findAllConvenioSistema = async (variables) => {
  const response = await Api.post("", {
    query: `
            query ($search: String, $pageableDTO: PageableDTOInput) {
                findAllConvenioSistema(search: $search, pageableDTO: $pageableDTO) {
                    last
                    content {
                        id
                        descricao
                    }
                }
            }
        `,
    variables: variables,
  });

  const { last, content } = response.data.data.findAllConvenioSistema;
  const customContent = content.map((item) => {
    return {
      ...item,
      value: item.id,
      label: item.descricao,
    };
  });

  return {
    last,
    content: customContent,
  };
};

export const convenioNomeJaExistente = async (search) => {
  const response = await Api.post("", {
    query: `
      {
        convenioNomeJaExistente(search: "${search}")
      }`,
  });

  return response.data.data.convenioNomeJaExistente;
};

export const findAllConvenioSistemaByConvenio = async (variables) => {
  const response = await Api.post("", {
    query: `
         query ($search: String, $vinculado: Boolean, $pageableDTO: PageableDTOInput) {
            findAllConvenioSistemaByConvenio(search: $search, vinculado: $vinculado, pageableDTO: $pageableDTO) {
              last
              content {
                id
                descricao
                convenioLogo {
                  id
                  urlDownload
                }
              }
            }
          }
          
         `,
    variables: variables,
  });

  const { last, content } = response.data.data.findAllConvenioSistemaByConvenio;
  const customContent = content.map((item) => {
    return {
      ...item,
      value: item.id,
      label: item.descricao,
    };
  });

  return {
    last,
    content: customContent,
  };
};
