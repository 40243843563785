import React from "react";
import { inject } from 'mobx-react';
import {
    Grid, CircularProgress, ClickAwayListener,
    Paper,
    Popper
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import styles from "../RelatorioStyle";

import ErrorCollector from '../../../utils/ErrorCollector';
import { findAllConvenioRelatorio } from '../../../services/ConvenioService';
import localStorageService, { USUARIO_LOGADO_KEY } from '../../../services/storage';
import moment from 'moment';
import { InputDateForm } from "../../../components/Modal/Input";
import Table from '../../../components/Table/Table'
import Api from '../../../config/api'
import HeaderRelatorio, { periodos } from "../HeaderRelatorio";
import { ReactSelect } from "../../../components/Select/SelectSearch"
import PageTitle from "../../../components/PageTitle/PageTitle";
import ButtonYellow from "../../../components/Button/ButtonYellow";
import { Print, ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import AgendamentosPorConvenio from "../../../template/pdf/relatorio/AgendamentosPorConvenio";
import Checkbox from "../../../components/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import classNames from "classnames";

const columns = [{
    Header: 'Convênio',
    getValue: (agendamento) => {
        return agendamento.descricao
    },
}, {
    Header: 'Tipo',
    getValue: (agendamento) => {
        return agendamento.tipo
    },
}, {
    Header: 'Quantidade',
    getValue: (agendamento) => {
        return agendamento.quantidade
    },
}];

const firstDayOfMonth = moment().startOf('month').format('YYYY-MM-DD');
const lastDayOfMonth = moment().endOf('month').format('YYYY-MM-DD');
@inject('atendimentoStore', 'googleAnalyticsStore')
class RelatorioAgendamento extends React.Component {

    constructor() {
        super();
        this.state = {
            convenios: [],
            query: [],
            convenio: null,
            dataInicial: firstDayOfMonth,
            dataFinal: lastDayOfMonth,
            periodoSelecionado: periodos[2],
            loading: false,
            isPrintMustache: false,
            dadosRelatorio: null,
            situacoes: [
                { value: 'AGENDADO', name: "Agendado", cor: "#73C8F9", checked: false },
                { value: 'CONFIRMADO', name: "Confirmado", cor: "#26ACAA", checked: false },
                { value: 'AGUARDANDO', name: "Aguardando", cor: "#F9BE73", checked: false },
                { value: 'ATENDENDO', name: "Atendendo", cor: "#717FFC", checked: false },
                { value: 'ATENDIDO', name: "Atendido", cor: "#9871FC", checked: false },
                { value: 'CANCELADO', name: "Cancelado", cor: "#FB7676", checked: false },
                { value: 'EXCLUIDO', name: "Excluido", cor: "#FF6230", checked: false },
                { value: 'BLOQUEADO', name: "Bloqueado", cor: "#566280", checked: false },
                { value: 'FALTOU', name: "Faltou", cor: "#FC71B4", checked: false }
            ],
            anchorEl: null,
            totalAgendamentos: 0,
        };
        this.loadConvenios();
    }

    status = [];

    async componentDidMount() {
        try {
            const query = await this.consultaAgendamento();
            this.setState({ query: query })
        } catch (error) {
            throw error;
        }

        const paginaVisualizada = {
            page: window.location.hash,
            title: 'Relatórios - Agendamentos por convênio'
        }
        this.props.googleAnalyticsStore.pageView(paginaVisualizada);
    }

    handleConvenioChange = e => {
        this.setState({ convenio: e });
        this.search();
    }

    consultaAgendamento = async () => {
        try {
            this.setState({ loading: true });
            const user = await localStorageService.get(USUARIO_LOGADO_KEY);

            const unidadeAtual = user && user.unidadeAtual;
            const unidadeId = unidadeAtual?.id && unidadeAtual.id;
            const situacoes = this.status.length > 0 ? this.status : null;
            const convenioId = this.state.convenio?.id && this.state.convenio.id;
            const dataInicial = this.state.dataInicial;
            const dataFinal = this.state.dataFinal;

            const response = await Api.post('',
                {
                    query: `
                        query ($page: SearchAgendamentoDTOInput){

                            relatorioAgendamentos(search: $page){
                                descricao
                                tipo
                                quantidade
                            }
                        }`,
                    variables: {
                        page: {
                            unidadeId,
                            convenioId,
                            dataInicial,
                            dataFinal,
                            situacoes
                        }
                    }
                }
            )

            const { relatorioAgendamentos } = response.data.data;
            this.setState({ totalAgendamentos: relatorioAgendamentos.length });
            return relatorioAgendamentos;
        } catch (error) {
            return [];
        } finally {
            this.setState({ loading: false })
        }
    }

    loadConvenios = () => {
        findAllConvenioRelatorio()
            .then(({ data: response }) => {
                if (response.data) {
                    const convenios = response.data.findAllConvenio.content.map(convenio => ({
                        ...convenio,
                        name: convenio.descricao,
                        value: convenio.id
                    }));
                    this.setState({
                        convenios: convenios
                    });
                }
            })
            .catch((error) => console.error(error));
    };

    search = async () => {
        const query = await this.consultaAgendamento();

        if (query) {
            this.setState({ query: query })
        } else {
            this.setState({ query: [] })
        }

    };

    handlePeriodoSelecionado = (value) => {
        const { dataInicial, dataFinal } = value;

        this.setState({
            periodoSelecionado: value,
            dataInicial: dataInicial,
            dataFinal: dataFinal,
        }, () => this.search());
    };

    handleDateChange = (e, field) => {
        const dataFormatada = e && moment(e).format("YYYY-MM-DD");

        if (field === "inicio") {
            this.setState({ dataInicial: dataFormatada });
        } else {
            this.setState({
                dataFinal: dataFormatada,
            })
        }

        this.search()
    };

    printRelatorio = async () => {
        const dadosRelatorio = await this.consultaAgendamento();
        this.setState({ dadosRelatorio }, () => {
            this.handlePrintRelatorio();
        });
    }

    handlePrintRelatorio = () => {
        this.setState({ isPrintMustache: true });
    }

    changeCheckbox = (e, index) => {
        let situacoes = this.state.situacoes
        let actualState = situacoes[index].checked
        situacoes[index].checked = !actualState
        this.setState({ situacoes })
        this.status = this.state.situacoes.filter(value => !value.checked).map(({ value }) => value);
        this.search();
    };

    quantidadeChecked = () => {
        const { situacoes } = this.state;
        const situacoesChecked = situacoes.filter(item => item.checked);
        const quantidadeChecked = situacoesChecked.length;
        return quantidadeChecked === 0 ? "Selecione" : `${quantidadeChecked} selecionados`
    }

    handleClickSituacao = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: state.anchorEl ? null : currentTarget,
        }));
    };



    render() {
        const { classes } = this.props;
        const { dataInicial, dataFinal, anchorEl, periodoSelecionado, convenio, convenios, loading, situacoes, totalAgendamentos } = this.state;

        const open = Boolean(anchorEl);
        const id = open ? 'no-transition-popper' : null;

        return (
            <div className={classes.content}>
                <PageTitle title="Relatórios - Agendamentos por convênios" />
                <HeaderRelatorio
                    handlePeriodo={this.handlePeriodoSelecionado}
                    periodoSelecionado={periodoSelecionado}
                    hiddenSearch
                    totalAgendamentos={totalAgendamentos}
                    totalTitle="agendamentos"
                />

                <div className={classes.filtros}>
                    <Grid item xs={3} className={classes.spacingConvenio}>
                        <span className={classes.tituloFiltros}> Data início: </span>
                        <InputDateForm
                            iconposition="end"
                            openTo="day"
                            views={["year", "month"]}
                            value={dataInicial}
                            classes={{
                                input: classes.inputData,
                            }}
                            onChange={(e) => this.handleDateChange(e, "inicio")}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.spacingConvenio}>
                        <span className={classes.tituloFiltros}> Data fim: </span>
                        <InputDateForm
                            iconposition="end"
                            openTo="day"
                            views={["year", "month"]}
                            value={dataFinal}
                            classes={{
                                input: classes.inputData,
                            }}
                            onChange={(e) => this.handleDateChange(e, "fim")}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.spacingConvenio}>
                        <span className={classes.tituloFiltros}> Convênio: </span>
                        <ReactSelect
                            placeholder={"Selecione"}
                            value={convenio}
                            onChange={this.handleConvenioChange}
                            options={convenios || []}
                            isClearable
                            getOptionLabel={(option) => option.descricao}
                            getOptionValue={(option) => option.id}
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.spacingConvenio}>
                        <span className={classes.tituloFiltros}> Situação: </span>
                        <div
                            onClick={this.handleClickSituacao}
                            className={classes.selectSituacao}
                        >
                            <span> {this.quantidadeChecked()} </span>
                            <ArrowDropDownIcon className={classes.colorArrow} />
                        </div>
                        <Popper
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            placement={"bottom"}
                            className={classes.popper}
                        >
                            <ClickAwayListener
                                onClickAway={() => this.setState({ open: false })}
                            >
                                <Paper className={classes.paperSituacao}>
                                    <div className={classes.checkboxOptions}>
                                        {situacoes.map((item, index) => (
                                            <div className={classes.wrapperCheckbox}>
                                                <FormControlLabel
                                                    className={classNames(item.name.toLowerCase())}
                                                    control={
                                                        <Checkbox
                                                            checked={item.checked}
                                                            color={'#505050'}
                                                            value={item.value}
                                                        />
                                                    }
                                                    label={<span className={classNames(item.name.toLowerCase())}> {item.name} </span>}
                                                    key={index}
                                                    onChange={e => this.changeCheckbox(e, index)} />
                                                <div className={classes.wrapperColorBox}>
                                                    <div className={classes.colorBox} style={{ background: item.cor }} />
                                                </div>
                                            </div>
                                        )
                                        )}
                                    </div>
                                </Paper>
                            </ClickAwayListener>
                        </Popper>
                    </Grid>
                </div>
                <ErrorCollector mensagem={'Não foi possível carregar o relatório!'}>
                    <Grid item xs={12} container className={classes.contentTable}>
                        {
                            convenios.length > 0 &&
                            <Table
                                className={classes.table}
                                dados={this.state.query || []}
                                columns={columns}
                                clickable={false}
                                handleClick={this.handleClickOpen}
                            />
                        }
                        {loading && (
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                style={{ height: "100%", marginTop: 20 }}
                            >
                                <CircularProgress size={30} />
                            </Grid>
                        )}
                    </Grid>
                </ErrorCollector>
                <div className={classes.buttonsDownloadPrint}>
                    <ButtonYellow
                        id="exportCsv"
                        className={classes.buttonImprimir}
                        onClick={this.printRelatorio}
                    >
                        <Print />
                    </ButtonYellow>
                </div>
                {this.state.isPrintMustache && <ImpressaoHtml
                    isPrintMustache={this.state.isPrintMustache}
                    handlePrintMustache={() => { this.setState({ isPrintMustache: false }) }}
                    htmlStringComponent={
                        <AgendamentosPorConvenio
                            dadosRelatorio={this.state.dadosRelatorio}
                            dataInicial={this.state.dataInicial}
                            dataFinal={this.state.dataFinal}
                        />
                    }
                />}
            </div>
        );
    }
}


export default withStyles(styles)(RelatorioAgendamento);
