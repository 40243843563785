import Api from "../config/api";

export const createAvaliacaoAntropometrica = async (
  avaliacaoAntropometrica
) => {
  const response = await Api.post("", {
    query: `
          mutation($avaliacaoAntropometrica: AvaliacaoAntropometricaInput){
            createAvaliacaoAntropometrica(AvaliacaoAntropometrica: $avaliacaoAntropometrica){
                id
              }
            }`,
    variables: { avaliacaoAntropometrica },
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response;
};

export const findAllAvaliacaoAntropometrica = async({sujeitoAtencaoId, pageableDTO}) => {
  const pageableDefault = {
    pageSize: 30,
    sortDir: "DESC",
    sortField: "entradaProntuario.dataHoraLancamento",
    ...pageableDTO
  };

  const response = await Api.post("", {
    query: `
    query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
      findAllAvaliacaoAntropometrica(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
        last
        content {
          id
          ativo
          entradaProntuario {
            id
            entradaProntuarioInativacao {
              inativador {
                nome
              }
            }
          }
          cintura
          circunferenciaBraquial
          perimetroCefalico
          data
          estatura
          abdomen
          coxaEsquerda
          coxaDireita
          bracoEsquerdo
          bracoDireito
          peso
          pregaCutanea
          quadril
        }
      }
    }
    `,
    variables: {
      searchDTO: { sujeitoAtencaoId },
      pageableDTO: pageableDefault
    }
  });

  return response.data.data.findAllAvaliacaoAntropometrica;
};

export const findByIdEntradaProntuarioAvaliacaoAntropometrica = async id => {
  const response = await Api.post("", {
    query: `
    {
      findByIdEntradaProntuarioAvaliacaoAntropometrica(idEntradaProntuario: ${id}){
        ativo
        id
        cintura
        circunferenciaBraquial
        perimetroCefalico
        data
        estatura
        peso
        pregaCutanea
        quadril
        entradaProntuario {
          entradaProntuarioInativacao {
            inativador {
              nome
            }
          }
        }
      }
    }
    `
  });

  if(response.data.errors){
    throw Error(response.data.errors[0].message)
  }
  return response.data.data.findByIdEntradaProntuarioAvaliacaoAntropometrica;
};

export const findAllAvaliacaoAntropometricaMediaDiaria = async (searchDTO) => {
  const response = await Api.post("", {
    query: `
    query ($searchDTO: SearchDTOInput) {
      findAllAvaliacaoAntropometricaMediaDiaria(searchDTO: $searchDTO) {
        data
        estatura
        peso
        perimetroCefalico
      }
    }`, 
    variables: {searchDTO}
  });

  return response.data.data.findAllAvaliacaoAntropometricaMediaDiaria;
};

export const buscaDadosGrafico = async (variables) => {
  const response = await Api.post("", {
    query: `
    query ($searchDTO: SearchDTOInput, $dataNascimento: LocalDate, $sexo: String, $tipo: TipoGrafico, $dataFim: LocalDate, $dataInicio: LocalDate, $pageableDTO: PageableDTOInput) {
      paciente:findAllAvaliacaoAntropometricaMediaMes(searchDTO: $searchDTO) {
        data
        estatura
        peso
        perimetroCefalico
      }
      grafico:findAllGraficoCrescimentoInfantilOMS(dataNascimento: $dataNascimento, sexo: $sexo, dataFim: $dataFim, dataInicio: $dataInicio, tipo: $tipo, pageableDTO: $pageableDTO) {
        mes
        tipo
        estatura
        peso
        perimetroCefalico
        data
      }
    }`,
    variables: variables
  });

  return response.data.data;
}

export const calcImc = async (variables) => {
  const response = await Api.post('', {
    query: `
      query ($sexoId: Long, $idade: Long, $peso: BigDecimal, $altura: BigDecimal) {
        calculaIMC(sexoId: $sexoId, idade: $idade, peso: $peso, altura: $altura)
      }
    `,
    variables: variables,
  });
  return response.data.data.calculaIMC;
};
