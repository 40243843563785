import React from "react";
import moment from "moment";
import { applyPhoneMask } from "../../../../utils/PhoneMask";
import { goToWhatsApp } from "../../../../utils/goToWhatsApp";
import ButtonWhatsApp from "../../../../components/Button/ButtonWhatsApp";

export const columns = [
  {
    Header: "Paciente",
    align: "left",
    paddingLeft: true,
    getValue: (agendamento) => {
      return agendamento.nome;
    },
  },
  {
    Header: "Idade",
    align: "left",
    paddingLeft: true,
    getValue: (agendamento) => {
      return agendamento.idade;
    },
  },
  {
    Header: "Telefone",
    align: "left",
    paddingLeft: true,
    getValue: (agendamento) => {
      const { contatoTelefonePrincipal, contatoTelefonePrincipalDdi } = agendamento || {};

      const handleClickWhatsapp = async (e) => {
        e.stopPropagation();
        return goToWhatsApp(contatoTelefonePrincipal, contatoTelefonePrincipalDdi, "");
      };

      return (
        <>
          {contatoTelefonePrincipal && (
            <div
              style={{ display: "flex", gap: "4px", width: "150px" }}
              onClick={handleClickWhatsapp}
            >
              <span>{applyPhoneMask(contatoTelefonePrincipal)}</span>
              <ButtonWhatsApp />
            </div>
          )}
        </>
      );
    },
  },
  {
    Header: "E-mail",
    align: "left",
    paddingLeft: true,
    getValue: (agendamento) => {
      return agendamento.contatoEmail;
    },
  },
  {
    Header: "Cidade",
    align: "left",
    paddingLeft: true,
    getValue: (agendamento) => {
      return agendamento?.nomeMunicipio;
    },
  },
  {
    Header: "Último atendimento",
    align: "left",
    paddingLeft: true,
    getValue: (agendamento) => {
      return moment(agendamento?.dataUltimoAtendimento).format("DD/MM/YYYY");
    },
  },
  {
    Header: "Convênio",
    align: "left",
    paddingLeft: true,
    getValue: (agendamento) => {
      return agendamento?.descricaoConvenio;
    },
  },
];

export const periodos = [
  {
    value: 30,
    name: "1 mês",
  },
  {
    value: 90,
    name: "3 meses",
  },
  {
    value: 180,
    name: "6 meses",
  },
  {
    value: 365,
    name: "1 ano",
  },
  {
    value: 999,
    name: "Mais de 1 ano",
  },
];

export const filtersDefault = {
  periodo: periodos[0],
};
